import React, {Component} from 'react'
import { Route} from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home'
import TransactionAdd from './components/TransactionAdd'
import TransactionList from './components/TransactionList'
import SocietyEdit from './components/SocietyEdit'
import ChangePassword from './components/ChangePassword'
import ImportData from './components/ImportData'
import Reports from './components/Reports'

class Main extends Component
{
    constructor(){
        super()
    }
    
    render()
    { 
        return<div>
            <Route exact path='/' render={({history})=>(<div><Login {...this.props} onHistory = {history}/></div>)} />
            <Route exact path='/home' render={({history})=>(<div><Home {...this.props} onHistory = {history}/></div>)} />
            <Route exact path='/transactionadd' render={({history})=>(<div><TransactionAdd {...this.props} onHistory = {history}/></div>)} />
            <Route exact path='/transaction/edit' render={({ history }) => (<div><TransactionAdd {...this.props} onHistory={history} /></div>)} />
            <Route exact path='/transactionlist' render={({history})=>(<div><TransactionList {...this.props} onHistory = {history}/></div>)} />
            <Route exact path='/importdata' render={({history})=>(<div><ImportData {...this.props} onHistory = {history}/></div>)} />
            <Route exact path='/societyedit' render={({history})=>(<div><SocietyEdit {...this.props} onHistory = {history}/></div>)} />
            <Route exact path='/changepassword' render={({history})=>(<div><ChangePassword {...this.props} onHistory = {history}/></div>)} />
            <Route exact path='/reports' render={({history})=>(<div><Reports {...this.props} onHistory = {history}/></div>)} />
        
        </div>
    }
}

export default Main;