import React, { Component } from "react";
import Header from "./Header";
import CSVReader from "react-csv-reader";
import { database } from "../firebase";
import moment from "moment";

// const elasticsearch = require('elasticsearch');
// const client = new elasticsearch.Client({
//     host: 'https://elastic:jWSrJcPS1hiv0zmcVmcq00bT@abf1b1b42bdc42aab764c101abbe1174.us-central1.gcp.cloud.es.io:9243'
// })

class ImportData extends Component {
  constructor() {
    super();
    this.state = {
      fileColumns: [
        "DIVISION",
        "DISTRICT",
        "DR NAME",
        "DR MOBILE",
        "AR",
        "AR NAME",
        "AR EMAILID",
        "AR MOBILE",
        "SOCIETY",
        "VILLAGE",
        "SECRETARY",
        "SECRETARY MOBILE",
        "INSPECTOR",
        "INSPECTOR MOBILE",
        "HAPPY SEEDER",
        "SUPER SEEDER",
        "PADDY STRAW CHOPPER",
        "MULCHER",
        "RMB PLOUGH",
        "ZERO TILL DRILL",
        "SUPER SMS",
        "ROTARY SLASHER",
        "SHRUB MASTER",
        "CUTTER CUM SPREADER",
        "ROTAVATOR",
        "TRACTOR",
        "TRACTOR TROLLEY",
        "LASER LEVELLER",
        "DIRECT SEEDER RICE (DSR)",
        "MAIZE PLANTER MECHANICAL",
        "MAIZE PLANTER VACCUM",
        "SPRAY PUMP",
        "PRESSURE DISKS",
        "DISK LOCK",
        "(BIO) FERTILIZER SPREADER",
        "CULTIVATOR",
        "SUB SOILER",
        "POWER CLEANER",
        "DISC RAZOR",
        "HARROW TRIPPLE",
        "COTTON SEED DRILL",
        "WATER TANK",
        "SUHAGA",
        "WHEAT SEED FAN",
        "SEED GRINDING MACHINE",
        "JINDRA",
        "COMBINE",
        "SEELAR COTTON",
        "GENERATOR",
        "WHEAT SEED DRILL",
        "REAPER",
        "PLOUGH/SIMPLE PLOUGH",
        "GROUND LEVELLER",
        "STRAW REAPER",
        "POTATO PLANTER",
        "TAWIAN",
        "SIMPLE LEVELLER",
        "LOADER",
        "POTATO DRILL",
        "MULTI DRILL MACHINE",
        "PUDDLER (KADDO)",
        "CHOPPER",
        "CORN DRILL",
        "PEAS MACHINE",
        "SEED DRILL",
        "CHIZLER",
        "PADDY PLANTER",
        "DIESEL TANK",
        "BHAUNI",
        "TOTA",
        "TILLER",
        "KARAH",
        "TARFALI",
        "RAZOR",
        "BALER",
        "THRESHER",
        "KARCHA MACHINE",
        "RATOR",
        "SUGARCANE RAGER",
        "DISC HARROW",
        "CHAIN KUPPI",
        "SUGARCANE SEEDER",
        "GRINDER",
        "CHANGER",
        "TRIPPLE PLOUGH",
        "RAKER",
        "CORN HARVESTER",
        "CUTTER",
        "MOTOR MACHINE / MOTOR EXTRACTION MACHINE",
        "DISC PLOUGH",
        "GAINI PLOUGH",
        "DUG PLOUGH",
        "OFFSET HARROW",
        "JAAL",
        "TARAKADI",
        "CARROT MACHINE",
        "DISC BED MAKER",
        "LOADER BUCKET",
        "SEED GRADER",
        "ACHAT HALL",
        "TAWA WATTA",
        "JACK",
        "SURFACE SEEDER",
        "SMART SEEDER",
      ],
      showFileUpload: false,
      totalCounter: 0,
      errors: [],
      startDate: new Date("2-Oct-2024").toISOString().split("T")[0],
      endDate: new Date("1-Apr-2025").toISOString().split("T")[0],
    };
    this.onFileLoaded = this.onFileLoaded.bind(this);
    this.onUpdatePasswordFileLoaded =
      this.onUpdatePasswordFileLoaded.bind(this);
    this.objToCSV = this.objToCSV.bind(this);
    this.getSocieties = this.getSocieties.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.getTransactionData = this.getTransactionData.bind(this);
    this.updateSocieties = this.updateSocieties.bind(this);
    // this.updateTransactions = this.updateTransactions.bind(this)
    this.getAllTransactionData = this.getAllTransactionData.bind(this);
    this.deleteSocieties = this.deleteSocieties.bind(this);
    this.createUsers = this.createUsers.bind(this);
    this.getSocietiesWithNoTransaction =
      this.getSocietiesWithNoTransaction.bind(this);
  }

  objToCSV(obj) {
    const csvRows = [];
    const headers = Object.keys(obj[0]);
    csvRows.push(headers.join(","));

    for (const row of obj) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
    return csvRows.join("\n");
  }

  async onFileLoaded(collection) {
    this.setState({ showFileUpload: false });
    console.log("collection[0].length", collection[0].length);
    console.log("this.state.fileColumns.length", this.state.fileColumns.length);
    // if (this.state.fileColumns.length !== collection[0].length) {
    //     alert("Column missing: File is not in desired format:- ", this.state.fileColumns.length + ' Columns Count:- ' + collection[0].length);
    //     return;
    // }

    // for (let index = 0; index < this.state.fileColumns.length; index++) {
    //     let fileColumn = this.state.fileColumns[index];

    //     if (collection[0][index].toString().trim().toLowerCase() !== fileColumn.toLowerCase()) {
    //         console.log(collection[0][index])
    //         console.log(fileColumn)
    //         alert("Column sequence missing: File is not in desired format");
    //         return;
    //     }
    // }

    let errors = [];
    let societies = [];
    for (let i = 1; i < collection.length; i++) {
      let data = collection[i];

      let error = "";

      if (data[0] !== "") {
        // if (data[1] === '') {
        //     error = error + "DISTRICT is missing."
        // }
        // if (data[2] === '') {
        //     error = error + "DR NAME is missing"
        // }
        // if (data[3] === '') {
        //     error = error + "DR MOBILE is missing."
        // }
        // if (data[4] === '') {
        //     error = error + "AR is missing."
        // }
        // if (data[5] === '') {
        //     error = error + "AR NAME is missing."
        // }
        // if (data[6] === '') {
        //     error = error + "AR MOBILE is missing."
        // }
        // if (data[7] === '') {
        //     error = error + "SOCIETY is missing."
        // }
        // if (data[8] === '') {
        //     error = error + "VILLAGE is missing."
        // }
        // if (data[9] === '') {
        //     error = error + "SECRETARY is missing."
        // }
        // if (data[10] === '') {
        //     error = error + "SECRETARY MOBILE is missing."
        // }
        // if (data[11] === '') {
        //     error = error + "INSPECTOR is missing."
        // }
        // if (data[12] === '') {
        //     error = error + "INSPECTOR MOBILE is missing."
        // }

        // if (error !== '') {
        //     let errorObj = {
        //         "EXCELROWNO": i + 1,
        //         "DIVISION": data[0].trim().toUpperCase(),
        //         "DISTRICT": data[1].trim().toUpperCase(),
        //         "DRNAME": data[2],
        //         "DRMOBILE": data[3],
        //         "AR": data[4].trim().toUpperCase(),
        //         "ARNAME": data[5],
        //         "ARMOBILE": data[6],
        //         "SOCIETY": data[7].trim().toUpperCase(),
        //         "VILLAGE": data[8],
        //         "SECRETARY": data[9],
        //         "SECRETARYMOBILE": data[10],
        //         "INSPECTOR": data[11],
        //         "INSPECTORMOBILE": data[12],
        //         "HAPPYSEEDER": data[13],
        //         "SUPERSEEDER": data[14],
        //         "PADDYSTRAWCHOPPER": data[15],
        //         "MULCHER": data[16],
        //         "RMBPLOUGH": data[17],
        //         "ZEROTILLDRILL": data[18],
        //         "SUPERSMS": data[19],
        //         "ROTARYSLASHER": data[20],
        //         "SHRUBMASTER": data[21],
        //         "CUTTERCUMSPREADER": data[22],
        //         "ROTAVATOR": data[23],
        //         "TRACTOR": data[24],
        //         "TRACTORTROLLEY": data[25],
        //         "LASERLEVELLER": data[26],
        //         "DIRECTSEEDERRICE": data[27],
        //         "MAIZEPLANTERMECHANICAL": data[28],
        //         "MAIZEPLANTERVACCUM": data[29],
        //         "SPRAYPUMP": data[30],
        //         "PRESSUREDISKS": data[31],
        //         "DISKLOCK": data[32],
        //         "BIOFERTILIZERSPREADER": data[33],
        //         "CULTIVATOR": data[34],
        //         "SUBSOILER": data[35],
        //         "POWERCLEANER": data[36],
        //         "DISCRAZOR": data[37],
        //         "HARROWTRIPPLE": data[38],
        //         "COTTONSEEDDRILL": data[39],
        //         "WATERTANK": data[40],
        //         "SUHAGA": data[41],
        //         "WHEATSEEDFAN": data[42],
        //         "SEEDGRINDINGMACHINE": data[43],
        //         "JINDRA": data[44],
        //         "COMBINE": data[45],
        //         "SEELARCOTTON": data[46],
        //         "GENERATOR": data[47],
        //         "WHEATSEEDDRILL": data[48],
        //         "REAPER": data[49],
        //         "PLOUGH": data[50],
        //         "GROUNDLEVELLER": data[51],
        //         "STRAWREAPER": data[52],
        //         "POTATOPLANTER": data[53],
        //         "TAWIAN": data[54],
        //         "SIMPLELEVELLER": data[55],
        //         "LOADER": data[56],
        //         "POTATODRILL": data[57],
        //         "MULTIDRILLMACHINE": data[58],
        //         "PUDDLER": data[59],
        //         "CHOPPER": data[60],
        //         "CORNDRILL": data[61],
        //         "PEASMACHINE": data[62],
        //         "SEEDDRILL": data[63],
        //         "CHIZLER": data[64],
        //         "PADDYPLANTER": data[65],
        //         "DIESELTANK": data[66],
        //         "BHAUNI": data[67],
        //         "TOTA": data[68],
        //         "TILLER": data[69],
        //         "KARAH": data[70],
        //         "TARFALI": data[71],
        //         "RAZOR": data[72],
        //         // "TAULYNEW": data[73],
        //         "BALER": data[73],
        //         "THRESHER": data[74],
        //         "KARCHAMACHINE": data[75],
        //         "RATOR": data[76],
        //         "SUGARCANERAGER": data[77],
        //         "DISCHARROW": data[78],
        //         "CHAINKUPPI": data[79],
        //         "SUGARCANESEEDER": data[80],
        //         "GRINDER": data[81],
        //         "CHANGER": data[82],
        //         "TRIPPLEPLOUGH": data[83],
        //         // "FILAGEMAKING": data[84],
        //         "RAKER":data[83],
        //         "CORNHARVESTER": data[85],
        //         "CUTTER": data[86],
        //         "MOTORMACHINE": data[87],
        //         "DISCPLOUGH": data[88],
        //         "GAINIPLOUGH": data[89],
        //         "DUGPLOUGH": data[90],
        //         "OFFSETHARROW": data[91],
        //         "JAAL": data[92],
        //         "TARAKADI": data[93],
        //         "CARROTMACHINE": data[94],
        //         "DISCBEDMAKER": data[95],
        //         "LOADERBUCKET": data[96],
        //         "SEEDGRADER": data[97],
        //         "ACHATHALL": data[98],
        //         "TAWAWATTA": data[99],
        //         "JACK": data[100],
        //         "SURFACESEEDER": data[101],
        //         "SMARTSEEDER": data[102],
        //         "MESSAGE": error
        //     }
        //     errors.push(errorObj)
        //     continue
        // }
        // else {
        let society = {
          DIVISION: data[0].trim().toUpperCase(),
          DISTRICT: data[1].trim().toUpperCase(),
          DRNAME: data[2].trim(),
          DRMOBILE: data[3].trim(),
          DREMAILID: data[4].trim(),
          AR: data[5].trim().toUpperCase(),
          ARNAME: data[6].trim(),
          AREMAILID: data[7].trim(),
          ARMOBILE: data[8].trim(),
          SOCIETY: data[9].trim().toUpperCase(),
          // "VILLAGE": data[8].trim(),
          SECRETARY: data[10].trim(),
          SECRETARYMOBILE: data[11].trim(),
          SECRETARYEMAILID: data[11].trim() + "@cs.com",
          INSPECTOR: data[12].trim(),
          INSPECTORMOBILE: data[13].trim(),
          INSPECTOREMAILID: data[13].trim() + "@cs.com",
          HAPPYSEEDER: Number(data[14]),
          SUPERSEEDER: Number(data[15]),
          PADDYSTRAWCHOPPER: Number(data[16]),
          MULCHER: Number(data[17]),
          RMBPLOUGH: Number(data[18]),
          ZEROTILLDRILL: Number(data[19]),
          SUPERSMS: Number(data[20]),
          ROTARYSLASHER: Number(data[21]),
          SHRUBMASTER: Number(data[22]),
          CUTTERCUMSPREADER: Number(data[23]),
          ROTAVATOR: Number(data[24]),
          TRACTOR: Number(data[25]),
          TRACTORTROLLEY: Number(data[26]),
          LASERLEVELLER: Number(data[27]),
          DIRECTSEEDERRICE: Number(data[28]),
          MAIZEPLANTERMECHANICAL: Number(data[29]),
          MAIZEPLANTERVACCUM: Number(data[30]),
          SPRAYPUMP: Number(data[31]),
          PRESSUREDISKS: Number(data[32]),
          DISKLOCK: Number(data[33]),
          BIOFERTILIZERSPREADER: Number(data[34]),
          CULTIVATOR: Number(data[35]),
          SUBSOILER: Number(data[36]),
          POWERCLEANER: Number(data[37]),
          DISCRAZOR: Number(data[38]),
          HARROWTRIPPLE: Number(data[39]),
          COTTONSEEDDRILL: Number(data[40]),
          WATERTANK: Number(data[41]),
          SUHAGA: Number(data[42]),
          WHEATSEEDFAN: Number(data[43]),
          SEEDGRINDINGMACHINE: Number(data[44]),
          JINDRA: Number(data[45]),
          COMBINE: Number(data[46]),
          SEELARCOTTON: Number(data[47]),
          GENERATOR: Number(data[48]),
          WHEATSEEDDRILL: Number(data[49]),
          REAPER: Number(data[50]),
          PLOUGH: Number(data[51]),
          GROUNDLEVELLER: Number(data[52]),
          STRAWREAPER: Number(data[53]),
          POTATOPLANTER: Number(data[54]),
          TAWIAN: Number(data[55]),
          SIMPLELEVELLER: Number(data[56]),
          LOADER: Number(data[57]),
          POTATODRILL: Number(data[58]),
          MULTIDRILLMACHINE: Number(data[59]),
          PUDDLER: Number(data[60]),
          CHOPPER: Number(data[61]),
          CORNDRILL: Number(data[62]),
          PEASMACHINE: Number(data[63]),
          SEEDDRILL: Number(data[64]),
          CHIZLER: Number(data[65]),
          PADDYPLANTER: Number(data[66]),
          DIESELTANK: Number(data[67]),
          BHAUNI: Number(data[68]),
          TOTA: Number(data[69]),
          TILLER: Number(data[70]),
          KARAH: Number(data[71]),
          TARFALI: Number(data[72]),
          RAZOR: Number(data[73]),
          // "TAULYNEW": Number(data[73]),
          BALER: data[74],
          THRESHER: Number(data[75]),
          KARCHAMACHINE: Number(data[76]),
          RATOR: Number(data[77]),
          SUGARCANERAGER: Number(data[78]),
          DISCHARROW: Number(data[79]),
          CHAINKUPPI: Number(data[80]),
          SUGARCANESEEDER: Number(data[81]),
          GRINDER: Number(data[82]),
          CHANGER: Number(data[83]),
          TRIPPLEPLOUGH: Number(data[84]),
          // "FILAGEMAKING": Number(data[84]),
          RAKER: Number(data[85]),
          CORNHARVESTER: Number(data[86]),
          CUTTER: Number(data[87]),
          MOTORMACHINE: Number(data[88]),
          DISCPLOUGH: Number(data[89]),
          GAINIPLOUGH: Number(data[90]),
          DUGPLOUGH: Number(data[91]),
          OFFSETHARROW: Number(data[92]),
          JAAL: Number(data[93]),
          TARAKADI: Number(data[94]),
          CARROTMACHINE: Number(data[95]),
          DISCBEDMAKER: Number(data[96]),
          LOADERBUCKET: Number(data[97]),
          SEEDGRADER: Number(data[98]),
          ACHATHALL: Number(data[99]),
          TAWAWATTA: Number(data[100]),
          JACK: Number(data[101]),
          SURFACESEEDER: Number(
            data.hasOwnProperty("SURFACESEEDER") ? data[102] : 0
          ),
          SMARTSEEDER: Number(
            data.hasOwnProperty("SMARTSEEDER") ? data[103] : 0
          ),
        };
        societies.push(society);
        // }
      }
    }

    if (errors.length > 0) {
      console.log("errors", JSON.stringify(errors));
      this.setState({ errors: errors });
    } else {
      console.log("societies", JSON.stringify(societies));
      for (let index = 0; index < societies.length; index++) {
        let data = societies[index];
        await this.props.saveSociety(data);
      }
      alert("Data uploaded successfully!!!");
      await this.generateUsers(societies);
    }
  }

  async onUpdatePasswordFileLoaded(collection) {
    this.setState({ showFileUpload: false });
    let userList = await this.props.getUserList();
    console.log("userList", userList);
    console.log("collection", collection);
    let notFoundCtr = 1;
    let newCollection = [];
    for (let i = 1; i < collection.length; i++) {
      let contactNo = collection[i][3];
      // console.log("contactNo",contactNo);
      if (userList.filter((f) => f.PhoneNo === contactNo).length > 0) {
        // console.log('found', collection[i]);
        let userOld = userList.filter((f) => f.PhoneNo === contactNo)[0];
        newCollection.push({ ContactNo: contactNo, Status: "Found" });
        let user = {};
        user.EmailID = contactNo + "_22@cs.com";
        user.Name = userOld.Name;
        user.PhoneNo = userOld.PhoneNo;
        user.IsDeleted = false;
        user.Role = userOld.Role;
        console.log("user", user);
        await database.collection("Users").doc().set(user);
        await database
          .collection("Users")
          .doc(userOld.key)
          .update({ IsDeleted: true });
        // auth.getUser(uid)
        // .then((userRecord) => {
        //   // See the UserRecord reference doc for the contents of userRecord.
        //   console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
        // })
        // .catch((error) => {
        //   console.log('Error fetching user data:', error);
        // });
      } else {
        newCollection.push({ ContactNo: contactNo, Status: "Not Found" });
        // console.log('notFoundCtr',notFoundCtr);
        // console.log('not found', contactNo);
        notFoundCtr = notFoundCtr + 1;
      }
    }

    const csvData = this.objToCSV(newCollection);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "status.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onExportClick() {
    let data = this.state.errors;
    const csvData = this.objToCSV(data);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "RawDataError.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async generateUsers(societies) {
    for (let index = 0; index < societies.length; index++) {
      let society = societies[index];
      // console.log("society", society.SOCIETY);
      if (society.SECRETARYEMAILID !== "") {
        let secExists = await this.props.checkUserExists(
          society.SECRETARYEMAILID
        );
        console.log("secExists", secExists);
        if (!secExists) {
          try {
            let userSec = {};
            userSec.EmailID = society.SECRETARYEMAILID;
            userSec.Name = society.SECRETARY;
            userSec.PhoneNo = society.SECRETARYMOBILE;
            userSec.IsDeleted = false;
            userSec.Role = "Secretary";
            await database.collection("Users").doc().set(userSec);
          } catch (error) {
            console.error("Error saving Secretary!!! ", error.message);
          }
        }
      }
      if (society.AREMAILID !== "") {
        let arExists = await this.props.checkUserExists(society.AREMAILID);
        console.log("arExists", arExists);
        if (!arExists) {
          try {
            let userAR = {};
            userAR.EmailID = society.AREMAILID;
            userAR.Name = society.ARNAME;
            userAR.PhoneNo = society.ARMOBILE;
            userAR.IsDeleted = false;
            userAR.Role = "AR";
            await database.collection("Users").doc().set(userAR);
          } catch (error) {
            console.error("Error saving AR!!! ", error);
          }
        }
      }
      if (society.DREMAILID !== "") {
        let drExists = await this.props.checkUserExists(society.DREMAILID);
        console.log("drExists", drExists);
        if (!drExists) {
          try {
            let userDR = {};
            userDR.EmailID = society.DREMAILID;
            userDR.Name = society.DRNAME;
            userDR.PhoneNo = society.DRMOBILE;
            userDR.Role = "DR";
            userDR.IsDeleted = false;
            await database.collection("Users").doc().set(userDR);
          } catch (error) {
            console.error("Error saving DR!!! ", error);
          }
        }
      }
      if (society.INSPECTOREMAILID !== "") {
        let insExists = await this.props.checkUserExists(
          society.INSPECTOREMAILID
        );
        console.log("insExists", insExists);
        if (!insExists) {
          try {
            let userIns = {};
            userIns.EmailID = society.INSPECTOREMAILID;
            userIns.Name = society.INSPECTOR;
            userIns.PhoneNo = society.INSPECTORMOBILE;
            userIns.Role = "Inspector";
            userIns.IsDeleted = false;
            await database.collection("Users").doc().set(userIns);
          } catch (error) {
            console.error("Error saving Inspector!!! ", error);
          }
        }
      }
    }
  }

  async getSocieties() {
    let societies = await this.props.getSocietyList();
    // coonsole.log('societies', societies);
    // return;
    let finalSocieties = [];
    for (let i = 0; i < societies.length; i++) {
      const society = societies[i];
      let obj = {
        key: society.key,
        DIVISION: society.DIVISION,
        DISTRICT: society.DISTRICT,
        "DR NAME": society.DRNAME,
        "DR MOBILE": society.DRMOBILE,
        "DR EMAILID": society.DREMAILID,
        AR: society.AR,
        "AR NAME": society.ARNAME,
        "AR EMAILID": society.AREMAILID,
        "AR MOBILE": society.ARMOBILE,
        SOCIETY: society.SOCIETY,
        SECRETARY: society.SECRETARY,
        "SECRETARY MOBILE": society.SECRETARYMOBILE,
        INSPECTOR: society.INSPECTOR,
        "INSPECTOR MOBILE": society.INSPECTORMOBILE,
        "HAPPY SEEDER": society.HAPPYSEEDER,
        "SUPER SEEDER": society.SUPERSEEDER,
        "PADDY STRAW CHOPPER": society.PADDYSTRAWCHOPPER,
        MULCHER: society.MULCHER,
        "RMB PLOUGH": society.RMBPLOUGH,
        "ZERO TILL DRILL": society.ZEROTILLDRILL,
        "SUPER SMS": society.SUPERSMS,
        "ROTARY SLASHER": society.ROTARYSLASHER,
        "SHRUB MASTER": society.SHRUBMASTER,
        "CUTTER CUM SPREADER": society.CUTTERCUMSPREADER,
        ROTAVATOR: society.ROTAVATOR,
        TRACTOR: society.TRACTOR,
        "TRACTOR TROLLEY": society.TRACTORTROLLEY,
        "LASER LEVELLER": society.LASERLEVELLER,
        "DIRECT SEEDER RICE  (DSR)": society.DIRECTSEEDERRICE,
        "MAIZE PLANTER MECHNICAL": society.MAIZEPLANTERMECHANICAL,
        "MAIZE PLANTER VACCUM": society.MAIZEPLANTERVACCUM,
        "SPRAY PUMP": society.SPRAYPUMP,
        "PRESSURE DISKS": society.PRESSUREDISKS,
        "DISK LOCK": society.DISKLOCK,
        "(BIO) FERTILIZER SPREADER": society.BIOFERTILIZERSPREADER,
        CULTIVATOR: society.CULTIVATOR,
        "SUB SOILER": society.SUBSOILER,
        "POWER CLEANER": society.POWERCLEANER,
        "DISC RAZOR": society.DISCRAZOR,
        "HARROW TRIPPLE": society.HARROWTRIPPLE,
        "COTTON SEED DRILL": society.COTTONSEEDDRILL,
        "WATER TANK": society.WATERTANK,
        SUHAGA: society.SUHAGA,
        "WHEAT SEED FAN ": society.WHEATSEEDFAN,
        "SEED GRINDING MACHINE": society.SEEDGRINDINGMACHINE,
        JINDRA: society.JINDRA,
        COMBINE: society.COMBINE,
        "SEELAR COTTON ": society.SEELARCOTTON,
        GENERATOR: society.GENERATOR,
        "WHEAT SEED DRILL": society.WHEATSEEDDRILL,
        REAPER: society.REAPER,
        " PLOUGH": society.PLOUGH,
        "GROUND LEVELLER": society.GROUNDLEVELLER,
        "STRAW REAPER": society.STRAWREAPER,
        "POTATO PLANTER": society.POTATOPLANTER,
        TAWIAN: society.TAWIAN,
        "SIMPLE LEVELLER": society.SIMPLELEVELLER,
        LOADER: society.LOADER,
        "POTATO DRILL": society.POTATODRILL,
        "MULTI DRILL MACHINE": society.MULTIDRILLMACHINE,
        "PUDDLER (KADDO)": society.PUDDLER,
        CHOPPER: society.CHOPPER,
        "CORN DRILL": society.CORNDRILL,
        "PEAS MACHINE": society.PEASMACHINE,
        "SEED DRILL": society.SEEDDRILL,
        CHIZLER: society.CHIZLER,
        "PADDY PLANTER": society.PADDYPLANTER,
        "DIESEL TANK": society.DIESELTANK,
        BHAUNI: society.BHAUNI,
        TOTA: society.TOTA,
        TILLER: society.TILLER,
        KARAH: society.KARAH,
        TARFALI: society.TARFALI,
        RAZOR: society.RAZOR,
        // "TAULY NEW": society.TAULYNEW,
        BALER: society.BALER ? society.BALER : 0,
        THRESHER: society.THRESHER,
        "KARCHA MACHINE": society.KARCHAMACHINE,
        RATOR: society.RATOR,
        "SUGARCANE RAGER": society.SUGARCANERAGER,
        "DISC HARROW": society.DISCHARROW,
        "CHAIN KUPPI": society.CHAINKUPPI,
        "SUGARCANE SEEDER": society.SUGARCANESEEDER,
        GRINDER: society.GRINDER,
        CHANGER: society.CHANGER,
        "TRIPPLE PLOUGH": society.TRIPPLEPLOUGH,
        // "FILAGE MAKING": society.FILAGEMAKING,
        RAKER: society.RAKER ? society.RAKER : 0,
        "CORN HARVESTER": society.CORNHARVESTER,
        CUTTER: society.CUTTER,
        "MOTOR MACHINE / MOTOR EXTRACTION MACHINE": society.MOTORMACHINE,
        "DISC PLOUGH": society.DISCPLOUGH,
        "GAINI PLOUGH": society.GAINIPLOUGH,
        "DUG PLOUGH": society.DUGPLOUGH,
        "OFFSET HARROW": society.OFFSETHARROW,
        JAAL: society.JAAL,
        TARAKADI: society.TARAKADI,
        "CARROT MACHINE": society.CARROTMACHINE,
        "DISC BED MAKER": society.DISCBEDMAKER,
        "LOADER BUCKET": society.LOADERBUCKET,
        "SEED GRADER": society.SEEDGRADER,
        "ACHAT HALL": society.ACHATHALL,
        "TAWA WATTA": society.TAWAWATTA,
        JACK: society.JACK,
        "SURFACE SEEDER": society.SURFACESEEDER ? society.SURFACESEEDER : 0,
        "SMART SEEDER": society.SMARTSEEDER ? society.SMARTSEEDER : 0,
      };
      finalSocieties.push(obj);
    }

    const csvData = this.objToCSV(finalSocieties);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Societies.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async getUserData() {
    let users = await database
      .collection("Users")
      .where("IsDeleted", "==", false)
      .orderBy("Role")
      .orderBy("Name")
      .get()
      .then((querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
          users.push({
            ID: doc.id,
            Name: doc.data().Name,
            "Login ID": doc.data().EmailID,
            Password: doc.data().PhoneNo,
            Role: doc.data().Role,
            "AuthenticationID:": doc.data().hasOwnProperty("AuthenticationID")
              ? doc.data().AuthenticationID
              : "",
          });
        });
        return users;
      });

    const csvData = this.objToCSV(users);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Users.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async getTransactionData() {
    let transactions = await this.props.getAllTransactionData();

    let finalTransactions = [];
    for (let i = 0; i < transactions.length; i++) {
      const transaction = transactions[i];

      let obj = {
        Division: transaction.Division,
        District: transaction.District,
        "AR Circle": transaction.AR,
        Village: transaction.Village,
        AR: transaction.AR,
        Society: transaction.Society,
        "Created By": transaction.ActionBy,
        "Transaction Date": moment(
          new Date(transaction.TransactionDate)
        ).format("DD-MMM-yyyy"),
      };

      finalTransactions.push(obj);
    }

    const csvData = this.objToCSV(finalTransactions);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Transactions.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async updateSocieties() {
    let societies = [
      { key: "35l5jL4RcsW7z4GRCX2R" },
      { key: "5IkrOCGUsF8IFVF8tqLJ" },
      { key: "7S6N12gulbvTd3VaQqhB" },
      { key: "ADZUCOfkvtm8QZ9FoAcK" },
      { key: "EQjldJZhK9ivJWOMTI9x" },
      { key: "FXtLnLTtsI3BvrBd1n4T" },
      { key: "KQhkUO2Qv5uMTaaaJFWB" },
      { key: "KWY0NS72jjG4JRryOJT9" },
      { key: "N6WRdJqyCPDJlM7C7afm" },
      { key: "TKS9Lz0rH13LQV8UpKc5" },
      { key: "Vd22fbgkEYa2o0tG5NLl" },
      { key: "Y36LEVwoRpUx0xgAxenq" },
      { key: "YqCd6tAfrHfUoWIqAVQb" },
      { key: "dRkIKspQ4RA2UTXGg4xE" },
      { key: "hd0ibduvh640i5Majpnx" },
      { key: "i7O6AyZZ7PK1FvYON9hl" },
      { key: "kVr84xKQ3Jgm1CN81urb" },
      { key: "kd3rICa1ciZM0xam1Wq0" },
      { key: "kh9WgVl5TwoMUH3gKWp0" },
      { key: "nVDNs2olCxN8MupkF8XN" },
      { key: "oTbno5ZNHeHcyMIJzW1k" },
      { key: "p1J1pq8yx4QA1X7Wn6kO" },
      { key: "qeP89c3fAHIPZ7PXJuSK" },
      { key: "qvWb48sg5lwoqcs2rtBM" },
      { key: "trYiVNWss0Sv76Hc7Zhl" },
      { key: "yRKYBq3Frc8fxACG2nlT" },
    ];

    for (let i = 0; i < societies.length; i++) {
      const society = societies[i];
      await database
        .collection("Societies")
        .doc(society.key)
        .update({ AR: "JAITU", AREMAILID: "arjaitu@cs.com" });
      console.log("ctr", i);
    }
  }

  // async updateTransactions() {

  //     let transactions = await this.props.getAllTransactionData();
  //     console.log(transactions.length);

  //     let societies = [{ "key": "35l5jL4RcsW7z4GRCX2R" },
  //     { "key": "5IkrOCGUsF8IFVF8tqLJ" },
  //     { "key": "7S6N12gulbvTd3VaQqhB" },
  //     { "key": "ADZUCOfkvtm8QZ9FoAcK" },
  //     { "key": "EQjldJZhK9ivJWOMTI9x" },
  //     { "key": "FXtLnLTtsI3BvrBd1n4T" },
  //     { "key": "KQhkUO2Qv5uMTaaaJFWB" },
  //     { "key": "KWY0NS72jjG4JRryOJT9" },
  //     { "key": "N6WRdJqyCPDJlM7C7afm" },
  //     { "key": "TKS9Lz0rH13LQV8UpKc5" },
  //     { "key": "Vd22fbgkEYa2o0tG5NLl" },
  //     { "key": "Y36LEVwoRpUx0xgAxenq" },
  //     { "key": "YqCd6tAfrHfUoWIqAVQb" },
  //     { "key": "dRkIKspQ4RA2UTXGg4xE" },
  //     { "key": "hd0ibduvh640i5Majpnx" },
  //     { "key": "i7O6AyZZ7PK1FvYON9hl" },
  //     { "key": "kVr84xKQ3Jgm1CN81urb" },
  //     { "key": "kd3rICa1ciZM0xam1Wq0" },
  //     { "key": "kh9WgVl5TwoMUH3gKWp0" },
  //     { "key": "nVDNs2olCxN8MupkF8XN" },
  //     { "key": "oTbno5ZNHeHcyMIJzW1k" },
  //     { "key": "p1J1pq8yx4QA1X7Wn6kO" },
  //     { "key": "qeP89c3fAHIPZ7PXJuSK" },
  //     { "key": "qvWb48sg5lwoqcs2rtBM" },
  //     { "key": "trYiVNWss0Sv76Hc7Zhl" },
  //     { "key": "yRKYBq3Frc8fxACG2nlT" },
  //     ];

  //     for (let i = 0; i < transactions.length; i++) {
  //         const transaction = transactions[i];

  //         // for (let j = 0; j < societies.length; j++) {
  //         //     const society = societies[j];

  //         //     if(society.key === transaction.SocietyID)
  //         //     {
  //         //         ctr = ctr + 1;
  //                 await database.collection("TransactionData").doc(transaction.key).update({ AR: 'JAITU', BackEndUpdate: true });
  //                 console.log(i);
  //             // }
  //         // }
  //     }
  // }

  async getAllTransactionData() {
    let result = await this.props.getAllTransactions(
      [],
      this.state.startDate,
      this.state.endDate,
      ""
    );
    let scrollID = result.scrollID;
    let transactions = result.searchResult;
    console.log("result.totalItemsCount", result.totalItemsCount);
    for (let index = 1; index < result.totalItemsCount / 10000; index++) {
      result = await this.props.getAllTransactions([], "", "", scrollID);
      result.searchResult.forEach((element) => {
        transactions.push(element);
      });
    }

    let finalTransactions = [];

    for (let i = 0; i < transactions.length; i++) {
      const transaction = transactions[i];

      let obj = {
        Division: transaction.Division,
        District: transaction.District,
        "AR Circle": transaction.AR,
        Village: transaction.Village,
        Society: transaction.Society,
        SocietyID: transaction.SocietyID,
        FarmerMobileNo: transaction.FarmerMobileNo,
        FarmerName: transaction.FarmerName,
        FatherName: transaction.FatherName,
        FarmerProfile: transaction.FarmerProfile,
        MachineCode: transaction.MachineCode,
        Machine: transaction.Machine,
        MachineHrs: transaction.MachineHrs,
        ChargesPerHrs: transaction.ChargesPerHrs,
        MachineRental: transaction.MachineRental,
        TractorRequired: transaction.TractorRequired,
        TractorRental: transaction.TractorRental,
        TotalRental: transaction.TotalRental,
        "Transaction Date": moment(
          new Date(transaction.TransactionDate)
        ).format("DD-MMM-yyyy"),
      };

      finalTransactions.push(obj);
    }
    // console.log("finalTransactions", JSON.stringify(finalTransactions));

    const csvData = this.objToCSV(finalTransactions);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Transactions.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async deleteSocieties() {
    let societies = [];

    for (let i = 0; i < societies.length; i++) {
      let society = societies[i];
      console.log(society.id + " ctr:" + i);
      await database.collection("Societies").doc(society.id).delete();
    }
  }

  async createUsers() {
    let users = [];
    for (let index = 0; index < users.length; index++) {
      let user = users[index];
      if (user.EmailID !== "" && user.Role === "AR") {
        let arExists = await this.props.checkUserExists(user.EmailID);
        console.log("arExists", arExists);
        if (!arExists) {
          try {
            let userAR = {};
            userAR.EmailID = user.EmailID;
            userAR.Name = "AR";
            userAR.PhoneNo = "111111";
            userAR.IsDeleted = false;
            userAR.Role = "AR";
            await database.collection("Users").doc().set(userAR);
          } catch (error) {
            console.error("Error saving AR!!! ", error);
          }
        }
      }
      if (user.EmailID !== "" && user.Role === "DR") {
        let drExists = await this.props.checkUserExists(user.EmailID);
        console.log("drExists", drExists);
        if (!drExists) {
          try {
            let userDR = {};
            userDR.EmailID = user.EmailID;
            userDR.Name = "DR";
            userDR.PhoneNo = "111111";
            userDR.Role = "DR";
            userDR.IsDeleted = false;
            await database.collection("Users").doc().set(userDR);
          } catch (error) {
            console.error("Error saving DR!!! ", error);
          }
        }
      }
    }
  }

  async getSocietiesWithNoTransaction() {
    let startDate = this.state.startDate; // new Date('2-Oct-2022').toISOString().split('T')[0];
    let endDate = this.state.endDate; // new Date('1-Apr-2023').toISOString().split('T')[0];

    let societiesWithTransactions =
      await this.props.getAllSocietiesThatHaveTransaction(startDate, endDate);
    console.log("societiesWithTransactions", societiesWithTransactions);
    let societies = await this.props.getSocietyList();
    console.log("societies", societies);

    // coonsole.log('societies', societies);
    // return;
    let finalSocieties = [];
    for (let i = 0; i < societies.length; i++) {
      const society = societies[i];
      if (
        societiesWithTransactions.filter((f) => f.societyID === society.key)
          .length > 0
      ) {
        console.log("Society has transaction date", society);
        continue;
      }
      let obj = {
        key: society.key,
        DIVISION: society.DIVISION,
        DISTRICT: society.DISTRICT,
        "DR NAME": society.DRNAME,
        "DR MOBILE": society.DRMOBILE,
        "DR EMAILID": society.DREMAILID,
        AR: society.AR,
        "AR NAME": society.ARNAME,
        "AR EMAILID": society.AREMAILID,
        "AR MOBILE": society.ARMOBILE,
        SOCIETY: society.SOCIETY,
        SECRETARY: society.SECRETARY,
        "SECRETARY MOBILE": society.SECRETARYMOBILE,
        INSPECTOR: society.INSPECTOR,
        "INSPECTOR MOBILE": society.INSPECTORMOBILE,
        "HAPPY SEEDER": society.HAPPYSEEDER,
        "SUPER SEEDER": society.SUPERSEEDER,
        "PADDY STRAW CHOPPER": society.PADDYSTRAWCHOPPER,
        MULCHER: society.MULCHER,
        "RMB PLOUGH": society.RMBPLOUGH,
        "ZERO TILL DRILL": society.ZEROTILLDRILL,
        "SUPER SMS": society.SUPERSMS,
        "ROTARY SLASHER": society.ROTARYSLASHER,
        "SHRUB MASTER": society.SHRUBMASTER,
        "CUTTER CUM SPREADER": society.CUTTERCUMSPREADER,
        ROTAVATOR: society.ROTAVATOR,
        TRACTOR: society.TRACTOR,
        "TRACTOR TROLLEY": society.TRACTORTROLLEY,
        "LASER LEVELLER": society.LASERLEVELLER,
        "DIRECT SEEDER RICE  (DSR)": society.DIRECTSEEDERRICE,
        "MAIZE PLANTER MECHNICAL": society.MAIZEPLANTERMECHANICAL,
        "MAIZE PLANTER VACCUM": society.MAIZEPLANTERVACCUM,
        "SPRAY PUMP": society.SPRAYPUMP,
        "PRESSURE DISKS": society.PRESSUREDISKS,
        "DISK LOCK": society.DISKLOCK,
        "(BIO) FERTILIZER SPREADER": society.BIOFERTILIZERSPREADER,
        CULTIVATOR: society.CULTIVATOR,
        "SUB SOILER": society.SUBSOILER,
        "POWER CLEANER": society.POWERCLEANER,
        "DISC RAZOR": society.DISCRAZOR,
        "HARROW TRIPPLE": society.HARROWTRIPPLE,
        "COTTON SEED DRILL": society.COTTONSEEDDRILL,
        "WATER TANK": society.WATERTANK,
        SUHAGA: society.SUHAGA,
        "WHEAT SEED FAN ": society.WHEATSEEDFAN,
        "SEED GRINDING MACHINE": society.SEEDGRINDINGMACHINE,
        JINDRA: society.JINDRA,
        COMBINE: society.COMBINE,
        "SEELAR COTTON ": society.SEELARCOTTON,
        GENERATOR: society.GENERATOR,
        "WHEAT SEED DRILL": society.WHEATSEEDDRILL,
        REAPER: society.REAPER,
        " PLOUGH": society.PLOUGH,
        "GROUND LEVELLER": society.GROUNDLEVELLER,
        "STRAW REAPER": society.STRAWREAPER,
        "POTATO PLANTER": society.POTATOPLANTER,
        TAWIAN: society.TAWIAN,
        "SIMPLE LEVELLER": society.SIMPLELEVELLER,
        LOADER: society.LOADER,
        "POTATO DRILL": society.POTATODRILL,
        "MULTI DRILL MACHINE": society.MULTIDRILLMACHINE,
        "PUDDLER (KADDO)": society.PUDDLER,
        CHOPPER: society.CHOPPER,
        "CORN DRILL": society.CORNDRILL,
        "PEAS MACHINE": society.PEASMACHINE,
        "SEED DRILL": society.SEEDDRILL,
        CHIZLER: society.CHIZLER,
        "PADDY PLANTER": society.PADDYPLANTER,
        "DIESEL TANK": society.DIESELTANK,
        BHAUNI: society.BHAUNI,
        TOTA: society.TOTA,
        TILLER: society.TILLER,
        KARAH: society.KARAH,
        TARFALI: society.TARFALI,
        RAZOR: society.RAZOR,
        // "TAULY NEW": society.TAULYNEW,
        BALER: society.BALER,
        THRESHER: society.THRESHER,
        "KARCHA MACHINE": society.KARCHAMACHINE,
        RATOR: society.RATOR,
        "SUGARCANE RAGER": society.SUGARCANERAGER,
        "DISC HARROW": society.DISCHARROW,
        "CHAIN KUPPI": society.CHAINKUPPI,
        "SUGARCANE SEEDER": society.SUGARCANESEEDER,
        GRINDER: society.GRINDER,
        CHANGER: society.CHANGER,
        "TRIPPLE PLOUGH": society.TRIPPLEPLOUGH,
        // "FILAGE MAKING": society.FILAGEMAKING,
        RAKER: society.RAKER,
        "CORN HARVESTER": society.CORNHARVESTER,
        CUTTER: society.CUTTER,
        "MOTOR MACHINE / MOTOR EXTRACTION MACHINE": society.MOTORMACHINE,
        "DISC PLOUGH": society.DISCPLOUGH,
        "GAINI PLOUGH": society.GAINIPLOUGH,
        "DUG PLOUGH": society.DUGPLOUGH,
        "OFFSET HARROW": society.OFFSETHARROW,
        JAAL: society.JAAL,
        TARAKADI: society.TARAKADI,
        "CARROT MACHINE": society.CARROTMACHINE,
        "DISC BED MAKER": society.DISCBEDMAKER,
        "LOADER BUCKET": society.LOADERBUCKET,
        "SEED GRADER": society.SEEDGRADER,
        "ACHAT HALL": society.ACHATHALL,
        "TAWA WATTA": society.TAWAWATTA,
        JACK: society.JACK,
        "SURFACE SEEDER": society.SURFACESEEDER,
        "SMART SEEDER": society.SMARTSEEDER,
      };
      finalSocieties.push(obj);
    }
    const csvData = this.objToCSV(finalSocieties);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "SocietiesWithNoTransaction.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    return (
      <div>
        <Header menuSelected="importdata" {...this.props} />
        <main className="container">
          <br />
          <div className="row">
            <div className="col-md-12">
              <div
                className="my-3 p-3 bg-white rounded shadow-sm"
                style={{ minHeight: "180px", fontSize: "14px" }}
              >
                <h3 className="border-bottom border-gray pb-2 mb-0">
                  {" "}
                  <span className="fa-stack fa-md">
                    <i className="fa fa-circle-thin fa-stack-2x"></i>
                    <i className="fa fa-file fa-stack-1x"></i>
                  </span>{" "}
                  {this.props.common.user &&
                  this.props.common.user.Role === "Admin"
                    ? "Import Raw Data"
                    : "Download Data"}
                </h3>
                <br />
                <br />
                <br />
                <br />

                <label>Please select file to import</label>
                <br />
                <br />
                <br />
                {/* {(this.props.common.user && this.props.common.user.Role === "Admin") && */}
                <div className="mb-3 col-md-6">
                  <label>Please select file to import societies</label>
                  <CSVReader
                    cssClass="csv-input"
                    label="Select .csv file"
                    onFileLoaded={this.onFileLoaded}
                    inputId="ObiWan"
                  />
                </div>
                {/* } */}
                <br />
                {/* {(this.props.common.user && this.props.common.user.Role === "Admin") &&  */}
                {/* <div className="mb-3 col-md-6">
                                <label>Update user password</label>
                                <CSVReader
                                    cssClass="csv-input"
                                    label="Select .csv file"
                                    onFileLoaded={this.onUpdatePasswordFileLoaded}
                                    inputId="ObiWan"
                                /> 
                            </div>*/}
                {/* } */}
                {this.state.errors.length > 0 && (
                  <div className="mb-3 col-md-6">
                    <button
                      className="btn btn-warning"
                      onClick={this.onExportClick}
                    >
                      Download Error File <i className="fa fa-download"></i>
                    </button>
                  </div>
                )}
                {/* <div className="mb-3 col-md-6">
                                <button className="btn btn-warning" onClick={() => this.updateSocieties()}>Update Society Data</button>
                            </div> */}
                {/* <div className="mb-3 col-md-6">
                                <button className="btn btn-warning" onClick={() => this.updateTransactions()}>Update Transactions</button>
                            </div> */}
                <div className="mb-3 col-md-6">
                  <button
                    className="btn btn-warning"
                    onClick={() => this.getSocieties()}
                  >
                    Get Society Data
                  </button>
                </div>
                <div className="mb-3 col-md-6">
                  <button
                    className="btn btn-warning"
                    onClick={() => this.getUserData()}
                  >
                    Get User Data
                  </button>
                </div>
                <div className="mb-3 col-md-6">
                  <button
                    className="btn btn-warning"
                    onClick={() => this.getAllTransactionData()}
                  >
                    Get Transaction Line Data
                  </button>
                </div>
                <div className="mb-3 col-md-6">
                  <button
                    className="btn btn-warning"
                    onClick={() => this.getSocietiesWithNoTransaction()}
                  >
                    Societies With No Transaction
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ImportData;
