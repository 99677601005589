import React, { Component } from 'react'
import {isMobile} from '../actions/Common';

class Header extends Component {

    constructor() {
        super();
        this.state = {
            showChangePasswordModal: false,
        }
        this.renderDesktop= this.renderDesktop.bind(this);
    }

    componentDidMount() {
        if (this.props.common && !this.props.common.user) {
            this.props.history.push({ pathname: "/" })
        }
    }

    renderDesktop(){
        return <div className="menubar" id="menubar">
            <div className="header">
                <div className="container">
                    <div>
                        <img src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/ips-logo.bc960b8b.png?alt=media&token=27c4558c-53ea-43d5-8ea4-5f6ff3487944" alt="" style={{ paddingRight: "20px" }} />
                        <img src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/societylogo.dfe011d3.png?alt=media&token=d74982d1-29fb-4bbd-88e6-b673d05ad470" alt="" />
                    </div>
                    <div className="header-title">Cooperative Machinery Tracker</div>
                </div>
            </div>
            <div className="menu-items">
                <div className="container">
                    <div className="menu-item" onClick={() =>this.props.history.push('/home')}>
                        Home
                    </div>
                    {(this.props.common.user && this.props.common.user.Role === "Secretary") && <div className="menu-item" 
                        onClick={() =>this.props.history.push('/transactionlist')}>
                        Transaction List
                    </div>}
                    {(this.props.common.user && this.props.common.user.Role === "Secretary") && <div className="menu-item" 
                        onClick={() =>this.props.history.push('/transactionadd')}>
                        Add Transaction
                    </div>}
                    {(this.props.common.user && (this.props.common.user.Role === "Admin" || this.props.common.user.Role === "Power")) && <div className="menu-item" 
                        onClick={() =>this.props.history.push('/importdata')}>
                        {this.props.common.user.Role === "Admin" ? "Import Data" : "Download Data"}
                    </div>}
                    {(this.props.common.user && this.props.common.user.Role === "Secretary") && <div className="menu-item" 
                        onClick={() =>this.props.history.push('/reports')}>
                        Cooperative Machine Performance Report
                    </div>}
                    {/* <div className="menu-item" 
                        onClick={() =>this.props.history.push('/changepassword')}>
                        Change Password
                    </div> */}
                   <div className="menu-item" 
                        onClick={() => {
                            this.props.signOutUser();
                            this.props.onHistory.push('/')
                        }}>
                        Sign Out
                    </div>
                </div>
            </div>
        </div>
    }

    renderMobile(){
        return <React.Fragment>
                <div className="header-mobile">
                    <div className="header-mobile-menu-button pull-left"  onClick={() =>{this.setState({isOpen:!this.state.isOpen})}}>
                        <svg viewBox="0 0 512 512">
                            <g><g><path d="M501.333,96H10.667C4.779,96,0,100.779,0,106.667s4.779,10.667,10.667,10.667h490.667c5.888,0,10.667-4.779,10.667-10.667    S507.221,96,501.333,96z" /></g></g><g><g><path d="M501.333,245.333H10.667C4.779,245.333,0,250.112,0,256s4.779,10.667,10.667,10.667h490.667    c5.888,0,10.667-4.779,10.667-10.667S507.221,245.333,501.333,245.333z" /></g></g><g><g><path d="M501.333,394.667H10.667C4.779,394.667,0,399.445,0,405.333C0,411.221,4.779,416,10.667,416h490.667    c5.888,0,10.667-4.779,10.667-10.667C512,399.445,507.221,394.667,501.333,394.667z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                        </svg>
                    </div>
                    <div className="header-mobile-businessname" onClick={() =>this.props.history.push('/home')}>
                    Cooperative Machinery Tracker
                    </div>
                </div>
                {this.state.isOpen &&<div className="menu-overlay" onClick={() => this.setState({isOpen: false})}/>}

                <div className={this.state.isOpen ? "mobile-menu-show" :"mobile-menu-hide" }>

                    <div className="menu-logo">
                        <img src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/ips-logo.bc960b8b.png?alt=media&token=27c4558c-53ea-43d5-8ea4-5f6ff3487944" alt="" style={{ paddingRight: "20px" }} />
                        <img src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/societylogo.dfe011d3.png?alt=media&token=d74982d1-29fb-4bbd-88e6-b673d05ad470" alt="" />
                    </div>
            
                    <div className="menu-content">
                        <div className="menu-row seperator" onClick={() =>this.props.history.push('/home')}>
                            <svg viewBox="0 0 476.912 476.912" width="24px">
                                <g><g><path d="M461.776,209.408L249.568,4.52c-6.182-6.026-16.042-6.026-22.224,0L15.144,209.4c-3.124,3.015-4.888,7.17-4.888,11.512    c0,8.837,7.164,16,16,16h28.2v224c0,8.837,7.163,16,16,16h112c8.837,0,16-7.163,16-16v-128h80v128c0,8.837,7.163,16,16,16h112    c8.837,0,16-7.163,16-16v-224h28.2c4.338,0,8.489-1.761,11.504-4.88C468.301,225.678,468.129,215.549,461.776,209.408z     M422.456,220.912c-8.837,0-16,7.163-16,16v224h-112v-128c0-8.837-7.163-16-16-16h-80c-8.837,0-16,7.163-16,16v128h-112v-224    c0-8.837-7.163-16-16-16h-28.2l212.2-204.88l212.28,204.88H422.456z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                            </svg>
                            <div className="menu-row-text" style={{ paddingLeft: "10px" }}>
                                Home
                            </div>
                        </div>
                        {(this.props.common.user && this.props.common.user.Role === "Secretary") && <div className="menu-row seperator"
                            onClick={() =>this.props.history.push('/transactionlist')}>
                            Transaction List
                        </div>}
                        {(this.props.common.user && this.props.common.user.Role === "Secretary") && <div className="menu-row seperator"
                            onClick={() =>this.props.history.push('/transactionadd')}>
                            Add Transaction
                        </div>}
                        {(this.props.common.user && (this.props.common.user.Role === "Admin" || this.props.common.user.Role === "Power")) && <div className="menu-row seperator"
                            onClick={() =>this.props.history.push('/importdata')}>
                            {this.props.common.user.Role === "Admin" ? "Import Data" : "Download Data"}
                        </div>}
                        {(this.props.common.user && this.props.common.user.Role === "Secretary") && <div className="menu-row seperator"
                            onClick={() =>this.props.history.push('/reports')}>
                            Cooperative Machine Performance Report
                        </div>}
                        {/* <div className="menu-row seperator"
                            onClick={() =>this.props.history.push('/changepassword')}>
                            Change Password
                        </div> */}
                        <div className="menu-row seperator"
                             onClick={() => {this.props.signOutUser();this.props.onHistory.push('/')}}>
                            Sign Out
                        </div>
                    </div>
                </div>
        </React.Fragment>
    }

    render() {
        return <div>
            {!isMobile() && this.renderDesktop()}
            {isMobile() && this.renderMobile()}
        </div>
    }
}

export default Header



// render() {
//     return <div>
//         <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-white">
//             <a className="navbar-brand mr-auto mr-lg-0" href="#" style={{ padding: "0px 20px 0px 0px" }}>
//                 <img src={require('../societylogo.png')} alt="" height="50" style={{ paddingRight: "20px" }} />
//                 <img src={require('../ips-logo.png')} alt="" height="50" /></a>
//             <button className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas" style={{ backgroundColor: "#343a40" }}>
//                 <span className="navbar-toggler-icon"></span>
//             </button>
//             <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
//                 <ul className="navbar-nav mr-auto">
//                     <button className={this.props.menuSelected === "home" ? "btn btn-success my-2 my-sm-0" : "btn btn-outline-success my-2 my-sm-0"}
//                         onClick={() => this.props.onHistory.push('/home')}
//                         type="submit"><i className="fa fa-home" aria-hidden="true" style={{ paddingRight: "10px" }}></i>Home</button>
//                     <span style={{ width: "10px" }}></span>
//                     {this.props.menuSelected !== 'changePassword' && <React.Fragment>
//                         {(this.props.common.user && this.props.common.user.Role === "Secretary") && <button className={this.props.menuSelected === "transactionlist" ? "btn btn-success my-2 my-sm-0" : "btn btn-outline-success my-2 my-sm-0"}
//                             onClick={() => this.props.onHistory.push('/transactionlist')}
//                             type="submit"><i className="fa fa-list" aria-hidden="true" style={{ paddingRight: "10px" }}></i>Transaction List</button>
//                         }
//                         {(this.props.common.user && this.props.common.user.Role === "Secretary") && 
//                             <React.Fragment>
//                             <span style={{ width: "10px" }}></span>
//                                 <button className={this.props.menuSelected === "transactionadd" ? "btn btn-success my-2 my-sm-0" : "btn btn-outline-success my-2 my-sm-0"}
//                                     onClick={() => this.props.onHistory.push('/transactionadd')}
//                                     type="submit"><i className="fa fa-plus" aria-hidden="true" style={{ paddingRight: "10px" }}></i>Add Transaction</button>
//                                 <span style={{ width: "10px" }}></span>
//                             </React.Fragment>
//                         }
//                         {(this.props.common.user && (this.props.common.user.Role === "Admin" || this.props.common.user.Role === "Power")) && <button className={this.props.menuSelected === "importdata" ? "btn btn-success my-2 my-sm-0" : "btn btn-outline-success my-2 my-sm-0"}
//                             onClick={() => this.props.onHistory.push('/importdata')}
//                             type="submit"><i className="fa fa-plus" aria-hidden="true" style={{ paddingRight: "10px" }}></i>{this.props.common.user.Role === "Admin" ? "Import Data" : "Download Data"} </button>}
//                     </React.Fragment>}

//                     {(this.props.common.user && this.props.common.user.Role !== "Secretary") && <h3><span style={{ position: "fixed", top: "2%", left: "35%" }}>Cooperative Machinery Tracker</span></h3>}
//                 </ul>

//                 <React.Fragment>
//                     {(this.props.common.user && this.props.common.user.Role === "Secretary") && <React.Fragment> <button className={this.props.menuSelected === "reports" ? "btn btn-success my-2 my-sm-0" : "btn btn-outline-success my-2 my-sm-0"}
//                         onClick={() => this.props.onHistory.push('/reports')}>Cooperative Machine Performance Report</button>&nbsp;&nbsp; </React.Fragment>}
//                     <button className="btn btn-outline-success my-2 my-sm-0"
//                         onClick={() => this.props.onHistory.push('/changepassword')}>Change Password</button>&nbsp;&nbsp;
//                 <button className="btn btn-outline-success my-2 my-sm-0"
//                         onClick={() => {
//                             this.props.signOutUser();
//                             this.props.onHistory.push('/')
//                         }}
//                         type="submit"><i className="fa fa-sign-out" aria-hidden="true" style={{ paddingRight: "10px" }}></i>Sign Out</button>
//                 </React.Fragment>
//             </div>
//         </nav>
//     </div>
// }