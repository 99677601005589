import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import { BrowserRouter } from 'react-router-dom';
import './css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';

const startApp = () => {
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

    ReactDOM.render(
        <Provider store={store}><BrowserRouter><App/></BrowserRouter></Provider>,
        document.getElementById('root')
    );
    serviceWorker.register()
}

if(window.cordova) {
  document.addEventListener('deviceready', startApp, false)
  
} else {
  startApp()
}
