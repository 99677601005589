import React, { Component } from "react";
import { auth } from "../firebase";
import "../css/App.css";
import { isMobile } from "../actions/Common";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginid: "",
      password: "",
      showRegistrationOption: false,
      societies: [],
      societyID: "",
    };
    this.signIn = this.signIn.bind(this);
    this.login = this.login.bind(this);
    this.OK = this.OK.bind(this);
  }

  async signIn() {
    if (this.state.loginid.trim() === "") {
      alert("Please provide login id");
      return;
    }
    if (this.state.password.trim() === "") {
      alert("Please provide password");
      return;
    }
    // let user =
    await this.props.login(
      this.state.loginid.trim(),
      this.state.password.trim()
    );
    if (this.props.common.user) {
      this.login();
    } else {
      alert("Invalid login id and password. Please try again.");
    }
    // auth.signInWithEmailAndPassword(this.state.loginid, this.state.password)
    // .then((result) => {
    //     // alert(result)
    //     this.login(result)
    // })
    // .catch(error => {
    //     alert("Invalid login id and password. Please try again.")
    // })
  }

  async login() {
  // result
    // console.log('result', result)
    // const user = result.user;
    // await this.props.setAuthUser(user)
    // await this.props.getUserDetails(user.uid)

    // console.log(this.props.common.user);

    if (this.props.common.user.Role === "Secretary") {
      let societies = await this.props.getSocietyDetailsByEmailID(
        this.props.common.user.EmailID
      );
      // console.log("societies", societies)
      if (societies.length > 1) {
        this.setState({ societies: societies });
      } else {
        await this.props.setSociety(societies[0]);
        this.props.onHistory.push("/home");
      }
    } else {
      this.props.onHistory.push("/home");
    }
  }

  async OK() {
    let society = this.state.societies.filter(
      (f) => f.key === this.state.societyID
    )[0];
    if (society) {
      await this.props.setSociety(society);
      this.props.onHistory.push("/home");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="row"
          style={{ marginTop: !isMobile() ? "100px" : "0px" }}
        >
          {this.state.societies.length === 0 && !isMobile() && (
            <div className="col-md-4 hiddex-xs">
              <div className="App">
                <img
                  className="mb-4"
                  src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/Image1.jpg?alt=media&token=ea5893bc-f20e-434b-90a4-228c4811a8c2"
                  alt=""
                  width="250px"
                  height="150px"
                />
              </div>
              <div className="App">
                <img
                  className="mb-4"
                  src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/Image2.jpg?alt=media&token=af903666-fbc2-4547-83fa-f5825b5f4964"
                  alt=""
                  width="250px"
                  height="150px"
                />
              </div>
              <div className="App">
                <img
                  className="mb-4"
                  src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/Image3.jpg?alt=media&token=0cf25d21-222b-4532-8af8-1ae3b56ce15a"
                  alt=""
                  width="250px"
                  height="150px"
                />
              </div>
            </div>
          )}
          <div
            className={
              this.state.societies.length === 0 ? "col-md-4" : "col-md-12"
            }
          >
            <div className="App">
              <div className="login">
                <div className="menu-logo">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/ips-logo.bc960b8b.png?alt=media&token=27c4558c-53ea-43d5-8ea4-5f6ff3487944"
                    alt=""
                    style={{ paddingRight: "20px" }}
                  />
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/societylogo.dfe011d3.png?alt=media&token=d74982d1-29fb-4bbd-88e6-b673d05ad470"
                    alt=""
                  />
                </div>
                <br />
                <h3>Cooperative Machinery Tracker v2.0</h3>
                <br />
                {this.state.societies.length === 0 && (
                  <React.Fragment>
                    <div className="text-center mb-4">
                      <p>Please enter your login id and password</p>
                    </div>
                    <div
                      className="form-label-group"
                      style={{ textAlign: "left" }}
                    >
                      <label>Login id:</label>
                      <input
                        type="email"
                        id="inputEmail"
                        onChange={(evt) =>
                          this.setState({ loginid: evt.target.value })
                        }
                        value={this.state.loginid}
                        className="form-control"
                        placeholder="Login id"
                        required=""
                      />
                    </div>
                    <br />
                    <div
                      className="form-label-group"
                      style={{ textAlign: "left" }}
                    >
                      <label>Password:</label>
                      <input
                        type="password"
                        id="inputPassword"
                        onChange={(evt) =>
                          this.setState({ password: evt.target.value })
                        }
                        value={this.state.password}
                        className="form-control"
                        placeholder="Password"
                        required=""
                      />
                    </div>
                    <br />
                    <button
                      className="btn btn-lg btn-primary btn-block"
                      onClick={this.signIn}
                    >
                      Sign in
                    </button>
                  </React.Fragment>
                )}
                {this.state.societies.length > 1 && (
                  <React.Fragment>
                    <div
                      className="form-label-group"
                      style={{ textAlign: "left" }}
                    >
                      <label>Select Society:</label>
                      <select
                        className="form-control"
                        value={this.state.society}
                        onChange={(evt) =>
                          this.setState({ societyID: evt.target.value })
                        }
                      >
                        <option value="">Select Society</option>
                        {this.state.societies.map((society) => (
                          <option value={society.key}>{society.SOCIETY}</option>
                        ))}
                      </select>
                    </div>
                    <br />
                    <button
                      className="btn btn-lg btn-primary btn-block"
                      disabled={this.state.societyID === ""}
                      onClick={this.OK}
                    >
                      OK
                    </button>
                  </React.Fragment>
                )}
                <br />
                <div className="col-12">
                  <p>© 2024</p>
                </div>
              </div>
              {/* {this.state.societies.length > 1 && <div className="form-signin" style={{ backgroundColor: "white", height: "500px" }}>
                            <div className="text-center mb-4">
                                <h3>Cooperative Machinery Tracker</h3>
                            </div>
                            
                            <br />
                            <div className="col-12">
                                <p>© 2020</p>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>} */}
            </div>
          </div>
          {this.state.societies.length === 0 && !isMobile() && (
            <div className="col-md-4 hiddex-xs">
              <div className="App">
                <img
                  className="mb-4"
                  src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/Image4.jpg?alt=media&token=784813a0-cd51-43b4-a4d7-bc7620110008"
                  alt=""
                  width="250px"
                  height="150px"
                  style={{ paddingRight: "20px" }}
                />
              </div>
              <div className="App">
                <img
                  className="mb-4"
                  src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/Image5.jpg?alt=media&token=ee506b0a-1a9a-4bd6-b406-95192ea8c19e"
                  alt=""
                  width="250px"
                  height="150px"
                  style={{ paddingRight: "20px" }}
                />
              </div>
              <div className="App">
                <img
                  className="mb-4"
                  src="https://firebasestorage.googleapis.com/v0/b/ips2-0420.appspot.com/o/Image6.png?alt=media&token=160d8749-02b6-4845-9958-416f5f58d2af"
                  alt=""
                  width="250px"
                  height="150px"
                  style={{ paddingRight: "20px" }}
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
