import React, { Component } from 'react'
import Header from './Header'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isMobile } from '../actions/Common';

class TransactionList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transactionlist: []
        }

    }

    async componentDidMount() {
        let transactionlist = []
        if(this.props.common.society)
            transactionlist = await this.props.getTransactionList(this.props.common.society.key)
        this.setState({ transactionlist: transactionlist })
    }

    render() {
        return <div>
            <Header menuSelected="transactionlist" {...this.props} />
            <main className="container">
                {/* <br /> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ minHeight: "180px", fontSize: "14px" }}>
                            <h3 className="border-bottom border-gray pb-2 mb-0"> <span className="fa-stack fa-md">
                                <i className="fa fa-circle-thin fa-stack-2x"></i>
                                <i className="fa fa-list fa-stack-1x"></i>
                            </span> Transaction List
                            </h3>
                            <br />
                            {/* <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                            <label>Date</label>
                                            <input type="date" className="form-control"/>
                                        </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                            <label>Village</label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                            <label>Farmer Name / Mobile No</label>
                                            <input type="text" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                        <br/>
                                        <button className="btn btn-default">Clear</button>
                                        <button className="btn btn-primary">Search</button>
                                </div>
                            </div> */}
                            {!isMobile() && <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ verticalAlign: "top", width:"10%"}}>Date</th>
                                        <th scope="col" style={{ verticalAlign: "top" , width:"20%"}}>Farmer Details</th>
                                        <th scope="col" style={{ verticalAlign: "top", textAlign:"center"}}>Details of Implements rented</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.transactionlist.map((t,index) => <tr id={index}>
                                        <td>{moment(new Date(t.TransactionDate)).format("DD-MMM-yyyy")}</td>
                                        <td> 
                                            <div><b>Farmer Name:</b> {t.FarmerName}</div>
                                            <div><b>Village:</b> {t.Village}</div>
                                            <div><b>Mobile No:</b> {t.FarmerMobileNo}</div>
                                            <div><b>Father Name:</b> {t.FatherName}</div>
                                            <div><b>Profile:</b> {t.FarmerProfile}</div>
                                        </td>
                                        <td>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{ verticalAlign: "top", textAlign: "center" }}>ਮਸ਼ੀਨਾਂ (Machine(s))</th>
                                                        <th style={{ verticalAlign: "top", textAlign: "center"  }}>ਘੰਟੇ) ਕਿਰਾਇਆ (Hour(s) Rented)</th>
                                                        <th style={{ verticalAlign: "top", textAlign: "center"  }}>ਘੰਟੇ ਪ੍ਰਤੀ ਕਿਰਾਇਆ ਕਿਰਾਏ (Rental Charges Per Hour)</th>
                                                        <th>ਕਿਰਾਏ ਦਾ ਖਰਚਾ (Rental Charges)</th>
                                                        <th style={{ verticalAlign: "top", textAlign: "center"  }}>ਟਰੈਕਟਰ ਲੋੜੀਂਦਾ (Tractor Required)</th>
                                                        <th style={{ verticalAlign: "top", textAlign: "center"  }}>ਟਰੈਕਟਰ ਕਿਰਾਇਆ (Tractor Rental)</th>
                                                        <th style={{ verticalAlign: "top" }}>ਕੁਲ ਕਿਰਾਇਆ (Total Rental)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {t.Machines.map((m,index1) => <tr id={index1}>
                                                        <td>{m.Machine}</td>
                                                        <td style={{ textAlign: "right"  }}>{m.MachineHrs}</td>
                                                        <td style={{ textAlign: "right"  }}>{m.ChargesPerHrs}</td>
                                                        <td style={{ textAlign: "right"  }}>{m.MachineRental}</td>
                                                        <td style={{ textAlign: "center"  }}>{m.TractorRequired ? 'Yes' : 'No'}</td>
                                                        <td style={{ textAlign: "right"  }}>{m.TractorRental ? m.TractorRental : 0}</td>
                                                        <td style={{ textAlign: "right"  }}>{m.TotalRental ? m.TotalRental : 0}</td>
                                                    </tr>)}
                                                    <tr>
                                                        <td style={{ fontWeight: "bold" }}> Grand Total </td>
                                                        <td style={{ textAlign: "right", fontWeight: "bold" }}> {t.TotalMachineHrs}  </td>
                                                        <td style={{ textAlign: "right", fontWeight: "bold" }}>  {t.TotalChargesPerHrs}  </td>
                                                        <td style={{ textAlign: "right", fontWeight: "bold" }}> {t.TotalMachineRental}  </td>
                                                        <td style={{ fontWeight: "bold",  textAlign: "center" }}> - </td>
                                                        <td style={{ textAlign: "right", fontWeight: "bold" }}> {t.TotalTractorRental} </td>
                                                        <td style={{ textAlign: "right", fontWeight: "bold" }}> {t.TotalTotalRental} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td>
                                            <div class="btn-group">
                                                <div>
                                                    <Link to={{ pathname: "/transaction/edit/", state: { transaction: t } }}
                                                        type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i></Link>
                                                </div>
                                            </div>
                                        </td>                                   
                                    </tr>
                                    )}
                                </tbody>
                            </table>}
                            {isMobile() && <React.Fragment>
                                {this.state.transactionlist.map((t,index) => <div key={"transaction_"+ index} className="transaction">
                                            <div>{moment(new Date(t.TransactionDate)).format("DD-MMM-yyyy")}</div>
                                            <div>Farmer Name: {t.FarmerName}</div>
                                            <div>Village: {t.Village}</div>
                                            <div>Mobile No: {t.FarmerMobileNo}</div>
                                            <div>Father Name: {t.FatherName}</div>
                                            <div>Profile: {t.FarmerProfile}</div>
                                            <div><b>Machines</b></div>
                                            {t.Machines.map((machine, index) =>  <div  key={"machine_" + index}
                                                className="transaction-machine">
                                                <div>{machine.Machine}</div>
                                                <div>{machine.MachineHrs} (hrs) X ₹ {machine.ChargesPerHrs}  = ₹ {machine.MachineRental}</div>
                                                {machine.TractorRequired && <div>Tractor rental: ₹ {machine.TractorRental}</div>}
                                                <div>Total rental: ₹ {machine.TotalRental}</div>
                                                
                                            </div>)}
                                            <div className="transaction-footer">
                                            <Link to={{ pathname: "/transaction/edit/", state: { transaction: t } }}
                                                        type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i> Edit</Link>
                                            </div>
                                </div>)}
                            </React.Fragment>}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}

export default TransactionList
