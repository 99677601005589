import React, { Component } from "react";
// import { Pie, Bar } from 'react-chartjs-2';
import Loader from "./Loader";
import Header from "./Header";
import moment from "moment";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date("2-Oct-2024").toISOString().split("T")[0],
      // endDate: new Date().toISOString().split('T')[0],
      // startDate: new Date().toISOString().split('T')[0],
      // endDate: new Date().toISOString().split('T')[0],
      endDate: new Date("1-Apr-2025").toISOString().split("T")[0],
      years: [
        // {StartDate:'1-Apr-2021', EndDate:'31-Mar-2022'},
        // {StartDate:'1-Apr-2020', EndDate:'31-Mar-2021'},
        {
          StartDate: "2-Oct-2024",
          EndDate: "1-Apr-2025",
          DisplayStartDate: "1-Oct-2024",
          DisplayEndDate: "31-Mar-2025",
        },
        {
          StartDate: "2-Apr-2024",
          EndDate: "1-Oct-2024",
          DisplayStartDate: "1-Apr-2024",
          DisplayEndDate: "30-Sep-2024",
        },
        {
          StartDate: "2-Oct-2023",
          EndDate: "1-Apr-2024",
          DisplayStartDate: "1-Oct-2023",
          DisplayEndDate: "31-Mar-2024",
        },
        {
          StartDate: "2-Apr-2023",
          EndDate: "1-Oct-2023",
          DisplayStartDate: "1-Apr-2023",
          DisplayEndDate: "30-Sep-2023",
        },
        {
          StartDate: "2-Oct-2022",
          EndDate: "1-Apr-2023",
          DisplayStartDate: "1-Oct-2022",
          DisplayEndDate: "31-Mar-2023",
        },
        {
          StartDate: "2-Apr-2022",
          EndDate: "1-Oct-2022",
          DisplayStartDate: "1-Apr-2022",
          DisplayEndDate: "30-Sep-2022",
        },
        {
          StartDate: "2-Oct-2021",
          EndDate: "1-Apr-2022",
          DisplayStartDate: "1-Oct-2021",
          DisplayEndDate: "31-Mar-2022",
        },
        {
          StartDate: "2-Apr-2021",
          EndDate: "1-Oct-2021",
          DisplayStartDate: "1-Apr-2021",
          DisplayEndDate: "30-Sep-2021",
        },
        {
          StartDate: "2-Oct-2020",
          EndDate: "1-Apr-2021",
          DisplayStartDate: "1-Oct-2021",
          DisplayEndDate: "31-Mar-2022",
        },
      ],
      selectedYearIndex: "0",
      machineTypes: [
        { code: "HAPPYSEEDER", name: "HAPPY SEEDER" },
        { code: "SUPERSEEDER", name: "SUPER SEEDER" },
        { code: "SURFACESEEDER", name: "SURFACE SEEDER" },
        { code: "RMBPLOUGH", name: "RMB PLOUGH" },
        { code: "ROTAVATOR", name: "ROTAVATOR" },
        { code: "ZEROTILLDRILL", name: "ZERO TILL DRILL" },
        { code: "PADDYSTRAWCHOPPER", name: "PADDY STRAW CHOPPER" },
        { code: "MULCHER", name: "MULCHER" },
      ],
      fullMachineTypes: [
        { code: "HAPPYSEEDER", name: "HAPPY SEEDER" },
        { code: "SUPERSEEDER", name: "SUPER SEEDER" },
        { code: "SUPERSMS", name: "SUPER SMS" },
        { code: "RMBPLOUGH", name: "RMB PLOUGH" },
        { code: "ROTAVATOR", name: "ROTAVATOR" },
        { code: "ZEROTILLDRILL", name: "ZERO TILL DRILL" },
        { code: "PADDYSTRAWCHOPPER", name: "PADDY STRAW CHOPPER" },
        { code: "MULCHER", name: "MULCHER" },
        { code: "CHOPPER", name: "CHOPPER" },
        { code: "ROTARYSLASHER", name: "ROTARY SLASHER" },
        { code: "SHRUBMASTER", name: "SHRUB MASTER" },
        { code: "CUTTERCUMSPREADER", name: "CUTTER CUM SPREADER" },
        { code: "TRACTOR", name: "TRACTOR" },
        { code: "TRACTORTROLLEY", name: "TRACTOR TROLLEY" },
        { code: "LASERLEVELLER", name: "LASER LEVELLER" },
        { code: "DIRECTSEEDERRICE", name: "DIRECT SEEDER RICE  (DSR)" },
        { code: "MAIZEPLANTERMECHANICAL", name: "MAIZE PLANTER MECHNICAL" },
        { code: "MAIZEPLANTERVACCUM", name: "MAIZE PLANTER VACCUM" },
        { code: "SPRAYPUMP", name: "SPRAY PUMP" },
        { code: "PRESSUREDISKS", name: "PRESSURE DISKS" },
        { code: "DISKLOCK", name: "DISK LOCK" },
        { code: "BIOFERTILIZERSPREADER", name: "(BIO) FERTILIZER SPREADER" },
        { code: "CULTIVATOR", name: "CULTIVATOR" },
        { code: "SUBSOILER", name: "SUB SOILER" },
        { code: "POWERCLEANER", name: "POWER CLEANER" },
        { code: "DISCRAZOR", name: "DISC RAZOR" },
        { code: "HARROWTRIPPLE", name: "HARROW TRIPPLE" },
        { code: "COTTONSEEDDRILL", name: "COTTON SEED DRILL" },
        { code: "WATERTANK", name: "WATER TANK" },
        { code: "SUHAGA", name: "SUHAGA" },
        { code: "WHEATSEEDFAN", name: "WHEAT SEED FAN " },
        { code: "SEEDGRINDINGMACHINE", name: "SEED GRINDING MACHINE" },
        { code: "JINDRA", name: "JINDRA" },
        { code: "COMBINE", name: "COMBINE" },
        { code: "SEELARCOTTON", name: "SEELAR COTTON " },
        { code: "GENERATOR", name: "GENERATOR" },
        { code: "WHEATSEEDDRILL", name: "WHEAT SEED DRILL" },
        { code: "REAPER", name: "REAPER" },
        { code: "PLOUGH", name: "PLOUGH" },
        { code: "GROUNDLEVELLER", name: "GROUND LEVELLER" },
        { code: "STRAWREAPER", name: "STRAW REAPER" },
        { code: "POTATOPLANTER", name: "POTATO PLANTER" },
        { code: "TAWIAN", name: "TAWIAN" },
        { code: "SIMPLELEVELLER", name: "SIMPLE LEVELLER" },
        { code: "LOADER", name: "LOADER" },
        { code: "POTATODRILL", name: "POTATO DRILL" },
        { code: "MULTIDRILLMACHINE", name: "MULTI DRILL MACHINE" },
        { code: "PUDDLER", name: "PUDDLER (KADDO)" },
        { code: "CORNDRILL", name: "CORN DRILL" },
        { code: "PEASMACHINE", name: "PEAS MACHINE" },
        { code: "SEEDDRILL", name: "SEED DRILL" },
        { code: "CHIZLER", name: "CHIZLER" },
        { code: "PADDYPLANTER", name: "PADDY PLANTER" },
        { code: "DIESELTANK", name: "DIESEL TANK" },
        { code: "BHAUNI", name: "BHAUNI" },
        { code: "TOTA", name: "TOTA" },
        { code: "TILLER", name: "TILLER" },
        { code: "KARAH", name: "KARAH" },
        { code: "TARFALI", name: "TARFALI" },
        { code: "RAZOR", name: "RAZOR" },
        // { "code": "TAULYNEW", "name": "TAULY NEW" },
        { code: "BALER", name: "BALER" },
        { code: "THRESHER", name: "THRESHER" },
        { code: "KARCHAMACHINE", name: "KARCHA MACHINE" },
        { code: "RATOR", name: "RATOR" },
        { code: "SUGARCANERAGER", name: "SUGARCANE RAGER" },
        { code: "DISCHARROW", name: "DISC HARROW" },
        { code: "CHAINKUPPI", name: "CHAIN KUPPI" },
        { code: "SUGARCANESEEDER", name: "SUGARCANE SEEDER" },
        { code: "GRINDER", name: "GRINDER" },
        { code: "CHANGER", name: "CHANGER" },
        { code: "TRIPPLEPLOUGH", name: "TRIPPLE PLOUGH" },
        // { "code": "FILAGEMAKING", "name": "FILAGE MAKING" },
        { code: "RAKER", name: "RAKER" },

        { code: "CORNHARVESTER", name: "CORN HARVESTER" },
        { code: "CUTTER", name: "CUTTER" },
        {
          code: "MOTORMACHINE",
          name: "MOTOR MACHINE / MOTOR EXTRACTION MACHINE",
        },
        { code: "DISCPLOUGH", name: "DISC PLOUGH" },
        { code: "GAINIPLOUGH", name: "GAINI PLOUGH" },
        { code: "DUGPLOUGH", name: "DUG PLOUGH" },
        { code: "OFFSETHARROW", name: "OFFSET HARROW" },
        { code: "JAAL", name: "JAAL" },
        { code: "TARAKADI", name: "TARAKADI" },
        { code: "CARROTMACHINE", name: "CARROT MACHINE" },
        { code: "DISCBEDMAKER", name: "DISC BED MAKER" },
        { code: "LOADERBUCKET", name: "LOADER BUCKET" },
        { code: "SEEDGRADER", name: "SEED GRADER" },
        { code: "ACHATHALL", name: "ACHAT HALL" },
        { code: "TAWAWATTA", name: "TAWA WATTA" },
        { code: "JACK", name: "JACK" },
        { code: "SURFACESEEDER", name: "SURFACE SEEDER" },
        { code: "SMARTSEEDER", name: "SMART SEEDER" },
      ],
      societyID: "",
      society: null,
      machineData: [],
      fullMachineData: [],
      viewMore: false,
      machinesUtilization: {},
      societies: [],
      selectedSocieties: [],
      machineDataTotals: {
        "Total Machines": 0,
        "Chargeable Hrs": 0,
        "Total Revenue": 0,
        "Total Hrs": 0,
        "Avg Utilization Hrs": 0,
        "Avg Utilization Revenue": 0,
        "Free Hrs": 0,
      },
      fullMachineDataTotals: {
        "Total Machines": 0,
        "Chargeable Hrs": 0,
        "Total Revenue": 0,
        "Total Hrs": 0,
        "Avg Utilization Hrs": 0,
        "Avg Utilization Revenue": 0,
        "Free Hrs": 0,
      },
      inspectors: [],
      societyList: [],
      loading: true,
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.loadMachineSummary = this.loadMachineSummary.bind(this);
    this.onSocietyChange = this.onSocietyChange.bind(this);
    this.onInspectorChange = this.onInspectorChange.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onExportTransactions = this.onExportTransactions.bind(this);
    this.objToCSV = this.objToCSV.bind(this);
  }

  async componentDidMount() {
    let societies = [];
    let selectedSocieties = [];
    let societyID = "";
    let inspectors = [];
    let societyList = [];
    let startDate = "";
    let endDate = "";
    let selectedYearIndex = 0;

    if (this.props.selectedYearIndex) {
      if (Number(this.props.selectedYearIndex) >= 0) {
        selectedYearIndex = this.props.selectedYearIndex;
      }
    }
    startDate = new Date(this.state.years[Number(selectedYearIndex)].StartDate)
      .toISOString()
      .split("T")[0];
    endDate = new Date(this.state.years[Number(selectedYearIndex)].EndDate)
      .toISOString()
      .split("T")[0];
    this.setState({
      startDate: startDate,
      endDate: endDate,
      selectedYearIndex: selectedYearIndex,
    });

    if (this.props.common.user && this.props.common.user.Role === "Secretary") {
      societies.push(this.props.common.society);
      societyList.push(this.props.common.society);
      selectedSocieties.push(this.props.common.society.key);
      societyID = this.props.common.society.key;
    } else if (
      this.props.common.user &&
      this.props.common.user.Role === "Inspector"
    ) {
      societies = await this.props.getSocietyDetailsByInspectorEmailID(
        this.props.common.user.EmailID
      );

      for (let i = 0; i < societies.length; i++) {
        const society = societies[i];
        societyList.push(society);
        selectedSocieties.push(society.key);
      }
      if (societyList.length > 1) {
        societyList.unshift({ key: "", SOCIETY: "ALL SOCIETIES" });
      }
    }
    if (this.props.common.user && this.props.common.user.Role === "AR") {
      inspectors = await this.props.getInspectorByAREmailID(
        this.props.common.user.EmailID
      );

      for (let l = 0; l < inspectors.length; l++) {
        let inspectorSocieties =
          await this.props.getSocietyDetailsByInspectorEmailID(
            inspectors[l].emailID
          );
        for (let o = 0; o < inspectorSocieties.length; o++) {
          const society = inspectorSocieties[o];

          if (society.AREMAILID === this.props.common.user.EmailID) {
            societies.push(society);
            societyList.push(society);
            selectedSocieties.push(society.key);
          }
        }
      }
      if (societyList.length > 1)
        societyList.unshift({ key: "", SOCIETY: "ALL SOCIETIES" });
      if (inspectors.length > 1) {
        inspectors.unshift({ emailID: "", name: "ALL INSPECTORS" });
      }
    }
    if (this.props.selectedAR && this.props.selectedAR !== "") {
      let arEmailID = await this.props.getARDetailsByARCircle(
        this.props.selectedAR
      );
      // console.log("arEmailID", arEmailID);

      inspectors = await this.props.getInspectorByAREmailID(arEmailID);

      for (let l = 0; l < inspectors.length; l++) {
        let inspectorSocieties =
          await this.props.getSocietyDetailsByInspectorEmailID(
            inspectors[l].emailID
          );
        for (let o = 0; o < inspectorSocieties.length; o++) {
          const society = inspectorSocieties[o];

          if (society.AREMAILID === arEmailID) {
            societies.push(society);
            societyList.push(society);
            selectedSocieties.push(society.key);
          }
        }
      }
      if (societyList.length > 1)
        societyList.unshift({ key: "", SOCIETY: "ALL SOCIETIES" });
      if (inspectors.length > 1) {
        inspectors.unshift({ emailID: "", name: "ALL INSPECTORS" });
      }
    }
    this.setState({
      societies: societies,
      selectedSocieties: selectedSocieties,
      societyID: societyID,
      inspectors: inspectors,
      societyList: societyList,
    });
    this.loadMachineSummary(selectedSocieties, startDate, endDate);
  }

  async loadMachineSummary(selectedSocieties, startDate, endDate) {
    console.log("loadMachineSummary startDate", startDate);
    console.log("loadMachineSummary endDate", endDate);

    this.setState({ loading: true });
    console.log("selectedSocieties", selectedSocieties);

    let machineWiseSummary = await this.props.getMachineWiseSummary(
      selectedSocieties,
      startDate,
      endDate
    );
    let machineWiseFreeHrsSummary =
      await this.props.getMachineWiseFreeHrsSummary(
        selectedSocieties,
        startDate,
        endDate
      );
    let tractorSummary = await this.props.getTractorSummary(
      selectedSocieties,
      startDate,
      endDate
    );
    console.log("machineWiseSummary", machineWiseSummary);
    console.log("machineWiseFreeHrsSummary", machineWiseFreeHrsSummary);
    console.log("tractorSummary", tractorSummary);
    let machineData = [];
    for (let i = 0; i < this.state.machineTypes.length; i++) {
      const machineType = this.state.machineTypes[i];
      let machine = {
        Machine: machineType.name,
        "Total Machines": 0, //(this.state.society && this.state.society[machineType.code]) ? this.state.society[machineType.code] : 0,
        "Chargeable Hrs": 0,
        "Total Revenue": 0,
        "Total Hrs": 0,
        "Avg Utilization Hrs": 0,
        "Avg Utilization Revenue": 0,
        "Free Hrs": 0,
      };

      for (let q = 0; q < selectedSocieties.length; q++) {
        const societyID = selectedSocieties[q];
        let society = this.state.societies.filter(
          (f) => f.key !== "" && f.key === societyID
        )[0];
        if (society) {
          console.log("society", society);
          let tmpCount = 0;
          if (society.hasOwnProperty(machineType.code)) {
            tmpCount = Number(society[machineType.code]);
          }
          machine["Total Machines"] = machine["Total Machines"] + tmpCount;
        }
      }

      let obj1 = machineWiseSummary.filter(
        (m) => m.machineCode === machineType.code
      );
      if (obj1 && obj1.length > 0) {
        machine["Chargeable Hrs"] = Number(obj1[0].machine_hrs).toFixed(2);
        machine["Total Revenue"] = obj1[0].machine_revenue;
      }

      let obj2 = machineWiseFreeHrsSummary.filter(
        (p) => p.machineCode === machineType.code
      );
      if (obj2 && obj2.length > 0) {
        machine["Free Hrs"] = Number(obj2[0].machine_hrs).toFixed(2);
      }

      machine["Total Hrs"] = Number(
        Number(machine["Chargeable Hrs"]) + Number(machine["Free Hrs"])
      ).toFixed(2);

      if (machine["Total Machines"] > 0)
        machine["Avg Utilization Hrs"] = Number(
          Number(machine["Total Hrs"]) / Number(machine["Total Machines"])
        ).toFixed(2);

      if (machine["Chargeable Hrs"] > 0)
        machine["Avg Utilization Revenue"] = Number(
          Number(machine["Total Revenue"]) / Number(machine["Chargeable Hrs"])
        ).toFixed(2);

      machineData.push(machine);
    }
    if (tractorSummary) {
      machineData
        .filter((f) => f.Machine === "TRACTOR")
        .map((machine) => {
          machine["Total Revenue"] = Number(
            Number(machine["Total Revenue"]) +
              Number(tractorSummary.tractor_revenue)
          ).toFixed(2);
        });
    }

    let machinesRevenue = {
      labels: [],
      datasets: [
        {
          label: "Total Revenue",
          backgroundColor: [
            "#99A1C6",
            "#51CDA0",
            "#DF7970",
            "#4C9CA0",
            "#AE7D99",
            "#C9D45C",
            "#5592AD",
            "#DF874D",
            "#52BCA8",
            "#8E7AA3",
            "#E3CB64",
            "#C77B85",
            "#C39762",
          ],
          hoverBackgroundColor: [
            "#99A1C6",
            "#51CDA0",
            "#DF7970",
            "#4C9CA0",
            "#AE7D99",
            "#C9D45C",
            "#5592AD",
            "#DF874D",
            "#52BCA8",
            "#8E7AA3",
            "#E3CB64",
            "#C77B85",
            "#C39762",
          ],
          data: [],
        },
      ],
    };

    let machinesUtilization = {
      labels: [],
      datasets: [
        {
          label: "Free Hrs",
          data: [],
          backgroundColor: "#FF6384",
        },
        {
          label: "Chargeable Hrs",
          data: [],
          backgroundColor: "#36A2EB",
        },
      ],
    };

    let machineDataTotals = {
      "Total Machines": 0,
      "Chargeable Hrs": 0,
      "Free Hrs": 0,
      "Total Hrs": 0,
      "Total Revenue": 0,
      "Avg Utilization Hrs": 0,
      "Avg Utilization Revenue": 0,
    };

    for (let i = 0; i < machineData.length; i++) {
      const objData = machineData[i];

      machinesRevenue.labels.push(objData.Machine);
      machinesRevenue.datasets[0].data.push(objData["Avg Utilization Revenue"]);

      machinesUtilization.labels.push(objData.Machine);

      for (let j = 0; j < machineData.length; j++) {
        machinesUtilization.datasets[0].data.push(0);
        machinesUtilization.datasets[1].data.push(0);
      }
      machinesUtilization.datasets[0].data[i] = objData["Free Hrs"];
      machinesUtilization.datasets[1].data[i] = objData["Chargeable Hrs"];

      machineDataTotals["Total Machines"] =
        Number(machineDataTotals["Total Machines"]) +
        Number(objData["Total Machines"]);
      machineDataTotals["Chargeable Hrs"] = Number(
        Number(machineDataTotals["Chargeable Hrs"]) +
          Number(objData["Chargeable Hrs"])
      ).toFixed(2);
      machineDataTotals["Free Hrs"] = Number(
        Number(machineDataTotals["Free Hrs"]) + Number(objData["Free Hrs"])
      ).toFixed(2);
      machineDataTotals["Total Hrs"] = Number(
        Number(machineDataTotals["Total Hrs"]) + Number(objData["Total Hrs"])
      ).toFixed(2);
      machineDataTotals["Total Revenue"] = Number(
        Number(machineDataTotals["Total Revenue"]) +
          Number(objData["Total Revenue"])
      ).toFixed(2);
    }

    if (machineDataTotals["Total Machines"] > 0)
      machineDataTotals["Avg Utilization Hrs"] = Number(
        Number(machineDataTotals["Total Hrs"]) /
          Number(machineDataTotals["Total Machines"])
      ).toFixed(2);

    if (machineDataTotals["Chargeable Hrs"] > 0)
      machineDataTotals["Avg Utilization Revenue"] = Number(
        Number(machineDataTotals["Total Revenue"]) /
          Number(machineDataTotals["Chargeable Hrs"])
      ).toFixed(2);

    let fullMachineData = [];
    for (let i = 0; i < this.state.fullMachineTypes.length; i++) {
      const machineType = this.state.fullMachineTypes[i];
      let machine = {
        Machine: machineType.name,
        "Total Machines": 0,
        "Chargeable Hrs": 0,
        "Total Revenue": 0,
        "Total Hrs": 0,
        "Avg Utilization Hrs": 0,
        "Avg Utilization Revenue": 0,
        "Free Hrs": 0,
      };

      for (let q = 0; q < selectedSocieties.length; q++) {
        const societyID = selectedSocieties[q];
        let society = this.state.societies.filter(
          (f) => f.key !== "" && f.key === societyID
        )[0];
        if (society) {
          machine["Total Machines"] =
            machine["Total Machines"] + Number(society[machineType.code]);
        }
      }

      let obj1 = machineWiseSummary.filter(
        (m) => m.machineCode === machineType.code
      );
      if (obj1 && obj1.length > 0) {
        machine["Chargeable Hrs"] = obj1[0].machine_hrs;
        machine["Total Revenue"] = obj1[0].machine_revenue;
      }

      let obj2 = machineWiseFreeHrsSummary.filter(
        (p) => p.machineCode === machineType.code
      );
      if (obj2 && obj2.length > 0) {
        machine["Free Hrs"] = obj2[0].machine_hrs;
      }

      machine["Total Hrs"] = Number(
        Number(machine["Chargeable Hrs"]) + Number(machine["Free Hrs"])
      ).toFixed(2);

      if (machine["Total Machines"] > 0)
        machine["Avg Utilization Hrs"] = Number(
          Number(machine["Total Hrs"]) / Number(machine["Total Machines"])
        ).toFixed(2);

      if (machine["Chargeable Hrs"] > 0)
        machine["Avg Utilization Revenue"] = Number(
          Number(machine["Total Revenue"]) / Number(machine["Chargeable Hrs"])
        ).toFixed(2);

      fullMachineData.push(machine);
    }
    if (tractorSummary) {
      fullMachineData
        .filter((f) => f.Machine === "TRACTOR")
        .map((machine) => {
          machine["Total Revenue"] = Number(
            Number(machine["Total Revenue"]) +
              Number(tractorSummary.tractor_revenue)
          ).toFixed(2);
        });
    }

    let fullMachineDataTotals = {
      "Total Machines": 0,
      "Chargeable Hrs": 0,
      "Total Revenue": 0,
      "Total Hrs": 0,
      "Avg Utilization Hrs": 0,
      "Avg Utilization Revenue": 0,
      "Free Hrs": 0,
    };

    for (let i = 0; i < fullMachineData.length; i++) {
      const objData = fullMachineData[i];

      fullMachineDataTotals["Total Machines"] =
        Number(fullMachineDataTotals["Total Machines"]) +
        Number(objData["Total Machines"]);
      fullMachineDataTotals["Chargeable Hrs"] = Number(
        Number(fullMachineDataTotals["Chargeable Hrs"]) +
          Number(objData["Chargeable Hrs"])
      ).toFixed(2);
      fullMachineDataTotals["Free Hrs"] = Number(
        Number(fullMachineDataTotals["Free Hrs"]) + Number(objData["Free Hrs"])
      ).toFixed(2);
      fullMachineDataTotals["Total Hrs"] = Number(
        Number(fullMachineDataTotals["Total Hrs"]) +
          Number(objData["Total Hrs"])
      ).toFixed(2);
      fullMachineDataTotals["Total Revenue"] = Number(
        Number(fullMachineDataTotals["Total Revenue"]) +
          Number(objData["Total Revenue"])
      ).toFixed(2);
    }

    if (fullMachineDataTotals["Total Machines"] > 0)
      fullMachineDataTotals["Avg Utilization Hrs"] = Number(
        Number(fullMachineDataTotals["Total Hrs"]) /
          Number(fullMachineDataTotals["Total Machines"])
      ).toFixed(2);

    if (fullMachineDataTotals["Chargeable Hrs"] > 0)
      fullMachineDataTotals["Avg Utilization Revenue"] = Number(
        Number(fullMachineDataTotals["Total Revenue"]) /
          Number(fullMachineDataTotals["Chargeable Hrs"])
      ).toFixed(2);

    this.setState({
      machineData: machineData,
      machineDataTotals: machineDataTotals,
      fullMachineData: fullMachineData,
      fullMachineDataTotals: fullMachineDataTotals,
      machinesRevenue: machinesRevenue,
      machinesUtilization: machinesUtilization,
      loading: false,
    });
  }

  onClearSearch() {
    let selectedSocieties = [];
    let societyID = "";
    let inspectorID = "";
    let societyList = [];

    for (let i = 0; i < this.state.societies.length; i++) {
      const society = this.state.societies[i];
      societyList.push(society);
      selectedSocieties.push(society.key);
    }

    if (this.state.societies.length === 1) {
      societyID = this.state.societies[0].key;
    }
    societyList.unshift({ key: "", SOCIETY: "ALL SOCIETIES" });

    this.setState({
      startDate: new Date("2-Oct-2020").toISOString().split("T")[0],
      societyList: societyList,
      endDate: new Date().toISOString().split("T")[0],
      selectedSocieties: selectedSocieties,
      societyID: societyID,
      viewMore: false,
      inspectorID: inspectorID,
    });

    this.loadMachineSummary(
      selectedSocieties,
      new Date("2-Oct-2020").toISOString().split("T")[0],
      new Date().toISOString().split("T")[0]
    );
  }

  onSearchClick() {
    this.loadMachineSummary(
      this.state.selectedSocieties,
      this.state.startDate,
      this.state.endDate
    );
  }

  onExportClick() {
    let dataToExport = [];
    if (this.state.viewMore) {
      dataToExport = this.state.fullMachineData;
    } else {
      dataToExport = this.state.machineData;
    }
    console.log("dataToExport", dataToExport);
    const csvData = this.objToCSV(dataToExport);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Report.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  objToCSV(obj) {
    const csvRows = [];
    const headers = Object.keys(obj[0]);
    csvRows.push(headers.join(","));

    for (const row of obj) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
    return csvRows.join("\n");
  }

  onInspectorChange(e) {
    let inspectorID = [];

    let societies = this.state.societies;
    let selectedSocieties = [];
    let societyList = [];
    let societyID = "";

    if (e.target.value !== "") {
      inspectorID = e.target.value;
      for (let i = 0; i < societies.length; i++) {
        const society = societies[i];
        if (society.INSPECTOREMAILID === inspectorID) {
          societyList.push(society);
          selectedSocieties.push(society.key);
        }
      }
    } else {
      for (let i = 0; i < societies.length; i++) {
        const society = societies[i];
        societyList.push(society);
        selectedSocieties.push(society.key);
      }
    }
    societyList.unshift({ key: "", SOCIETY: "ALL SOCIETIES" });

    this.setState({
      societyList: societyList,
      selectedSocieties: selectedSocieties,
      societyID: societyID,
      inspectorID: inspectorID,
    });
  }

  onSocietyChange(e) {
    let selectedSocieties = [];
    if (e.target.value !== "") {
      selectedSocieties.push(e.target.value);
    } else {
      for (let i = 0; i < this.state.societies.length; i++) {
        const society = this.state.societies[i];
        selectedSocieties.push(society.key);
      }
    }
    this.setState({
      selectedSocieties: selectedSocieties,
      societyID: e.target.value,
    });
  }

  async onExportTransactions() {
    let result = await this.props.getAllTransactions(
      this.state.selectedSocieties,
      this.state.startDate,
      this.state.endDate,
      ""
    );
    let scrollID = result.scrollID;
    let transactions = result.searchResult;

    for (let index = 1; index < result.totalItemsCount / 2000; index++) {
      result = await this.props.getAllTransactions([], "", "", scrollID);
      result.searchResult.forEach((element) => {
        transactions.push(element);
      });
    }

    let finalTransactions = [];

    for (let i = 0; i < transactions.length; i++) {
      const transaction = transactions[i];

      let obj = {
        Division: transaction.Division,
        District: transaction.District,
        "AR Circle": transaction.AR,
        Village: transaction.Village,
        Society: transaction.Society,
        // "SocietyID": transaction.SocietyID,
        FarmerMobileNo: transaction.FarmerMobileNo,
        FarmerName: transaction.FarmerName,
        FatherName: transaction.FatherName,
        FarmerProfile: transaction.FarmerProfile,
        MachineCode: transaction.MachineCode,
        Machine: transaction.Machine,
        MachineHrs: transaction.MachineHrs,
        ChargesPerHrs: transaction.ChargesPerHrs,
        MachineRental: transaction.MachineRental,
        TractorRequired: transaction.TractorRequired,
        TractorRental: transaction.TractorRental,
        TotalRental: transaction.TotalRental,
        "Transaction Date": moment(
          new Date(transaction.TransactionDate)
        ).format("DD-MMM-yyyy"),
      };

      finalTransactions.push(obj);
    }

    // console.log("finalTransactions", JSON.stringify(finalTransactions));

    const csvData = this.objToCSV(finalTransactions);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Transactions.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  renderData() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div
              className="my-3 p-3 bg-white rounded shadow-sm"
              style={{ minHeight: "180px", fontSize: "14px" }}
            >
              <h3 className="border-bottom border-gray pb-2 mb-0">
                {" "}
                <span className="fa-stack fa-md">
                  <i className="fa fa-circle-thin fa-stack-2x"></i>
                  <i className="fa fa-list fa-stack-1x"></i>
                </span>{" "}
                {this.props.selectedAR && this.props.selectedAR !== ""
                  ? "Cooperative Machine Performance Report / " +
                    this.props.selectedAR
                  : "Cooperative Machine Performance Report"}
                {this.props.selectedAR && this.props.selectedAR !== "" && (
                  <button
                    type="button"
                    className="btn btn-primary pull-right"
                    onClick={() => this.props.onBackClick()}
                  >
                    Back
                  </button>
                )}
              </h3>
              <br />
              <div className="row">
                {this.props.common.user &&
                  (this.props.common.user.Role === "AR" ||
                    (this.props.selectedAR &&
                      this.props.selectedAR !== "")) && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          <b>Select Inspector</b>
                        </label>
                        <select
                          className="form-control"
                          value={this.state.inspectorID}
                          onChange={this.onInspectorChange}
                        >
                          {this.state.inspectors.map((inspector, index) => (
                            <option key={index} value={inspector.emailID}>
                              {inspector.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                {this.props.common.user &&
                  (this.props.common.user.Role === "Inspector" ||
                    this.props.common.user.Role === "AR" ||
                    (this.props.selectedAR &&
                      this.props.selectedAR !== "")) && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          <b>Select Society</b>
                        </label>
                        <select
                          className="form-control"
                          value={this.state.societyID}
                          onChange={this.onSocietyChange}
                        >
                          {this.state.societyList.map((society, index) => (
                            <option key={index} value={society.key}>
                              {society.SOCIETY}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      <b>Select Year</b>
                    </label>
                    <select
                      className="form-control"
                      value={this.state.selectedYearIndex}
                      onChange={(evt) => {
                        let selectedYearIndex = evt.target.value;
                        let startDate = new Date(
                          this.state.years[Number(selectedYearIndex)].StartDate
                        )
                          .toISOString()
                          .split("T")[0];
                        let endDate = new Date(
                          this.state.years[Number(selectedYearIndex)].EndDate
                        )
                          .toISOString()
                          .split("T")[0];
                        this.setState({
                          startDate: startDate,
                          endDate: endDate,
                          selectedYearIndex: selectedYearIndex,
                        });
                      }}
                    >
                      {this.state.years.map((y, index) => (
                        <option value={index}>
                          {y.DisplayStartDate} - {y.DisplayEndDate}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      <b>Start Date</b>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(evt) =>
                        this.setState({ startDate: evt.target.value })
                      }
                      value={this.state.startDate}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      <b>End Date</b>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(evt) =>
                        this.setState({ endDate: evt.target.value })
                      }
                      value={this.state.endDate}
                    />
                  </div>
                </div>
                {this.props.common.user &&
                  (this.props.common.user.Role === "Secretary" ||
                    this.props.common.user.Role === "Inspector") && (
                    <div className="col-md-3" style={{ marginTop: "28px" }}>
                      <button
                        className="btn btn-default"
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={this.onSearchClick}
                      >
                        {" "}
                        Search
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-warning"
                        style={{ color: "white" }}
                        onClick={this.onExportClick}
                      >
                        {" "}
                        Export
                      </button>
                    </div>
                  )}
              </div>
              {this.props.common.user &&
                (this.props.common.user.Role === "AR" ||
                  (this.props.selectedAR && this.props.selectedAR !== "")) && (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-warning pull-right"
                        onClick={this.onExportClick}
                        style={{ color: "white" }}
                      >
                        {" "}
                        Export
                      </button>
                      <button
                        className="btn btn-primary pull-right"
                        onClick={this.onSearchClick}
                        style={{ marginRight: "5px" }}
                      >
                        {" "}
                        Search
                      </button>
                      <button
                        className="btn btn-default pull-right"
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                )}
              {/* <div className="row">
                                <div className="col-md-8">
                                    <h5 style={{ textAlign: "center" }} >Total Utilization</h5>
                                    <div className="chart" style={{ height: "40vh" }}>
                                        <Bar
                                            width={500}
                                            height={400}
                                            data={this.state.machinesUtilization}
                                            options={{
                                                legend: {
                                                    display: true,
                                                },
                                                maintainAspectRatio: false, 
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true
                                                    }],
                                                    yAxes: [{
                                                        stacked: true
                                                    }]
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h5 style={{ textAlign: "center" }} >Avg. Revenue(for Chargeable Hrs)</h5>
                                    <div className="chart" style={{ height: "40vh" }}>
                                        <Pie
                                            width={150}
                                            height={400}
                                            data={this.state.machinesRevenue}
                                            options={{
                                                legend: {
                                                    display: true,
                                                    position: "right"
                                                },
                                                maintainAspectRatio: false
                                            }}
                                        />
                                    </div>
                                </div>
                            </div> */}
              {this.state.loading && <Loader show={this.state.loading} />}
              <div
                className="col-md-12"
                style={{ padding: "0px", marginTop: "5px" }}
              >
                <button
                  className="btn btn-primary pull-left"
                  onClick={() =>
                    this.setState({ viewMore: !this.state.viewMore })
                  }
                >
                  {" "}
                  {this.state.viewMore ? "Show Less" : "View More"}{" "}
                </button>
                &nbsp;
                <button
                  className="btn btn-warning"
                  style={{ color: "white" }}
                  onClick={this.onExportTransactions}
                >
                  {" "}
                  Export Transactions
                </button>
              </div>
              {!this.state.viewMore && !this.state.loading && (
                <div style={{ marginTop: "5px", overflow: "auto" }}>
                  {/* style={{ width: "auto", overflowX: "auto", height: "420px" }} className="scroll2" */}
                  <table
                    className="table table-striped table-bordered"
                    style={{ fontSize: "14px" }}
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{
                            verticalAlign: "top",
                            textAlign: "center",
                            width: "15%",
                          }}
                        >
                          Machines
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          No. of Machines
                        </th>
                        <th
                          scope="col"
                          colSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Utilization
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Total Utilization
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Avg. Utilization
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Total Revenue
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Avg. Revenue
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            color: "#fff",
                            backgroundColor: "#343a40",
                            borderColor: "#454d55",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Chargeable Hrs
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "#fff",
                            backgroundColor: "#343a40",
                            borderColor: "#454d55",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Free Hrs
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.machineData.map((t, index) => (
                        <tr key={index}>
                          <td>{t["Machine"]} </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Total Machines"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Chargeable Hrs"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Free Hrs"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Total Hrs"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Avg Utilization Hrs"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Total Revenue"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Avg Utilization Revenue"]}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td style={{ fontWeight: "bold" }}>TOTALS</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.machineDataTotals["Total Machines"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.machineDataTotals["Chargeable Hrs"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.machineDataTotals["Free Hrs"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.machineDataTotals["Total Hrs"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.machineDataTotals["Avg Utilization Hrs"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.machineDataTotals["Total Revenue"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {
                            this.state.machineDataTotals[
                              "Avg Utilization Revenue"
                            ]
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {this.state.viewMore && !this.state.loading && (
                <div style={{ marginTop: "5px", overflow: "auto" }}>
                  {/*  */}
                  <table
                    className="table table-striped table-bordered"
                    style={{ fontSize: "14px" }}
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{
                            verticalAlign: "top",
                            textAlign: "center",
                            width: "15%",
                          }}
                        >
                          Machines
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          No. of Machines
                        </th>
                        <th
                          scope="col"
                          colSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Utilization
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Total Utilization
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Avg. Utilization
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Total Revenue
                        </th>
                        <th
                          scope="col"
                          rowSpan="2"
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          Avg. Revenue
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            color: "#fff",
                            backgroundColor: "#343a40",
                            borderColor: "#454d55",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Chargeable Hrs
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "#fff",
                            backgroundColor: "#343a40",
                            borderColor: "#454d55",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Free Hrs
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.fullMachineData.map((t, index) => (
                        <tr key={index}>
                          <td>{t["Machine"]} </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Total Machines"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Chargeable Hrs"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Free Hrs"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Total Hrs"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Avg Utilization Hrs"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Total Revenue"]}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {t["Avg Utilization Revenue"]}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td style={{ fontWeight: "bold" }}>TOTALS</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.fullMachineDataTotals["Total Machines"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.fullMachineDataTotals["Chargeable Hrs"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.fullMachineDataTotals["Free Hrs"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.fullMachineDataTotals["Total Hrs"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {
                            this.state.fullMachineDataTotals[
                              "Avg Utilization Hrs"
                            ]
                          }
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {this.state.fullMachineDataTotals["Total Revenue"]}
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {
                            this.state.fullMachineDataTotals[
                              "Avg Utilization Revenue"
                            ]
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* {this.props.showHeader &&  */}
        <React.Fragment>
          <Header menuSelected="reports" {...this.props} />
          <main className="container">
            {/* <br /> */}
            {this.renderData()}
          </main>
        </React.Fragment>
        {/* } */}

        {/* {!this.props.showHeader && this.renderData()} */}
      </React.Fragment>
    );
  }
}

export default Reports;
