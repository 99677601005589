import React, { Component } from 'react';
import { auth } from '../firebase'
import '../css/App.css';
import Header from './Header'

class ChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            employee: {},
            employeePassword: '',
            employeeConfirmPassword: '',
            employeePasswordError: '',
            employeeConfirmPasswordError: ''
        }
        this.onChangePassword = this.onChangePassword.bind(this)
    }

    async onChangePassword() {
        let employeePasswordError = ""
        let employeeConfirmPasswordError = ""

        if (this.state.employeePassword.trim() === '') { employeePasswordError = "Please provide password" }
        if (this.state.employeeConfirmPassword.trim() === '') { employeeConfirmPasswordError = "Please provide password" }
        if (this.state.employeePassword.length < 6) { employeePasswordError = "Password should be at least 6 characters in length" }
        if (this.state.employeePassword.trim() !== this.state.employeeConfirmPassword.trim()) { employeeConfirmPasswordError = "Password and confirm password do not match" }

        if (employeePasswordError.trim() !== "" || employeeConfirmPasswordError.trim() !== "") {
            this.setState({
                employeePasswordError: employeePasswordError,
                employeeConfirmPasswordError: employeeConfirmPasswordError
            })
            return;
        }
        var user = auth.currentUser;
        await user.updatePassword(this.state.employeePassword);
        // Update successful.
        alert("Password Changed Successfully!!!");
        this.props.signOutUser();
        this.props.onHistory.push('/')
    }

    onCancelClick() {
        this.props.onClose();
    }

    render() {
        return <div>
            <Header menuSelected="changePassword" {...this.props} />

            <main className="container">
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ minHeight: "180px", fontSize: "14px" }}>
                            <h3 className="border-bottom border-gray pb-2 mb-0"> <span className="fa-stack fa-md">
                                <i className="fa fa-circle-thin fa-stack-2x"></i>
                                <i className="fa fa-edit fa-stack-1x"></i>
                            </span> Change Password
                            </h3>
                            <br />
                            <div className={this.state.employeePasswordError.trim() !== "" ? "mb-3 col-md-6 has-error" : "mb-3 col-md-6"}>
                                <label>Password</label>
                                <input type="password" className="form-control"
                                    value={this.state.employeePassword}
                                    onChange={(evt) => this.setState({ employeePassword: evt.target.value, employeePasswordError: '' })} />
                                {this.state.employeePasswordError.trim() !== "" && <span className="help-block">{this.state.employeePasswordError}</span>}
                            </div>
                            <div className={this.state.employeeConfirmPasswordError.trim() !== "" ? "mb-3 col-md-6 has-error" : "mb-3 col-md-6"}>
                                <label>Confirm Password</label>
                                <input type="password" className="form-control"
                                    value={this.state.employeeConfirmPassword}
                                    onChange={(evt) => this.setState({ employeeConfirmPassword: evt.target.value, employeeConfirmPasswordError: '' })} />
                                {this.state.employeeConfirmPasswordError.trim() !== "" && <span className="help-block">{this.state.employeeConfirmPasswordError}</span>}
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-default" onClick={() => this.props.onHistory.push('/home')}>Cancel</button>
                                <button className="btn btn-primary pull-right" onClick={this.onChangePassword} disabled={this.state.saving} >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}
export default ChangePassword