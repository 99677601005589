import * as constants from '../Constants'
import firebase, { database } from '../firebase'

export  const  isMobile = ()=> {
  if ( window.screen.width <= 480) {
        return true
    }
    else {
        return false
    }
}

export function login(loginID, password) {
  return dispatch => {
    return database.collection("Users").where("EmailID", "==", loginID).where("PhoneNo", "==", password).limit(1).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const user = doc.data()
        dispatch({ type: constants.SET_USER, user })
      })
    }).catch((error) => {
      console.error("User not found!!!");

    })
  }
}

export function setAuthUser(auth) {
  return dispatch => {
    dispatch({ type: constants.SET_AUTH, auth })
  }
}

export function signOutUser() {
  return dispatch => {
    dispatch({ type: constants.SIGN_OUT })
  }
}

export function getUserDetails(authenticationID) {
  return (dispatch) => {

    return database.collection("Users").where("AuthenticationID", "==", authenticationID).where("IsDeleted", "==", false).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const user = doc.data()
        dispatch({ type: constants.SET_USER, user })
      })
    }).catch((error) => {
      console.error("User not found!!!");

    })
  }
}

export function getFarmerDetails(mobileno){
  let searchParameters = {
    "size": 1, 
    "query": {
      "term": {
        "FarmerMobileNo": {
          "value": mobileno
        }
      }
    }
  }

  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let farmerDetails = {};
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((doc, index) => {
        farmerDetails.FarmerName = doc._source.FarmerName;
        farmerDetails.FatherName = doc._source.FatherName;
        farmerDetails.FarmerProfile = doc._source.FarmerProfile;
        farmerDetails.Village = doc._source.Village;
      })
      return farmerDetails;
    });
  }
}

export function getSocietyDetailsByEmailID(emailID) {
  return (dispatch) => {
    return database.collection("Societies").where("SECRETARYEMAILID", "==", emailID).get().then((querySnapshot) => {
      let societies = [];
      querySnapshot.forEach((doc) => {
        let society = doc.data()
        society.key = doc.id
        societies.push(society);
      })
      return societies;
    }).catch((error) => {
      console.error(error)
    })
  }
}

export function checkUserExists(emailID) {
  return (dispatch) => {
    return database.collection("Users").where("EmailID", "==", emailID).where("IsDeleted", "==", false).limit(1).get().then((querySnapshot) => {
      let userExists = false;
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          userExists = true;
        }
      })
      return userExists;
    });
  }
};

export function setSociety(society) {
  return dispatch => {
    dispatch({ type: constants.SET_SOCIETY, society })
  }
}

export function saveTransaction(transaction, key) {
  return (dispatch, getState) => {
    if (key !== "") {
      transaction.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      transaction.ActionBy = getState().common.user.Name
      transaction.ActionByEmailID = getState().common.user.EmailID
      transaction.Action = constants.AUDIT_TRAIL_EDIT
      return database.collection("TransactionData").doc(key).update({
        ...transaction
      }).then(() => {
        return key
      }).catch(function (error) {
        console.error("Error updating transaction: ", error);
      })
    }
    else {
      transaction.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      transaction.ActionBy = getState().common.user.Name
      transaction.ActionByEmailID = getState().common.user.EmailID
      transaction.SocietyID = getState().common.society.key
      transaction.Society = getState().common.society.SOCIETY
      transaction.Action = constants.AUDIT_TRAIL_NEW

      return database.collection("TransactionData").add({
        ...transaction
      })
        .then(function (docRef) {
          return docRef.id
        })
        .catch(function (error) {
          console.error("Error adding transaction: ", error);
        })
    }
  }
}

export function getTransactionList(societyID) {
  return (dispatch) => {
    return database.collection("TransactionData").where("SocietyID", "==", societyID).orderBy("ActionOn", "desc").get().then((querySnapshot) => {
      const transactionList = []
      querySnapshot.forEach((doc) => {
        transactionList.push(
          {
            key: doc.id, ...doc.data()
          }
        )
      })
      return transactionList
    }).catch((error) => {
      console.error(error)
    })
  }
}

export function getSocietyList() {
  return (dispatch) => {
    return database.collection("Societies").get().then((querySnapshot) => {
      const societyList = []
      querySnapshot.forEach((doc) => {
        societyList.push(
          {
            key: doc.id, ...doc.data()
          }
        )
      })
      return societyList
    }).catch((error) => {
      console.error(error)
    })
  }
}

export function getUserList() {
  return (dispatch) => {
    return database.collection("Users").get().then((querySnapshot) => {
      const userList = []
      querySnapshot.forEach((doc) => {
        userList.push(
          {
            key: doc.id, ...doc.data()
          }
        )
      })
      return userList
    }).catch((error) => {
      console.error(error)
    })
  }
}

export function saveSociety(society) {
  return (dispatch, getState) => {

    return database.collection("Societies").add({
      ...society
    })
      .then(function (docRef) {
        society.id = docRef.id
        return society
      })
      .catch(function (error) {
        console.error("Error adding society: ", error);
      })
  }
}

export function updateSociety(society, key) {
  return (dispatch, getState) => {
    return database.collection("Societies").doc(key).update(society).then(() => {
      return key
    }).catch(function (error) {
      console.error("Error updating loyalty type: ", error);
    })
  }
}

export function getAllTransactionData() {
  return (dispatch) => {
    return database.collection("TransactionData").where("SocietyID","==","KQhkUO2Qv5uMTaaaJFWB").get().then((querySnapshot) => {
      const transactionList = []
      querySnapshot.forEach((doc) => {
        transactionList.push(
          {
            key: doc.id  
            // , ...doc.data()
          }
        )
      })
      return transactionList
    }).catch((error) => {
      console.error(error)
    })
  }
}

export function getAllSocietiesThatHaveTransaction(fromDate, toDate){

  let searchParameters={
    "query": {
      "range": {
        "TransactionDate": {
          "gte": fromDate,
          "lte": toDate,
          "format": "yyyy-MM-dd"
        }
      }
    }, 
    "aggs": {
      "societies": {
        "terms": {
          "field": "SocietyID.keyword", "size": 10000
        }
      }
    }
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let societies = []
      sanitizedMessage.aggregations.societies.buckets.map((data, index) => {
        let society = {
          societyID: data.key,
        }
        societies.push(society);
      })
      return societies;
    });
  }
}

export function getMachineWiseSummary(societies, startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "SocietyID.keyword": societies
            }
          },
          {
            "range": {
              "MachineRental": {
                "gt": 0
              }
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "machines": {
        "terms": {
          "field": "MachineCode.keyword",
          "size": 100
        },
        "aggs": {
          "machine_hrs": {
            "sum": {
              "field": "MachineHrs"
            }
          },
          "machine_revenue": {
            "sum": {
              "field": "MachineRental"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let machineTypes = []
      sanitizedMessage.aggregations.machines.buckets.map((data, index) => {
        let machineType = {
          machineCode: data.key,
          machine_revenue: Number(data.machine_revenue.value).toFixed(2),
          machine_hrs: Number(data.machine_hrs.value).toFixed(2),
        }
        machineTypes.push(machineType);
      })
      return machineTypes;
    });
  }
}

export function getMachineWiseFreeHrsSummary(societies, startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "SocietyID.keyword": societies
            }
          },
          {
            "term": {
              "MachineRental": 0
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "machines": {
        "terms": {
          "field": "MachineCode.keyword",
          "size": 100
        },
        "aggs": {
          "machine_hrs": {
            "sum": {
              "field": "MachineHrs"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let machineTypes = []
      sanitizedMessage.aggregations.machines.buckets.map((data, index) => {
        let machineType = {
          machineCode: data.key,
          machine_hrs: Number(data.machine_hrs.value).toFixed(2),
        }
        machineTypes.push(machineType);
      })
      return machineTypes;
    });
  }
}

export function getTractorSummary(societies, startDate, endDate) {
  let searchParameters = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "SocietyID.keyword": societies
            }
          },
          {
            "match": {
              "TractorRequired": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ]
      }
    },
    "aggs": {
      "tractor_revenue": {
        "sum": {
          "field": "TractorRental"
        }
      }
    }
  }
  return (dispatch) => {
    // console.log(JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let tractor = {
        tractor_revenue: Number(sanitizedMessage.aggregations.tractor_revenue.value).toFixed(2)
      }
      return tractor;
    });
  }
}

export function getSocietyDetailsByInspectorEmailID(emailID) {
  return (dispatch) => {
    return database.collection("Societies").where("INSPECTOREMAILID", "==", emailID).orderBy("SOCIETY", "asc").get().then((querySnapshot) => {
      let societies = [];
      querySnapshot.forEach((doc) => {
        let society = doc.data()
        society.key = doc.id
        societies.push(society);
      })
      return societies;
    }).catch((error) => {
      console.error(error)
    })
  }
}

export function getDivisionWiseMachineCountForRCS(divisions) {
  let searchParameters = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "DIVISION.keyword": divisions
            }
          }
        ]
      }
    },
    "aggs": {
      "divisions": {
        "terms": {
          "field": "DIVISION.keyword",
          "size": 10
        },
        "aggs": {
          "HAPPYSEEDER": {
            "sum": {
              "field": "HAPPYSEEDER"
            }
          },
          "SUPERSEEDER": {
            "sum": {
              "field": "SUPERSEEDER"
            }
          },
          "SURFACESEEDER": {
            "sum": {
              "field": "SURFACESEEDER"
            }
          },
          "RMBPLOUGH": {
            "sum": {
              "field": "RMBPLOUGH"
            }
          },
          "ROTAVATOR": {
            "sum": {
              "field": "ROTAVATOR"
            }
          },
          "ZEROTILLDRILL": {
            "sum": {
              "field": "ZEROTILLDRILL"
            }
          },
          "CHOPPER": {
            "sum": {
              "field": "PADDYSTRAWCHOPPER"
            }
          },
          "MULCHER": {
            "sum": {
              "field": "MULCHER"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    console.log("getDivisionWiseMachineCountForRCS", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSSocieties');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let divisions = []
      sanitizedMessage.aggregations.divisions.buckets.map((data, index) => {
        
        let division = {
          name: data.key,
          "HAPPY SEEDER": 0,
          "SUPER SEEDER": 0,
          "SURFACE SEEDER": 0,
          "RMB PLOUGH": 0,
          ROTAVATOR: 0,
          "ZERO TILL DRILL": 0,
          CHOPPER: 0,
          MULCHER: 0
        }

        division["HAPPY SEEDER"] = Number(division["HAPPY SEEDER"]) + Number(data.HAPPYSEEDER.value)
        division["SUPER SEEDER"] = Number(division["SUPER SEEDER"]) + Number(data.SUPERSEEDER.value)
        division["SURFACE SEEDER"] = Number(division["SURFACE SEEDER"]) + Number(data.SURFACESEEDER.value)
        division["RMB PLOUGH"] = Number(division["RMB PLOUGH"]) + Number(data.RMBPLOUGH.value)
        division["ROTAVATOR"] = Number(division["ROTAVATOR"]) + Number(data.ROTAVATOR.value)
        division["ZERO TILL DRILL"] = Number(division["ZERO TILL DRILL"]) + Number(data.ZEROTILLDRILL.value)
        division["CHOPPER"] = Number(division["CHOPPER"]) + Number(data.CHOPPER.value)
        division["MULCHER"] = Number(division["MULCHER"]) + Number(data.MULCHER.value)
       

        divisions.push(division);
      })
      return divisions;
    });
  }
}

export function getDivisionWiseSummaryForRCS(divisions, startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "Division.keyword": divisions
            }
          },
          {
            "range": {
              "MachineRental": {
                "gt": 0
              }
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "terms": {
              "MachineCode.keyword": [
                "HAPPYSEEDER",
                "SUPERSEEDER",
                "SURFACESEEDER",
                "RMBPLOUGH",
                "ROTAVATOR",
                "ZEROTILLDRILL",
                "PADDYSTRAWCHOPPER",
                "MULCHER"
              ]
            }
          }
        ]
      }
    },
    "size": 0,
     "aggs": {
        "divisions": {
          "composite": {
            "size": 100,
            "sources": [
              {
                "division": {
                  "terms": {
                    "field": "Division.keyword"
                    
                  }
                }
              },
              {
                "machine": {
                  "terms": {
                    "field": "Machine.keyword"
                  }
                }
              }
            ]
          },
          "aggs": {
            "total_hrs": {
              "sum": {
                "field": "MachineHrs"
              }
            }
          }
        }
      }
  }
  return (dispatch) => {
    console.log("getDivisionWiseSummaryForRCS", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let divisions = []
      sanitizedMessage.aggregations.divisions.buckets.map((data, index) => {

        if (divisions.filter((f) => f.name === data.key.division.toUpperCase()).length === 0)
        {

          let division = {
            name: data.key.division.toUpperCase(),
            "HAPPY SEEDER": 0,
            "SUPER SEEDER": 0,
            "SURFACE SEEDER": 0,
            "RMB PLOUGH": 0,
            ROTAVATOR: 0,
            "ZERO TILL DRILL": 0,
            CHOPPER: 0,
            MULCHER: 0
          }
  
          if(data.key.machine === "HAPPY SEEDER")
          {
            division["HAPPY SEEDER"] = Number(Number(division["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
          
          if(data.key.machine === "SUPER SEEDER")
          {
            division["SUPER SEEDER"] = Number(Number(division["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "SURFACE SEEDER")
          {
            division["SURFACE SEEDER"] = Number(Number(division["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "RMB PLOUGH")
          {
            division["RMB PLOUGH"] = Number(Number(division["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ROTAVATOR")
          {
            division["ROTAVATOR"] = Number(Number(division["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ZERO TILL DRILL")
          {
            division["ZERO TILL DRILL"] = Number(Number(division["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "PADDY STRAW CHOPPER")
          {
            division["CHOPPER"] = Number(Number(division["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "MULCHER")
          {
            division["MULCHER"] = Number(Number(division["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
          }

          divisions.push(division);
        }
        else {
          divisions.filter((f) => f.name === data.key.division.toUpperCase()).map(division => {
            if (data.key.machine === "HAPPY SEEDER") {
              division["HAPPY SEEDER"] = Number(Number(division["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SUPER SEEDER") {
              division["SUPER SEEDER"] = Number(Number(division["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SURFACE SEEDER") {
              division["SURFACE SEEDER"] = Number(Number(division["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "RMB PLOUGH") {
              division["RMB PLOUGH"] = Number(Number(division["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ROTAVATOR") {
              division["ROTAVATOR"] = Number(Number(division["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ZERO TILL DRILL") {
              division["ZERO TILL DRILL"] = Number(Number(division["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "PADDY STRAW CHOPPER") {
              division["CHOPPER"] = Number(Number(division["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "MULCHER") {
              division["MULCHER"] = Number(Number(division["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
            }
          })
        }
      })
      return divisions;
    });
  }
}

export function getDivisionWiseFreeSummaryForRCS(divisions, startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "Division.keyword": divisions
            }
          },
          {
            "term": {
              "MachineRental": 0
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "terms": {
              "MachineCode.keyword": [
                "HAPPYSEEDER",
                "SUPERSEEDER",
                "SURFACESEEDER",
                "RMBPLOUGH",
                "ROTAVATOR",
                "ZEROTILLDRILL",
                "PADDYSTRAWCHOPPER",
                "MULCHER"
              ]
            }
          }
        ]
      }
    },
    "size": 0,
     "aggs": {
        "divisions": {
          "composite": {
            "size": 100,
            "sources": [
              {
                "division": {
                  "terms": {
                    "field": "Division.keyword"
                  }
                }
              },
              {
                "machine": {
                  "terms": {
                    "field": "Machine.keyword"
                  }
                }
              }
            ]
          },
          "aggs": {
            "total_hrs": {
              "sum": {
                "field": "MachineHrs"
              }
            }
          }
        }
      }
  }
  return (dispatch) => {
    // console.log("getDivisionWiseSummaryForRCS", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let divisions = []
      sanitizedMessage.aggregations.divisions.buckets.map((data, index) => {

        if (divisions.filter((f) => f.name === data.key.division.toUpperCase()).length === 0)
        {

          let division = {
            name: data.key.division.toUpperCase(),
            "HAPPY SEEDER": 0,
            "SUPER SEEDER": 0,
            "SURFACE SEEDER": 0,
            "RMB PLOUGH": 0,
            ROTAVATOR: 0,
            "ZERO TILL DRILL": 0,
            CHOPPER: 0,
            MULCHER: 0
          }
  
          if(data.key.machine === "HAPPY SEEDER")
          {
            division["HAPPY SEEDER"] = Number(Number(division["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
          
          if(data.key.machine === "SUPER SEEDER")
          {
            division["SUPER SEEDER"] = Number(Number(division["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "SURFACE SEEDER")
          {
            division["SURFACE SEEDER"] = Number(Number(division["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "RMB PLOUGH")
          {
            division["RMB PLOUGH"] = Number(Number(division["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ROTAVATOR")
          {
            division["ROTAVATOR"] = Number(Number(division["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ZERO TILL DRILL")
          {
            division["ZERO TILL DRILL"] = Number(Number(division["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "PADDY STRAW CHOPPER")
          {
            division["CHOPPER"] = Number(Number(division["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "MULCHER")
          {
            division["MULCHER"] = Number(Number(division["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
          }

          divisions.push(division);
        }
        else {
          divisions.filter((f) => f.name === data.key.division.toUpperCase()).map(division => {
            if (data.key.machine === "HAPPY SEEDER") {
              division["HAPPY SEEDER"] = Number(Number(division["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SUPER SEEDER") {
              division["SUPER SEEDER"] = Number(Number(division["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SURFACE SEEDER") {
              division["SURFACE SEEDER"] = Number(Number(division["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "RMB PLOUGH") {
              division["RMB PLOUGH"] = Number(Number(division["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ROTAVATOR") {
              division["ROTAVATOR"] = Number(Number(division["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ZERO TILL DRILL") {
              division["ZERO TILL DRILL"] = Number(Number(division["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "PADDY STRAW CHOPPER") {
              division["CHOPPER"] = Number(Number(division["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "MULCHER") {
              division["MULCHER"] = Number(Number(division["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
            }
          })
        }
      })
      return divisions;
    });
  }
}

export function getDistrictWiseMachineCountForRCS(division) {
  let searchParameters = {
    "query": {
      "terms": {
        "DIVISION.keyword": [
          division
        ]
      }
    },
    "size": 0,
    "aggs": {
      "districts": {
        "terms": {
          "field": "DISTRICT.keyword",
          "size": 3000
        },
        "aggs": {
          "HAPPYSEEDER": {
            "sum": {
              "field": "HAPPYSEEDER"
            }
          },
          "SUPERSEEDER": {
            "sum": {
              "field": "SUPERSEEDER"
            }
          },
          "SURFACESEEDER": {
            "sum": {
              "field": "SURFACESEEDER"
            }
          },
          "RMBPLOUGH": {
            "sum": {
              "field": "RMBPLOUGH"
            }
          },
          "ROTAVATOR": {
            "sum": {
              "field": "ROTAVATOR"
            }
          },
          "ZEROTILLDRILL": {
            "sum": {
              "field": "ZEROTILLDRILL"
            }
          },
          "CHOPPER": {
            "sum": {
              "field": "PADDYSTRAWCHOPPER"
            }
          },
          "MULCHER": {
            "sum": {
              "field": "MULCHER"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    // console.log("getDistrictWiseMachineCountForRCS",  JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSSocieties');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let districts = []
      sanitizedMessage.aggregations.districts.buckets.map((data, index) => {

        let district = {
          name: data.key,
          "HAPPY SEEDER": 0,
          "SUPER SEEDER": 0,
          "SURFACE SEEDER": 0,
          "RMB PLOUGH": 0,
          ROTAVATOR: 0,
          "ZERO TILL DRILL": 0,
          CHOPPER: 0,
          MULCHER: 0
        }
        district["HAPPY SEEDER"] = Number(district["HAPPY SEEDER"]) + Number(data.HAPPYSEEDER.value)
        district["SUPER SEEDER"] = Number(district["SUPER SEEDER"]) + Number(data.SUPERSEEDER.value)
        district["SURFACE SEEDER"] = Number(district["SURFACE SEEDER"]) + Number(data.SURFACESEEDER.value)
        district["RMB PLOUGH"] = Number(district["RMB PLOUGH"]) + Number(data.RMBPLOUGH.value)
        district["ROTAVATOR"] = Number(district["ROTAVATOR"]) + Number(data.ROTAVATOR.value)
        district["ZERO TILL DRILL"] = Number(district["ZERO TILL DRILL"]) + Number(data.ZEROTILLDRILL.value)
        district["CHOPPER"] = Number(district["CHOPPER"]) + Number(data.CHOPPER.value)
        district["MULCHER"] = Number(district["MULCHER"]) + Number(data.MULCHER.value)
        districts.push(district);
      })
      return districts;
    });
  }
}

export function getDistrictWiseSummaryForRCS(districts, startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "District.keyword": districts
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "range": {
              "MachineRental": {
                "gt": 0
              }
            }
          },
          {
            "terms": {
              "MachineCode.keyword": [
                "HAPPYSEEDER",
                "SUPERSEEDER",
                "SURFACESEEDER",
                "RMBPLOUGH",
                "ROTAVATOR",
                "ZEROTILLDRILL",
                "PADDYSTRAWCHOPPER",
                "MULCHER"
              ]
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "districts": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "district": {
                "terms": {
                  "field": "District.keyword"

                }
              }
            },
            {
              "machine": {
                "terms": {
                  "field": "Machine.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "total_hrs": {
            "sum": {
              "field": "MachineHrs"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    // console.log("getDistrictWiseSummaryForRCS",  JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let districts = [];

      sanitizedMessage.aggregations.districts.buckets.map((data, index) => {
        if (districts.filter((f) => f.name === data.key.district.toUpperCase()).length === 0)
        {
          let district = {
            name: data.key.district.toUpperCase(),
            "HAPPY SEEDER": 0,
            "SUPER SEEDER": 0,
            "SURFACE SEEDER": 0,
            "RMB PLOUGH": 0,
            ROTAVATOR: 0,
            "ZERO TILL DRILL": 0,
            CHOPPER: 0,
            MULCHER: 0
          }
  
          if(data.key.machine === "HAPPY SEEDER")
          {
            district["HAPPY SEEDER"] = Number(Number(district["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
          
          if(data.key.machine === "SUPER SEEDER")
          {
            district["SUPER SEEDER"] = Number(Number(district["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "SURFACE SEEDER")
          {
            district["SURFACE SEEDER"] = Number(Number(district["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "RMB PLOUGH")
          {
            district["RMB PLOUGH"] = Number(Number(district["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ROTAVATOR")
          {
            district["ROTAVATOR"] = Number(Number(district["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ZERO TILL DRILL")
          {
            district["ZERO TILL DRILL"] = Number(Number(district["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "PADDY STRAW CHOPPER")
          {
            district["CHOPPER"] = Number(Number(district["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "MULCHER")
          {
            district["MULCHER"] = Number(Number(district["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
          }

          districts.push(district);
        }
        else {
          districts.filter((f) => f.name === data.key.district.toUpperCase()).map(district => {
            if (data.key.machine === "HAPPY SEEDER") {
              district["HAPPY SEEDER"] = Number(Number(district["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SUPER SEEDER") {
              district["SUPER SEEDER"] = Number(Number(district["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SURFACE SEEDER") {
              district["SURFACE SEEDER"] = Number(Number(district["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "RMB PLOUGH") {
              district["RMB PLOUGH"] = Number(Number(district["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ROTAVATOR") {
              district["ROTAVATOR"] = Number(Number(district["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ZERO TILL DRILL") {
              district["ZERO TILL DRILL"] = Number(Number(district["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "PADDY STRAW CHOPPER") {
              district["CHOPPER"] = Number(Number(district["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "MULCHER") {
              district["MULCHER"] = Number(Number(district["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
            }
          })
        }
      })
      return districts;
    });
  }
}

export function getDistrictWiseFreeSummaryForRCS(districts, startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "District.keyword": districts
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "term": {
              "MachineRental": 0
            }
          },
          {
            "terms": {
              "MachineCode.keyword": [
                "HAPPYSEEDER"
                ,"SUPERSEEDER",
                "SURFACESEEDER",
                "RMBPLOUGH",
                "ROTAVATOR",
                "ZEROTILLDRILL",
                "PADDYSTRAWCHOPPER",
                "MULCHER"
              ]
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "districts": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "district": {
                "terms": {
                  "field": "District.keyword"

                }
              }
            },
            {
              "machine": {
                "terms": {
                  "field": "Machine.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "total_hrs": {
            "sum": {
              "field": "MachineHrs"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    // console.log("getDistrictWiseSummaryForRCS",  JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let districts = [];

      sanitizedMessage.aggregations.districts.buckets.map((data, index) => {
        if (districts.filter((f) => f.name === data.key.district.toUpperCase()).length === 0)
        {
          let district = {
            name: data.key.district.toUpperCase(),
            "HAPPY SEEDER": 0,
            "SUPER SEEDER": 0,
            "SURFACE SEEDER": 0,
            "RMB PLOUGH": 0,
            ROTAVATOR: 0,
            "ZERO TILL DRILL": 0,
            CHOPPER: 0,
            MULCHER: 0
          }
  
          if(data.key.machine === "HAPPY SEEDER")
          {
            district["HAPPY SEEDER"] = Number(Number(district["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
          
          if(data.key.machine === "SUPER SEEDER")
          {
            district["SUPER SEEDER"] = Number(Number(district["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "SURFACE SEEDER")
          {
            district["SURFACE SEEDER"] = Number(Number(district["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "RMB PLOUGH")
          {
            district["RMB PLOUGH"] = Number(Number(district["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ROTAVATOR")
          {
            district["ROTAVATOR"] = Number(Number(district["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ZERO TILL DRILL")
          {
            district["ZERO TILL DRILL"] = Number(Number(district["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "PADDY STRAW CHOPPER")
          {
            district["CHOPPER"] = Number(Number(district["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "MULCHER")
          {
            district["MULCHER"] = Number(Number(district["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
          }

          districts.push(district);
        }
        else {
          districts.filter((f) => f.name === data.key.district.toUpperCase()).map(district => {
            if (data.key.machine === "HAPPY SEEDER") {
              district["HAPPY SEEDER"] = Number(Number(district["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SUPER SEEDER") {
              district["SUPER SEEDER"] = Number(Number(district["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SURFACE SEEDER") {
              district["SURFACE SEEDER"] = Number(Number(district["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "RMB PLOUGH") {
              district["RMB PLOUGH"] = Number(Number(district["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ROTAVATOR") {
              district["ROTAVATOR"] = Number(Number(district["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ZERO TILL DRILL") {
              district["ZERO TILL DRILL"] = Number(Number(district["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "PADDY STRAW CHOPPER") {
              district["CHOPPER"] = Number(Number(district["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "MULCHER") {
              district["MULCHER"] = Number(Number(district["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
            }
          })
        }
      })
      return districts;
    });
  }
}

export function getSocietyWiseMachineCount(divisions) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "DIVISION.keyword": divisions
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "societies": {
        "composite": {
          "size": 5000,
          "sources": [
            {
              "division": {
                "terms": {
                  "field": "DIVISION.keyword"
                }
              }
            },
            {
              "district": {
                "terms": {
                  "field": "DISTRICT.keyword"
                }
              }
            },
            {
              "ar": {
                "terms": {
                  "field": "AR.keyword"
                }
              }
            },
            {
              "societyID": {
                "terms": {
                  "field": "_id"
                }
              }
            },
            {
              "society": {
                "terms": {
                  "field": "SOCIETY.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "HAPPYSEEDER": {
            "sum": {
              "field": "HAPPYSEEDER"
            }
          },
          "SUPERSEEDER": {
            "sum": {
              "field": "SUPERSEEDER"
            }
          },
          "SURFACESEEDER": {
            "sum": {
              "field": "SURFACESEEDER"
            }
          },
          "RMBPLOUGH": {
            "sum": {
              "field": "RMBPLOUGH"
            }
          },
          "ROTAVATOR": {
            "sum": {
              "field": "ROTAVATOR"
            }
          },
          "ZEROTILLDRILL": {
            "sum": {
              "field": "ZEROTILLDRILL"
            }
          },
          "CHOPPER": {
            "sum": {
              "field": "PADDYSTRAWCHOPPER"
            }
          },
          "MULCHER": {
            "sum": {
              "field": "MULCHER"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    // console.log("getSocietyWiseMachineCount",  JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSSocieties');
    return addMessage({ text: searchParameters }).then(function (result) {
     
      let sanitizedMessage = result.data.text;
      let societies = []
      
      sanitizedMessage.aggregations.societies.buckets.map((data, index) => {

        let objSociety = {
          id: data.key.societyID,
          name: data.key.society,
          division: data.key.division,
          district: data.key.district,
          ar: data.key.ar,
          "HAPPY SEEDER": 0,
          "SUPER SEEDER": 0,
          "SURFACE SEEDER": 0,
          "RMB PLOUGH": 0,
          ROTAVATOR: 0,
          "ZERO TILL DRILL": 0,
          CHOPPER: 0,
          MULCHER: 0
        }

        objSociety["HAPPY SEEDER"] = Number(objSociety["HAPPY SEEDER"]) + Number(data.HAPPYSEEDER.value)
        objSociety["SUPER SEEDER"] = Number(objSociety["SUPER SEEDER"]) + Number(data.SUPERSEEDER.value)
        objSociety["SURFACE SEEDER"] = Number(objSociety["SURFACE SEEDER"]) + Number(data.SURFACESEEDER.value)
        objSociety["RMB PLOUGH"] = Number(objSociety["RMB PLOUGH"]) + Number(data.RMBPLOUGH.value)
        objSociety["ROTAVATOR"] = Number(objSociety["ROTAVATOR"]) + Number(data.ROTAVATOR.value)
        objSociety["ZERO TILL DRILL"] = Number(objSociety["ZERO TILL DRILL"]) + Number(data.ZEROTILLDRILL.value)
        objSociety["CHOPPER"] = Number(objSociety["CHOPPER"]) + Number(data.CHOPPER.value)
        objSociety["MULCHER"] = Number(objSociety["MULCHER"]) + Number(data.MULCHER.value)

        societies.push(objSociety);

      })

      return societies;
    });
  }
}

export function getSocieytWiseSummaryForSocieties(societies, machineType , startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "SocietyID.keyword": societies
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "terms": {
              "MachineCode.keyword": [ machineType ]
            }
          }
        ]
      }
    },
    "size": 0,
     "aggs": {
        "societies": {
          "composite": {
            "size": 5000,
            "sources": [
              {
                "division": {
                  "terms": {
                    "field": "Division.keyword"
                  }
                }
              },
              {
                "district": {
                  "terms": {
                    "field": "District.keyword"
                  }
                }
              },
              {
                "ar": {
                  "terms": {
                    "field": "AR.keyword"
                  }
                }
              },
              {
                "society": {
                  "terms": {
                    "field": "SocietyID.keyword"
                    
                  }
                }
              },
              {
                "machine": {
                  "terms": {
                    "field": "Machine.keyword"
                  }
                }
              }
            ]
          },
          "aggs": {
            "total_hrs": {
              "sum": {
                "field": "MachineHrs"
              }
            }
          }
        }
      }
  }
  return (dispatch) => {
    // console.log("getSocieytWiseSummaryForSocieties", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let societies = []
      sanitizedMessage.aggregations.societies.buckets.map((data, index) => {

        if (societies.filter((f) => f.name === data.key.society).length === 0)
        {

          let society = {
            id: data.key.society,
            division: data.key.division,
            district: data.key.district,
            ar: data.key.ar,
            "HAPPY SEEDER": 0,
            "SUPER SEEDER": 0,
            "SURFACE SEEDER": 0,
            "RMB PLOUGH": 0,
            ROTAVATOR: 0,
            "ZERO TILL DRILL": 0,
            CHOPPER: 0,
            MULCHER: 0
          }
  
          if(data.key.machine === "HAPPY SEEDER")
          {
            society["HAPPY SEEDER"] = Number(Number(society["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
          
          if(data.key.machine === "SUPER SEEDER")
          {
            society["SUPER SEEDER"] = Number(Number(society["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "SURFACE SEEDER")
          {
            society["SURFACE SEEDER"] = Number(Number(society["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "RMB PLOUGH")
          {
            society["RMB PLOUGH"] = Number(Number(society["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ROTAVATOR")
          {
            society["ROTAVATOR"] = Number(Number(society["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ZERO TILL DRILL")
          {
            society["ZERO TILL DRILL"] = Number(Number(society["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "PADDY STRAW CHOPPER")
          {
            society["CHOPPER"] = Number(Number(society["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "MULCHER")
          {
            society["MULCHER"] = Number(Number(society["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
          }

          societies.push(society);
        }
        else {
          societies.filter((f) => f.name === data.key.society).map(society => {
            if (data.key.machine === "HAPPY SEEDER") {
              society["HAPPY SEEDER"] = Number(Number(society["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SUPER SEEDER") {
              society["SUPER SEEDER"] = Number(Number(society["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SURFACE SEEDER") {
              society["SURFACE SEEDER"] = Number(Number(society["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "RMB PLOUGH") {
              society["RMB PLOUGH"] = Number(Number(society["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ROTAVATOR") {
              society["ROTAVATOR"] = Number(Number(society["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ZERO TILL DRILL") {
              society["ZERO TILL DRILL"] = Number(Number(society["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "PADDY STRAW CHOPPER") {
              society["CHOPPER"] = Number(Number(society["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "MULCHER") {
              society["MULCHER"] = Number(Number(society["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
            }
          })
        }
      })
      return societies;
    });
  }
}

export function getSocieytWiseFreeHrsSummaryForSocieties(societies, machineType , startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "SocietyID.keyword": societies
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "terms": {
              "MachineCode.keyword": [ machineType ]
            }
          },
          {
            "range": {
              "MachineRental": {
                "lte": 0
              }
            }
          }
        ]
      }
    },
    "size": 0,
     "aggs": {
        "societies": {
          "composite": {
            "size": 5000,
            "sources": [
              {
                "division": {
                  "terms": {
                    "field": "Division.keyword"
                  }
                }
              },
              {
                "district": {
                  "terms": {
                    "field": "District.keyword"
                  }
                }
              },
              {
                "ar": {
                  "terms": {
                    "field": "AR.keyword"
                  }
                }
              },
              {
                "society": {
                  "terms": {
                    "field": "SocietyID.keyword"
                    
                  }
                }
              },
              {
                "machine": {
                  "terms": {
                    "field": "Machine.keyword"
                  }
                }
              }
            ]
          },
          "aggs": {
            "total_hrs": {
              "sum": {
                "field": "MachineHrs"
              }
            }
          }
        }
      }
  }
  return (dispatch) => {
    // console.log("getSocieytWiseSummaryForSocieties", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let societies = []
      sanitizedMessage.aggregations.societies.buckets.map((data, index) => {

        if (societies.filter((f) => f.name === data.key.society).length === 0)
        {

          let society = {
            id: data.key.society,
            division: data.key.division,
            district: data.key.district,
            ar: data.key.ar,
            "HAPPY SEEDER": 0,
            "SUPER SEEDER": 0,
            "SURFACE SEEDER": 0,
            "RMB PLOUGH": 0,
            ROTAVATOR: 0,
            "ZERO TILL DRILL": 0,
            CHOPPER: 0,
            MULCHER: 0
          }
  
          if(data.key.machine === "HAPPY SEEDER")
          {
            society["HAPPY SEEDER"] = Number(Number(society["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
          
          if(data.key.machine === "SUPER SEEDER")
          {
            society["SUPER SEEDER"] = Number(Number(society["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "SURFACE SEEDER")
          {
            society["SURFACE SEEDER"] = Number(Number(society["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "RMB PLOUGH")
          {
            society["RMB PLOUGH"] = Number(Number(society["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ROTAVATOR")
          {
            society["ROTAVATOR"] = Number(Number(society["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ZERO TILL DRILL")
          {
            society["ZERO TILL DRILL"] = Number(Number(society["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "PADDY STRAW CHOPPER")
          {
            society["CHOPPER"] = Number(Number(society["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "MULCHER")
          {
            society["MULCHER"] = Number(Number(society["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
          }

          societies.push(society);
        }
        else {
          societies.filter((f) => f.name === data.key.society).map(society => {
            if (data.key.machine === "HAPPY SEEDER") {
              society["HAPPY SEEDER"] = Number(Number(society["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SUPER SEEDER") {
              society["SUPER SEEDER"] = Number(Number(society["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SURFACE SEEDER") {
              society["SURFACE SEEDER"] = Number(Number(society["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "RMB PLOUGH") {
              society["RMB PLOUGH"] = Number(Number(society["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ROTAVATOR") {
              society["ROTAVATOR"] = Number(Number(society["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ZERO TILL DRILL") {
              society["ZERO TILL DRILL"] = Number(Number(society["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "PADDY STRAW CHOPPER") {
              society["CHOPPER"] = Number(Number(society["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "MULCHER") {
              society["MULCHER"] = Number(Number(society["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
            }
          })
        }
      })
      return societies;
    });
  }
}

export function getInspectorByAREmailID(arEmailID) {
  let searchParameters = {
    "size": 0,
    "query": {
      "match_phrase": {
        "AREMAILID": arEmailID
      }
    },
    "aggs": {
      "inspectors": {
        "terms": {
          "field": "INSPECTOREMAILID.keyword",
          "size": 5000
        },
        "aggs": {
          "inspectors1": {
            "terms": {
              "field": "INSPECTOR.keyword",
              "size": 5000,
              "order": {
                "_term": "asc"
              }
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getIPSSocieties');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let inspectors = []
      sanitizedMessage.aggregations.inspectors.buckets.map((data, index) => {
        let inspector = {
          emailID: data.key,
          name: data.inspectors1.buckets[0].key
        };
        inspectors.push(inspector);
      })
      return inspectors;
    });
  }
}

export function getDistrictsByDREmailID(drEmailID) {
  let searchParameters = {
    "size": 0,
    "query": {
      "match_phrase": {
        "DREMAILID": drEmailID
      }
    },
    "aggs": {
      "districts": {
        "terms": {
          "field": "DISTRICT.keyword",
          "size": 3000
        },
        "aggs": {
          "HAPPYSEEDER": {
            "sum": {
              "field": "HAPPYSEEDER"
            }
          },
          "SUPERSEEDER": {
            "sum": {
              "field": "SUPERSEEDER"
            }
          },
          "SURFACESEEDER": {
            "sum": {
              "field": "SURFACESEEDER"
            }
          },
          "RMBPLOUGH": {
            "sum": {
              "field": "RMBPLOUGH"
            }
          },
          "ROTAVATOR": {
            "sum": {
              "field": "ROTAVATOR"
            }
          },
          "ZEROTILLDRILL": {
            "sum": {
              "field": "ZEROTILLDRILL"
            }
          },
          "CHOPPER": {
            "sum": {
              "field": "PADDYSTRAWCHOPPER"
            }
          },
          "MULCHER": {
            "sum": {
              "field": "MULCHER"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getIPSSocieties');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let districts = []
      sanitizedMessage.aggregations.districts.buckets.map((data, index) => {

        let district = {
          name: data.key,
          "HAPPY SEEDER": 0,
          "SUPER SEEDER": 0,
          "SURFACE SEEDER": 0,
          "RMB PLOUGH": 0,
          ROTAVATOR: 0,
          "ZERO TILL DRILL": 0,
          CHOPPER: 0,
          MULCHER: 0
        }
        district["HAPPY SEEDER"] = Number(district["HAPPY SEEDER"]) + Number(data.HAPPYSEEDER.value)
        district["SUPER SEEDER"] = Number(district["SUPER SEEDER"]) + Number(data.SUPERSEEDER.value)
        district["SURFACE SEEDER"] = Number(district["SURFACE SEEDER"]) + Number(data.SURFACESEEDER.value)
        district["RMB PLOUGH"] = Number(district["RMB PLOUGH"]) + Number(data.RMBPLOUGH.value)
        district["ROTAVATOR"] = Number(district["ROTAVATOR"]) + Number(data.ROTAVATOR.value)
        district["ZERO TILL DRILL"] = Number(district["ZERO TILL DRILL"]) + Number(data.ZEROTILLDRILL.value)
        district["CHOPPER"] = Number(district["CHOPPER"]) + Number(data.CHOPPER.value)
        district["MULCHER"] = Number(district["MULCHER"]) + Number(data.MULCHER.value)
        districts.push(district);
      })
      return districts;
    });
  }
}

export function getSocietyWiseMachineCountForDisctricts(districts) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "DISTRICT.keyword": districts
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "societies": {
        "composite": {
          "size": 5000,
          "sources": [
            {
              "district": {
                "terms": {
                  "field": "DISTRICT.keyword"
                }
              }
            },
            {
              "ar": {
                "terms": {
                  "field": "AR.keyword"
                }
              }
            },
            {
              "societyID": {
                "terms": {
                  "field": "_id"
                }
              }
            },
            {
              "society": {
                "terms": {
                  "field": "SOCIETY.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "HAPPYSEEDER": {
            "sum": {
              "field": "HAPPYSEEDER"
            }
          },
          "SUPERSEEDER": {
            "sum": {
              "field": "SUPERSEEDER"
            }
          },
          "SURFACESEEDER": {
            "sum": {
              "field": "SURFACESEEDER"
            }
          },
          "RMBPLOUGH": {
            "sum": {
              "field": "RMBPLOUGH"
            }
          },
          "ROTAVATOR": {
            "sum": {
              "field": "ROTAVATOR"
            }
          },
          "ZEROTILLDRILL": {
            "sum": {
              "field": "ZEROTILLDRILL"
            }
          },
          "CHOPPER": {
            "sum": {
              "field": "PADDYSTRAWCHOPPER"
            }
          },
          "MULCHER": {
            "sum": {
              "field": "MULCHER"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    // console.log("getSocietyWiseMachineCount",  JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSSocieties');
    return addMessage({ text: searchParameters }).then(function (result) {
     
      let sanitizedMessage = result.data.text;
      let societies = []
      
      sanitizedMessage.aggregations.societies.buckets.map((data, index) => {

        let objSociety = {
          id: data.key.societyID,
          name: data.key.society,
          district: data.key.district,
          ar: data.key.ar,
          "HAPPY SEEDER": 0,
          "SUPER SEEDER": 0,
          "SURFACE SEEDER": 0,
          "RMB PLOUGH": 0,
          ROTAVATOR: 0,
          "ZERO TILL DRILL": 0,
          CHOPPER: 0,
          MULCHER: 0
        }

        objSociety["HAPPY SEEDER"] = Number(objSociety["HAPPY SEEDER"]) + Number(data.HAPPYSEEDER.value)
        objSociety["SUPER SEEDER"] = Number(objSociety["SUPER SEEDER"]) + Number(data.SUPERSEEDER.value)
        objSociety["SURFACE SEEDER"] = Number(objSociety["SURFACE SEEDER"]) + Number(data.SURFACESEEDER.value)
        objSociety["RMB PLOUGH"] = Number(objSociety["RMB PLOUGH"]) + Number(data.RMBPLOUGH.value)
        objSociety["ROTAVATOR"] = Number(objSociety["ROTAVATOR"]) + Number(data.ROTAVATOR.value)
        objSociety["ZERO TILL DRILL"] = Number(objSociety["ZERO TILL DRILL"]) + Number(data.ZEROTILLDRILL.value)
        objSociety["CHOPPER"] = Number(objSociety["CHOPPER"]) + Number(data.CHOPPER.value)
        objSociety["MULCHER"] = Number(objSociety["MULCHER"]) + Number(data.MULCHER.value)

        societies.push(objSociety);

      })

      return societies;
    });
  }
}

export function getARCircleWiseMachineCountForRCS(district) {
  let searchParameters = {
    "query": {
      "terms": {
        "DISTRICT.keyword": [
          district
        ]
      }
    },
    "size": 0,
    "aggs": {
      "ars": {
        "terms": {
          "field": "AR.keyword",
          "size": 3000
        },
        "aggs": {
          "HAPPYSEEDER": {
            "sum": {
              "field": "HAPPYSEEDER"
            }
          },
          "SUPERSEEDER": {
            "sum": {
              "field": "SUPERSEEDER"
            }
          },
          "SURFACESEEDER": {
            "sum": {
              "field": "SURFACESEEDER"
            }
          },
          "RMBPLOUGH": {
            "sum": {
              "field": "RMBPLOUGH"
            }
          },
          "ROTAVATOR": {
            "sum": {
              "field": "ROTAVATOR"
            }
          },
          "ZEROTILLDRILL": {
            "sum": {
              "field": "ZEROTILLDRILL"
            }
          },
          "CHOPPER": {
            "sum": {
              "field": "PADDYSTRAWCHOPPER"
            }
          },
          "MULCHER": {
            "sum": {
              "field": "MULCHER"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    // console.log("getARCircleWiseMachineCountForRCS",  JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSSocieties');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let ars = []
      sanitizedMessage.aggregations.ars.buckets.map((data, index) => {

        let ar = {
          name: data.key,
          "HAPPY SEEDER": 0,
          "SUPER SEEDER": 0,
          "SURFACE SEEDER": 0,
          "RMB PLOUGH": 0,
          ROTAVATOR: 0,
          "ZERO TILL DRILL": 0,
          CHOPPER: 0,
          MULCHER: 0
        }
        ar["HAPPY SEEDER"] = Number(ar["HAPPY SEEDER"]) + Number(data.HAPPYSEEDER.value)
        ar["SUPER SEEDER"] = Number(ar["SUPER SEEDER"]) + Number(data.SUPERSEEDER.value)
        ar["SURFACE SEEDER"] = Number(ar["SURFACE SEEDER"]) + Number(data.SURFACESEEDER.value)
        ar["RMB PLOUGH"] = Number(ar["RMB PLOUGH"]) + Number(data.RMBPLOUGH.value)
        ar["ROTAVATOR"] = Number(ar["ROTAVATOR"]) + Number(data.ROTAVATOR.value)
        ar["ZERO TILL DRILL"] = Number(ar["ZERO TILL DRILL"]) + Number(data.ZEROTILLDRILL.value)
        ar["CHOPPER"] = Number(ar["CHOPPER"]) + Number(data.CHOPPER.value)
        ar["MULCHER"] = Number(ar["MULCHER"]) + Number(data.MULCHER.value)
        ars.push(ar);
      })
      return ars;
    });
  }
}

export function getARWiseSummaryForRCS(ars, startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "AR.keyword": ars
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "range": {
              "MachineRental": {
                "gt": 0
              }
            }
          },
          {
            "terms": {
              "MachineCode.keyword": [
                "HAPPYSEEDER"
                ,"SUPERSEEDER",
                "SURFACESEEDER",
                "RMBPLOUGH",
                "ROTAVATOR",
                "ZEROTILLDRILL",
                "PADDYSTRAWCHOPPER",
                "MULCHER"
              ]
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "ars": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "ar": {
                "terms": {
                  "field": "AR.keyword"

                }
              }
            },
            {
              "machine": {
                "terms": {
                  "field": "Machine.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "total_hrs": {
            "sum": {
              "field": "MachineHrs"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    // console.log("getDistrictWiseSummaryForRCS",  JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let ars = [];

      sanitizedMessage.aggregations.ars.buckets.map((data, index) => {
        if (ars.filter((f) => f.name === data.key.ar.toUpperCase()).length === 0)
        {
          let ar = {
            name: data.key.ar.toUpperCase(),
            "HAPPY SEEDER": 0,
            "SUPER SEEDER": 0,
            "SURFACE SEEDER": 0,
            "RMB PLOUGH": 0,
            ROTAVATOR: 0,
            "ZERO TILL DRILL": 0,
            CHOPPER: 0,
            MULCHER: 0
          }
  
          if(data.key.machine === "HAPPY SEEDER")
          {
            ar["HAPPY SEEDER"] = Number(Number(ar["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
          
          if(data.key.machine === "SUPER SEEDER")
          {
            ar["SUPER SEEDER"] = Number(Number(ar["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "SURFACE SEEDER")
          {
            ar["SURFACE SEEDER"] = Number(Number(ar["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "RMB PLOUGH")
          {
            ar["RMB PLOUGH"] = Number(Number(ar["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ROTAVATOR")
          {
            ar["ROTAVATOR"] = Number(Number(ar["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ZERO TILL DRILL")
          {
            ar["ZERO TILL DRILL"] = Number(Number(ar["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "PADDY STRAW CHOPPER")
          {
            ar["CHOPPER"] = Number(Number(ar["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "MULCHER")
          {
            ar["MULCHER"] = Number(Number(ar["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
          }

          ars.push(ar);
        }
        else {
          ars.filter((f) => f.name === data.key.ar.toUpperCase()).map(ar => {
            if (data.key.machine === "HAPPY SEEDER") {
              ar["HAPPY SEEDER"] = Number(Number(ar["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SUPER SEEDER") {
              ar["SUPER SEEDER"] = Number(Number(ar["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SURFACE SEEDER") {
              ar["SURFACE SEEDER"] = Number(Number(ar["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "RMB PLOUGH") {
              ar["RMB PLOUGH"] = Number(Number(ar["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ROTAVATOR") {
              ar["ROTAVATOR"] = Number(Number(ar["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ZERO TILL DRILL") {
              ar["ZERO TILL DRILL"] = Number(Number(ar["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "PADDY STRAW CHOPPER") {
              ar["CHOPPER"] = Number(Number(ar["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "MULCHER") {
              ar["MULCHER"] = Number(Number(ar["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
            }
          })
        }
      })
      return ars;
    });
  }
}

export function getARWiseFreeSummaryForRCS(ars, startDate, endDate) {
  let searchParameters = {
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "AR.keyword": ars
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": endDate,
                "gte": startDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "term": {
              "MachineRental": 0
            }
          },
          {
            "terms": {
              "MachineCode.keyword": [
                "HAPPYSEEDER"
                ,"SUPERSEEDER",
                "SURFACESEEDER",
                "RMBPLOUGH",
                "ROTAVATOR",
                "ZEROTILLDRILL",
                "PADDYSTRAWCHOPPER",
                "MULCHER"
              ]
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "ars": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "ar": {
                "terms": {
                  "field": "AR.keyword"

                }
              }
            },
            {
              "machine": {
                "terms": {
                  "field": "Machine.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "total_hrs": {
            "sum": {
              "field": "MachineHrs"
            }
          }
        }
      }
    }
  }
  return (dispatch) => {
    // console.log("getDistrictWiseSummaryForRCS",  JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getIPSTransactions');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let ars = [];

      sanitizedMessage.aggregations.ars.buckets.map((data, index) => {
        if (ars.filter((f) => f.name === data.key.ar.toUpperCase()).length === 0)
        {
          let ar = {
            name: data.key.ar.toUpperCase(),
            "HAPPY SEEDER": 0,
            "SUPER SEEDER": 0,
            "SURFACE SEEDER": 0,
            "RMB PLOUGH": 0,
            ROTAVATOR: 0,
            "ZERO TILL DRILL": 0,
            CHOPPER: 0,
            MULCHER: 0
          }
  
          if(data.key.machine === "HAPPY SEEDER")
          {
            ar["HAPPY SEEDER"] = Number(Number(ar["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
          
          if(data.key.machine === "SUPER SEEDER")
          {
            ar["SUPER SEEDER"] = Number(Number(ar["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "SURFACE SEEDER")
          {
            ar["SURFACE SEEDER"] = Number(Number(ar["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "RMB PLOUGH")
          {
            ar["RMB PLOUGH"] = Number(Number(ar["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ROTAVATOR")
          {
            ar["ROTAVATOR"] = Number(Number(ar["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "ZERO TILL DRILL")
          {
            ar["ZERO TILL DRILL"] = Number(Number(ar["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "PADDY STRAW CHOPPER")
          {
            ar["CHOPPER"] = Number(Number(ar["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
          }
  
          if(data.key.machine === "MULCHER")
          {
            ar["MULCHER"] = Number(Number(ar["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
          }

          ars.push(ar);
        }
        else {
          ars.filter((f) => f.name === data.key.ar.toUpperCase()).map(ar => {
            if (data.key.machine === "HAPPY SEEDER") {
              ar["HAPPY SEEDER"] = Number(Number(ar["HAPPY SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SUPER SEEDER") {
              ar["SUPER SEEDER"] = Number(Number(ar["SUPER SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "SURFACE SEEDER") {
              ar["SURFACE SEEDER"] = Number(Number(ar["SURFACE SEEDER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "RMB PLOUGH") {
              ar["RMB PLOUGH"] = Number(Number(ar["RMB PLOUGH"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ROTAVATOR") {
              ar["ROTAVATOR"] = Number(Number(ar["ROTAVATOR"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "ZERO TILL DRILL") {
              ar["ZERO TILL DRILL"] = Number(Number(ar["ZERO TILL DRILL"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "PADDY STRAW CHOPPER") {
              ar["CHOPPER"] = Number(Number(ar["CHOPPER"]) + Number(data.total_hrs.value)).toFixed(2)
            }

            if (data.key.machine === "MULCHER") {
              ar["MULCHER"] = Number(Number(ar["MULCHER"]) + Number(data.total_hrs.value)).toFixed(2)
            }
          })
        }
      })
      return ars;
    });
  }
}

export function getARDetailsByARCircle(arCircle) {
  return (dispatch) => {
    return database.collection("Societies").where("AR", "==", arCircle).limit(1).get().then((querySnapshot) => {
      let ar = null;
      querySnapshot.forEach((doc) => {
        ar = doc.data().AREMAILID
      })
      return ar;
    }).catch((error) => {
      console.error(error)
    })
  }
}

export function getAllTransactions(societies, startDate, endDate, scrollID) {
  console.log("startDate",startDate);
  console.log("endDate",endDate);
  let searchParameters = null
  if (scrollID === "") {
    searchParameters = {
      "from": 0,
      "size": 10000,
      "query": {
        "bool": {
          "must": [
            
            {
              "range": {
                "TransactionDate": {
                  "lte": endDate,
                  "gte": startDate,
                  "format": "yyyy-MM-dd"
                }
              }
            }
          ]
        }
      },
      "sort": [
        {
          "TransactionDate": {
            "order": "asc"
          }
        }
      ]
    }
  }
  else {
    searchParameters = {
      "scroll": "1m",
      "scroll_id": scrollID
    }
  }

 console.log("searchParameters",JSON.stringify(searchParameters))

  return (dispatch) => {
    let addMessage = ''
    if (scrollID === "") {

      if (societies.length > 0)
        searchParameters.query.bool.must.push(
          {
            "terms": {
              "SocietyID.keyword": societies
            }
          }
        )
    
      addMessage = firebase.functions().httpsCallable('getIPSTransactionsWithScrollID');
    }
    else {
      addMessage = firebase.functions().httpsCallable('getIPSTransactionsForScrollID');
    }

    return addMessage({ text: searchParameters }).then(function (result) {
      const transactions = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((doc, index) => {
        let transaction = doc._source;
        transactions.push(transaction)
      })
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: transactions, scrollID: sanitizedMessage._scroll_id }
    });
  }
}

export function getAllDistrictTransactions(districts, startDate, endDate, scrollID) {
  let searchParameters = null
  if (scrollID === "") {
    searchParameters = {
      "from": 0,
      "size": 2000,
      "query": {
        "bool": {
          "must": [
            
            {
              "range": {
                "TransactionDate": {
                  "lte": endDate,
                  "gte": startDate,
                  "format": "yyyy-MM-dd"
                }
              }
            }
          ]
        }
      },
      "sort": [
        {
          "TransactionDate": {
            "order": "asc"
          }
        }
      ]
    }
  }
  else {
    searchParameters = {
      "scroll": "1m",
      "scroll_id": scrollID
    }
  }
  return (dispatch) => {
    let addMessage = ''
    if (scrollID === "") {

      if (districts.length > 0)
        searchParameters.query.bool.must.push(
          {
            "terms": {
              "District.keyword": districts
            }
          }
        )
      addMessage = firebase.functions().httpsCallable('getIPSTransactionsWithScrollID');
    }
    else {
      addMessage = firebase.functions().httpsCallable('getIPSTransactionsForScrollID');
    }

    return addMessage({ text: searchParameters }).then(function (result) {
      const transactions = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((doc, index) => {
        let transaction = doc._source;
        transactions.push(transaction)
      })
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: transactions, scrollID: sanitizedMessage._scroll_id }
    });
  }
}