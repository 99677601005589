import React, { Component } from 'react';

class Loader extends Component {
  
    componentDidMount() {
    }

    render() {
    
        return <div className="spinner-container">
            <div className="spinner-sub-container">
                <div className="spinner"></div>
            </div>
        </div>

    }
}

export default Loader