
import firebase from 'firebase';
import 'firebase/auth';

// Initialize Firebase
var config = {
    apiKey: "AIzaSyAkJPAX3TXYXOuISD3ulu1B_LlVa5w5lUs",
    authDomain: "ips2-0420.firebaseapp.com",
    databaseURL: "https://ips2-0420.firebaseio.com",
    projectId: "ips2-0420",
    storageBucket: "ips2-0420.appspot.com",
    appId: "1:53091001978:web:a7a36f83fe4f45a507bdc3"
  };

firebase.initializeApp(config);
export const database = firebase.firestore()
export const functions = firebase.functions()
export const storageRef = firebase.storage().ref();
export const auth = firebase.auth();

export default firebase
