import Main from './Main'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import {
  setAuthUser, signOutUser, getUserDetails, saveTransaction, getTransactionList, getAllTransactions
  , saveSociety, updateSociety, getSocietyList, checkUserExists
  , getUserList
  , setSociety, getSocietyDetailsByInspectorEmailID, getSocietyDetailsByEmailID
  , getAllTransactionData, getMachineWiseSummary, getMachineWiseFreeHrsSummary, getTractorSummary
  , getDivisionWiseMachineCountForRCS, getDivisionWiseSummaryForRCS, getDivisionWiseFreeSummaryForRCS
  , getDistrictWiseMachineCountForRCS, getDistrictWiseSummaryForRCS, getDistrictWiseFreeSummaryForRCS
  , getSocieytWiseSummaryForSocieties , getSocietyWiseMachineCount, getSocieytWiseFreeHrsSummaryForSocieties
  , getInspectorByAREmailID
  , getDistrictsByDREmailID, getSocietyWiseMachineCountForDisctricts, getAllDistrictTransactions
  , getARCircleWiseMachineCountForRCS, getARWiseSummaryForRCS, getARWiseFreeSummaryForRCS
  , getARDetailsByARCircle
  , getFarmerDetails
  ,getAllSocietiesThatHaveTransaction
  ,login
} from './actions/Common'

function mapStateToProps(state) {
  return {
    common: state.common
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setAuthUser, signOutUser, getUserDetails, saveTransaction, getTransactionList, getAllTransactions
    , saveSociety, updateSociety, getSocietyList, setSociety, getSocietyDetailsByInspectorEmailID, getSocietyDetailsByEmailID
    , getUserList, checkUserExists
    , getAllTransactionData, getMachineWiseSummary, getMachineWiseFreeHrsSummary, getTractorSummary
    , getDivisionWiseMachineCountForRCS, getDivisionWiseSummaryForRCS, getDivisionWiseFreeSummaryForRCS
    , getDistrictWiseMachineCountForRCS, getDistrictWiseSummaryForRCS , getDistrictWiseFreeSummaryForRCS
    , getSocieytWiseSummaryForSocieties, getSocietyWiseMachineCount, getSocieytWiseFreeHrsSummaryForSocieties
    , getInspectorByAREmailID
    , getDistrictsByDREmailID, getSocietyWiseMachineCountForDisctricts, getAllDistrictTransactions
    , getARCircleWiseMachineCountForRCS, getARWiseSummaryForRCS, getARWiseFreeSummaryForRCS
    , getARDetailsByARCircle
    , getFarmerDetails
    ,getAllSocietiesThatHaveTransaction
    ,login
  }, dispatch)
}

const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))

export default App