import React, { Component } from "react";
import Header from "./Header";
import "../css/App.css";
import { isMobile } from "../actions/Common";
var Typeahead = require("react-bootstrap-typeahead").Typeahead;

class TransactionAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionDate: new Date().toISOString().split("T")[0],
      village: "",
      farmerMobileNo: "",
      farmerName: "",
      villageError: "",
      farmerMobileNoError: "",
      farmerNameError: "",
      hrsError: "",
      saving: false,
      machineTypes: [
        { code: "HAPPYSEEDER", name: "HAPPY SEEDER" },
        { code: "SUPERSEEDER", name: "SUPER SEEDER" },
        { code: "PADDYSTRAWCHOPPER", name: "PADDY STRAW CHOPPER" },
        { code: "MULCHER", name: "MULCHER" },
        { code: "RMBPLOUGH", name: "RMB PLOUGH" },
        { code: "ZEROTILLDRILL", name: "ZERO TILL DRILL" },
        { code: "SUPERSMS", name: "SUPER SMS" },
        { code: "ROTARYSLASHER", name: "ROTARY SLASHER" },
        { code: "SHRUBMASTER", name: "SHRUB MASTER" },
        { code: "CUTTERCUMSPREADER", name: "CUTTER CUM SPREADER" },
        { code: "ROTAVATOR", name: "ROTAVATOR" },
        { code: "TRACTOR", name: "TRACTOR" },
        { code: "TRACTORTROLLEY", name: "TRACTOR TROLLEY" },
        { code: "LASERLEVELLER", name: "LASER LEVELLER" },
        { code: "DIRECTSEEDERRICE", name: "DIRECT SEEDER RICE  (DSR)" },
        { code: "MAIZEPLANTERMECHANICAL", name: "MAIZE PLANTER MECHNICAL" },
        { code: "MAIZEPLANTERVACCUM", name: "MAIZE PLANTER VACCUM" },
        { code: "SPRAYPUMP", name: "SPRAY PUMP" },
        { code: "PRESSUREDISKS", name: "PRESSURE DISKS" },
        { code: "DISKLOCK", name: "DISK LOCK" },
        { code: "BIOFERTILIZERSPREADER", name: "(BIO) FERTILIZER SPREADER" },
        { code: "CULTIVATOR", name: "CULTIVATOR" },
        { code: "SUBSOILER", name: "SUB SOILER" },
        { code: "POWERCLEANER", name: "POWER CLEANER" },
        { code: "DISCRAZOR", name: "DISC RAZOR" },
        { code: "HARROWTRIPPLE", name: "HARROW TRIPPLE" },
        { code: "COTTONSEEDDRILL", name: "COTTON SEED DRILL" },
        { code: "WATERTANK", name: "WATER TANK" },
        { code: "SUHAGA", name: "SUHAGA" },
        { code: "WHEATSEEDFAN", name: "WHEAT SEED FAN " },
        { code: "SEEDGRINDINGMACHINE", name: "SEED GRINDING MACHINE" },
        { code: "JINDRA", name: "JINDRA" },
        { code: "COMBINE", name: "COMBINE" },
        { code: "SEELARCOTTON", name: "SEELAR COTTON " },
        { code: "GENERATOR", name: "GENERATOR" },
        { code: "WHEATSEEDDRILL", name: "WHEAT SEED DRILL" },
        { code: "REAPER", name: "REAPER" },
        { code: "PLOUGH", name: "PLOUGH" },
        { code: "GROUNDLEVELLER", name: "GROUND LEVELLER" },
        { code: "STRAWREAPER", name: "STRAW REAPER" },
        { code: "POTATOPLANTER", name: "POTATO PLANTER" },
        { code: "TAWIAN", name: "TAWIAN" },
        { code: "SIMPLELEVELLER", name: "SIMPLE LEVELLER" },
        { code: "LOADER", name: "LOADER" },
        { code: "POTATODRILL", name: "POTATO DRILL" },
        { code: "MULTIDRILLMACHINE", name: "MULTI DRILL MACHINE" },
        { code: "PUDDLER", name: "PUDDLER (KADDO)" },
        { code: "CHOPPER", name: "CHOPPER" },
        { code: "CORNDRILL", name: "CORN DRILL" },
        { code: "PEASMACHINE", name: "PEAS MACHINE" },
        { code: "SEEDDRILL", name: "SEED DRILL" },
        { code: "CHIZLER", name: "CHIZLER" },
        { code: "PADDYPLANTER", name: "PADDY PLANTER" },
        { code: "DIESELTANK", name: "DIESEL TANK" },
        { code: "BHAUNI", name: "BHAUNI" },
        { code: "TOTA", name: "TOTA" },
        { code: "TILLER", name: "TILLER" },
        { code: "KARAH", name: "KARAH" },
        { code: "TARFALI", name: "TARFALI" },
        { code: "RAZOR", name: "RAZOR" },
        // { "code": "TAULYNEW", "name": "TAULY NEW" },
        { code: "BALER", name: "BALER" },
        { code: "THRESHER", name: "THRESHER" },
        { code: "KARCHAMACHINE", name: "KARCHA MACHINE" },
        { code: "RATOR", name: "RATOR" },
        { code: "SUGARCANERAGER", name: "SUGARCANE RAGER" },
        { code: "DISCHARROW", name: "DISC HARROW" },
        { code: "CHAINKUPPI", name: "CHAIN KUPPI" },
        { code: "SUGARCANESEEDER", name: "SUGARCANE SEEDER" },
        { code: "GRINDER", name: "GRINDER" },
        { code: "CHANGER", name: "CHANGER" },
        { code: "TRIPPLEPLOUGH", name: "TRIPPLE PLOUGH" },
        // { "code": "FILAGEMAKING", "name": "FILAGE MAKING" },
        { code: "RAKER", name: "RAKER" },
        { code: "CORNHARVESTER", name: "CORN HARVESTER" },
        { code: "CUTTER", name: "CUTTER" },
        {
          code: "MOTORMACHINE",
          name: "MOTOR MACHINE / MOTOR EXTRACTION MACHINE",
        },
        { code: "DISCPLOUGH", name: "DISC PLOUGH" },
        { code: "GAINIPLOUGH", name: "GAINI PLOUGH" },
        { code: "DUGPLOUGH", name: "DUG PLOUGH" },
        { code: "OFFSETHARROW", name: "OFFSET HARROW" },
        { code: "JAAL", name: "JAAL" },
        { code: "TARAKADI", name: "TARAKADI" },
        { code: "CARROTMACHINE", name: "CARROT MACHINE" },
        { code: "DISCBEDMAKER", name: "DISC BED MAKER" },
        { code: "LOADERBUCKET", name: "LOADER BUCKET" },
        { code: "SEEDGRADER", name: "SEED GRADER" },
        { code: "ACHATHALL", name: "ACHAT HALL" },
        { code: "TAWAWATTA", name: "TAWA WATTA" },
        { code: "JACK", name: "JACK" },
        { code: "SURFACESEEDER", name: "SURFACE SEEDER" },
        { code: "SMARTSEEDER", name: "SMART SEEDER" },
      ],
      machine: "",
      machineCode: "",
      machineHrs: "",
      machineRental: "",
      machines: [],
      machineTypeError: "",
      chargesPerHrsError: "",
      chargesPerHrs: "",
      farmerFatherName: "",
      farmerFatherNameError: "",
      farmerProfile: "",
      farmerProfileError: "",
      tractorRental: "",
      tractorRentalError: "",
      tractorRequired: false,
      farmerProfiles: [
        { code: "Small", name: "Small (landholding upto 2.5 acres)" },
        {
          code: "Marginal",
          name: "Marginal (landholding from 2.5 to 5 acres)",
        },
        { code: "Large", name: "Large (landholding above 5 acres)" },
      ],
      selectedMachine: [],
      grandTotal: {
        TotalMachineHrs: 0,
        TotalChargesPerHrs: 0,
        TotalMachineRental: 0,
        TotalTractorRental: 0,
        TotalTotalRental: 0,
      },
      totalRental: "",
      key: "",
      selectedIndex: -1,
    };
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onAddMachine = this.onAddMachine.bind(this);
    this.onRemoveMachine = this.onRemoveMachine.bind(this);
    this.searchFarmer = this.searchFarmer.bind(this);
    this.calculateGrandTotals = this.calculateGrandTotals.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.onMachineEdit = this.onMachineEdit.bind(this);
    this.closeAddEditWindow = this.closeAddEditWindow.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.transaction) {
      let transaction = this.props.location.state.transaction;
      this.setState({
        village: transaction.Village,
        farmerMobileNo: transaction.FarmerMobileNo,
        farmerName: transaction.FarmerName,
        farmerFatherName: transaction.FatherName,
        farmerProfile: transaction.FarmerProfile,
        machines: transaction.Machines,
        transactionDate: transaction.TransactionDate,
        key: transaction.key,
      });
      this.calculateGrandTotals(transaction.Machines);
    }
  }

  onSave() {
    let villageError = "";
    let farmerMobileNoError = "";
    let farmerNameError = "";
    let farmerFatherNameError = "";
    let farmerProfileError = "";

    this.setState({ saving: true });

    if (this.state.village.trim() === "") {
      villageError = "Please enter village name";
    }
    if (this.state.farmerMobileNo.trim() === "") {
      farmerMobileNoError = "Please enter farmer mobile no";
    }
    if (this.state.farmerName.trim() === "") {
      farmerNameError = "Please provide farmer name";
    }
    if (this.state.farmerFatherName.trim() === "") {
      farmerFatherNameError = "Please provide father name";
    }
    if (this.state.farmerProfile.trim() === "") {
      farmerProfileError = "Please select farmer profile";
    }
    if (
      villageError.trim() !== "" ||
      farmerMobileNoError.trim() !== "" ||
      farmerNameError.trim() !== "" ||
      farmerFatherNameError.trim() !== "" ||
      farmerProfileError.trim() !== ""
    ) {
      this.setState({
        villageError: villageError,
        farmerMobileNoError: farmerMobileNoError,
        farmerNameError: farmerNameError,
        farmerFatherNameError: farmerFatherNameError,
        farmerProfileError: farmerProfileError,
        saving: false,
      });
      return;
    }
    if (this.state.machines.length === 0) {
      alert("Please add atleast one machine");
      this.setState({ saving: false });
      return;
    }

    let transaction = {
      Division: this.props.common.society.DIVISION.toUpperCase(),
      District: this.props.common.society.DISTRICT.toUpperCase(),
      AR: this.props.common.society.AR,
      Village: this.state.village,
      FarmerMobileNo: this.state.farmerMobileNo,
      FarmerName: this.state.farmerName,
      FatherName: this.state.farmerFatherName,
      FarmerProfile: this.state.farmerProfile,
      Machines: this.state.machines,
      TransactionDate: this.state.transactionDate,
      ...this.state.grandTotal,
    };

    // console.log(transaction);
    this.props.saveTransaction(transaction, this.state.key).then(() => {
      alert("Data saved successfully");
      this.onCancel();
    });
  }

  onAddMachine() {
    let hrsError = "";
    let chargesPerHrsError = "";
    let machineTypeError = "";
    let tractorRentalError = "";

    if (this.state.machine.trim() === "") {
      machineTypeError = "Please select Machine";
    }

    if (this.state.machineHrs <= 0) {
      hrsError = "Please provide Rented Hrs";
    }

    if (this.state.chargesPerHrs < 0) {
      chargesPerHrsError = "Please provide Rental Charges Per Hour";
    }

    if (this.state.tractorRequired) {
      if (this.state.tractorRental <= 0) {
        tractorRentalError = "Please provide Tracktor Rental Charges";
      }
    }

    if (this.state.machine.trim() !== "") {
      // console.log(this.props.common.society[this.state.machineCode]);
      console.log("this.props.common.society", this.props.common.society);
      if (!this.props.common.society.hasOwnProperty(this.state.machineCode)) {
        machineTypeError = "Machine cannot be added as count is 0";
      } else if (this.props.common.society[this.state.machineCode] === 0) {
        machineTypeError = "Machine cannot be added as count is 0";
      }
    }

    if (this.state.machineHrs > 99.99) {
      hrsError = "Please enter proper machine hrs.";
    }

    if (
      machineTypeError.trim() !== "" ||
      chargesPerHrsError.trim() !== "" ||
      hrsError.trim() !== "" ||
      tractorRentalError !== ""
    ) {
      console.log("error");
      this.setState({
        chargesPerHrsError: chargesPerHrsError,
        hrsError: hrsError,
        machineTypeError: machineTypeError,
        tractorRentalError: tractorRentalError,
      });
      return;
    }

    let machine = {
      MachineCode: this.state.machineCode,
      Machine: this.state.machine,
      MachineHrs: this.state.machineHrs,
      ChargesPerHrs: this.state.chargesPerHrs,
      MachineRental: Number(Number(this.state.machineRental).toFixed(2)),
      TractorRequired: this.state.tractorRequired,
      TractorRental: this.state.tractorRental,
      TotalRental: Number(
        Number(
          Number(this.state.machineRental) + Number(this.state.tractorRental)
        ).toFixed(2)
      ),
    };
    console.log("machine", machine);
    console.log("this.state.selectedIndex", this.state.selectedIndex);
    let machines = this.state.machines;
    if (this.state.selectedIndex === -1) {
      machines.unshift(machine);
    } else {
      machines[this.state.selectedIndex] = machine;
    }
    console.log("machines", machines);
    this.calculateGrandTotals(machines);

    this.setState({
      machines: machines,
      machineCode: "",
      machine: "",
      machineTypeError: "",
      hrsError: "",
      chargesPerHrsError: "",
      chargesPerHrs: "",
      machineHrs: "",
      machineRental: "",
      totalRental: "",
      tractorRequired: false,
      tractorRental: "",
      selectedMachine: [],
      showAddEditMachine: false,
      selectedIndex: -1,
    });
  }

  closeAddEditWindow() {
    this.setState({
      machineCode: "",
      machine: "",
      machineTypeError: "",
      hrsError: "",
      chargesPerHrsError: "",
      chargesPerHrs: "",
      machineHrs: "",
      machineRental: "",
      totalRental: "",
      tractorRequired: false,
      tractorRental: "",
      selectedMachine: [],
      showAddEditMachine: false,
      selectedIndex: -1,
    });
  }

  onMachineEdit(index) {
    let machine = this.state.machines[index];
    this.setState({
      machineCode: machine.MachineCode,
      machine: machine.Machine,
      machineHrs: machine.MachineHrs,
      chargesPerHrs: machine.ChargesPerHrs,
      machineRental: machine.MachineRental,
      tractorRequired: machine.TractorRequired,
      tractorRental: machine.TractorRental,
      totalRental: machine.TotalRental,
      selectedIndex: index,
      showAddEditMachine: true,
    });
  }

  onRemoveMachine(index) {
    let machines = this.state.machines;
    machines.splice(index, 1);

    this.calculateGrandTotals(machines);

    this.setState({ machines: machines });
  }

  onCancel() {
    this.setState({
      farmerMobileNo: "",
      farmerName: "",
      villageError: "",
      farmerMobileNoError: "",
      farmerNameError: "",
      hrsError: "",
      machine: "",
      machineCode: "",
      machines: [],
      machineRental: 0,
      machineTypeError: "",
      chargesPerHrsError: "",
      farmerFatherName: "",
      farmerFatherNameError: "",
      farmerProfile: "",
      farmerProfileError: "",
      tractorRental: 0,
      tractorRentalError: "",
      tractorRequired: false,
      grandTotal: {
        TotalMachineHrs: 0,
        TotalChargesPerHrs: 0,
        TotalMachineRental: 0,
        TotalTractorRental: 0,
        TotalTotalRental: 0,
      },
      totalRental: 0,
      saving: false,
    });
  }

  async searchFarmer() {
    let farmerDetails = await this.props.getFarmerDetails(
      this.state.farmerMobileNo
    );
    if (farmerDetails) {
      this.setState({
        farmerName: farmerDetails.FarmerName,
        farmerFatherName: farmerDetails.FatherName,
        farmerProfile: farmerDetails.FarmerProfile,
        village: farmerDetails.Village,
      });
    } else {
      alert("No details found!!!");
    }
  }

  calculateGrandTotals(machines) {
    let totalMachineHrs = 0;
    let totalChargesPerHrs = 0;
    let totalMachineRental = 0;
    let totalTractorRental = 0;
    let totalTotalRental = 0;

    for (let i = 0; i < machines.length; i++) {
      const machine = machines[i];
      totalMachineHrs = totalMachineHrs + machine.MachineHrs;
      totalChargesPerHrs = totalChargesPerHrs + machine.ChargesPerHrs;
      totalMachineRental = totalMachineRental + machine.MachineRental;
      totalTractorRental = totalTractorRental + machine.TractorRental;
      totalTotalRental = totalTotalRental + machine.TotalRental;
    }

    let grandTotal = {
      TotalMachineHrs: Number(Number(totalMachineHrs).toFixed(2)),
      TotalChargesPerHrs: Number(Number(totalChargesPerHrs).toFixed(2)),
      TotalMachineRental: Number(Number(totalMachineRental).toFixed(2)),
      TotalTractorRental: Number(Number(totalTractorRental).toFixed(2)),
      TotalTotalRental: Number(Number(totalTotalRental).toFixed(2)),
    };

    this.setState({ grandTotal: grandTotal });
  }

  renderDesktop() {
    return (
      <div className="col-md-12">
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "25%", verticalAlign: "top" }}>
                ਮਸ਼ੀਨਾਂ (Machine(s))
              </th>
              <th style={{ verticalAlign: "top" }}>
                ਘੰਟੇ) ਕਿਰਾਇਆ (Hour(s) Rented)
              </th>
              <th style={{ verticalAlign: "top" }}>
                ਘੰਟੇ ਪ੍ਰਤੀ ਕਿਰਾਇਆ ਕਿਰਾਏ (Rental Charges Per Hour)
              </th>
              <th style={{ verticalAlign: "top" }}>
                ਕਿਰਾਏ ਦਾ ਖਰਚਾ (Rental Charges)
              </th>
              <th style={{ verticalAlign: "top" }}>
                ਟਰੈਕਟਰ ਲੋੜੀਂਦਾ (Tractor Required)
              </th>
              <th style={{ verticalAlign: "top" }}>
                ਟਰੈਕਟਰ ਕਿਰਾਇਆ (Tractor Rental)
              </th>
              <th style={{ verticalAlign: "top" }}>
                ਕੁਲ ਕਿਰਾਇਆ (Total Rental)
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  className={
                    this.state.machineTypeError !== "" ? "has-error" : ""
                  }
                >
                  <Typeahead
                    id="selectMachine"
                    autoFocus={false}
                    allowNew={false}
                    multiple={false}
                    options={this.state.machineTypes}
                    labelKey="name"
                    placeholder="Select Machine"
                    filterBy={["name"]}
                    dropup={false}
                    highlightOnlyResult={false}
                    selected={this.state.selectedMachine}
                    onChange={(selected) => {
                      // console.log(selected);
                      if (selected && selected.length > 0)
                        this.setState({
                          machineCode: selected[0].code,
                          machine: selected[0].name,
                          selectedMachine: selected,
                          machineTypeError: "",
                        });
                      else {
                        this.setState({
                          selectedMachine: selected,
                          machineTypeError: "",
                        });
                      }
                    }}
                  />
                  {this.state.machineTypeError !== "" && (
                    <span className="help-block">
                      {this.state.machineTypeError}
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div className={this.state.hrsError !== "" ? "has-error" : ""}>
                  <input
                    type="number"
                    className="form-control"
                    min="1"
                    style={{ textAlign: "right" }}
                    onChange={(evt) => {
                      if (Number(evt.target.value) < 100) {
                        let machineRental = Number(
                          Number(
                            Number(evt.target.value) *
                              Number(this.state.chargesPerHrs)
                          ).toFixed(2)
                        );
                        let totalRental = Number(
                          Number(
                            Number(machineRental) +
                              Number(this.state.tractorRental)
                          ).toFixed(2)
                        );
                        this.setState({
                          machineHrs: Number(evt.target.value),
                          machineRental: machineRental,
                          hrsError: "",
                          totalRental: totalRental,
                        });
                      }
                    }}
                    // onBlur={()=> {
                    //     if(this.state.machineHrs > 99){this.setState({hrsError:'Please enter proper machine hrs.'})}
                    // }}
                    value={this.state.machineHrs}
                  />
                  {this.state.hrsError !== "" && (
                    <span className="help-block">{this.state.hrsError}</span>
                  )}
                </div>
              </td>
              <td>
                <div
                  className={
                    this.state.chargesPerHrsError !== "" ? "has-error" : ""
                  }
                >
                  <input
                    type="number"
                    className="form-control"
                    min="0"
                    style={{ textAlign: "right" }}
                    onChange={(evt) => {
                      let machineRental = Number(
                        Number(
                          Number(evt.target.value) *
                            Number(this.state.machineHrs)
                        ).toFixed(2)
                      );
                      let totalRental = Number(
                        Number(
                          Number(machineRental) +
                            Number(this.state.tractorRental)
                        ).toFixed(2)
                      );
                      this.setState({
                        chargesPerHrs: Number(evt.target.value),
                        machineRental: machineRental,
                        totalRental: totalRental,
                        chargesPerHrsError: "",
                      });
                    }}
                    value={this.state.chargesPerHrs}
                  />
                  {this.state.chargesPerHrsError !== "" && (
                    <span className="help-block">
                      {this.state.chargesPerHrsError}
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div
                  className="form-control"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    textAlign: "right",
                  }}
                >
                  {this.state.machineRental}
                </div>
              </td>
              <td>
                <input
                  type="checkbox"
                  style={{ marginTop: "13px", marginLeft: "35px" }}
                  checked={this.state.tractorRequired}
                  value={this.state.tractorRequired}
                  onChange={() => {
                    let totalRental = Number(
                      Number(this.state.machineRental).toFixed(2)
                    );
                    this.setState({
                      tractorRequired: !this.state.tractorRequired,
                      tractorRentalError: "",
                      tractorRental: "",
                      totalRental: totalRental,
                    });
                  }}
                />
              </td>
              <td>
                {this.state.tractorRequired && (
                  <div
                    className={
                      this.state.tractorRentalError !== "" ? "has-error" : ""
                    }
                  >
                    <input
                      type="number"
                      className="form-control"
                      style={{ textAlign: "right" }}
                      onChange={(evt) => {
                        let totalRental = Number(
                          Number(
                            Number(this.state.machineRental) +
                              Number(evt.target.value)
                          ).toFixed(2)
                        );
                        this.setState({
                          tractorRental: Number(evt.target.value),
                          tractorRentalError: "",
                          totalRental: totalRental,
                        });
                      }}
                      value={this.state.tractorRental}
                    />
                    {this.state.tractorRentalError !== "" && (
                      <span className="help-block">
                        {this.state.tractorRentalError}
                      </span>
                    )}
                  </div>
                )}
              </td>
              <td>
                <span
                  className="form-control"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    textAlign: "right",
                  }}
                >
                  {this.state.totalRental}
                </span>{" "}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-warning btn-md"
                  onClick={this.onAddMachine}
                >
                  ADD{" "}
                </button>
              </td>
            </tr>
            {this.state.machines.map((machine, index) => (
              <tr id={index}>
                <td>
                  <span
                    className="form-control"
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    {machine.Machine}
                  </span>{" "}
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    min="1"
                    style={{ textAlign: "right" }}
                    onChange={(evt) => {
                      if (Number(evt.target.value) < 100) {
                        let machines = this.state.machines;
                        machines[index].MachineHrs = Number(evt.target.value);
                        machines[index].MachineRental = Number(
                          Number(
                            Number(evt.target.value) *
                              Number(machines[index].ChargesPerHrs)
                          ).toFixed(2)
                        );
                        machines[index].TotalRental = Number(
                          Number(
                            Number(machine.MachineRental) +
                              Number(machine.TractorRental)
                          ).toFixed(2)
                        );
                        this.calculateGrandTotals(machines);
                        this.setState({ machines: machines });
                      }
                    }}
                    value={machine.MachineHrs}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    min="1"
                    style={{ textAlign: "right" }}
                    onChange={(evt) => {
                      let machines = this.state.machines;
                      machines[index].ChargesPerHrs = Number(
                        Number(evt.target.value).toFixed(2)
                      );
                      machines[index].MachineRental = Number(
                        Number(
                          Number(evt.target.value) *
                            Number(machines[index].MachineHrs)
                        ).toFixed(2)
                      );
                      machines[index].TotalRental = Number(
                        Number(
                          Number(machine.MachineRental) +
                            Number(machine.TractorRental)
                        ).toFixed(2)
                      );
                      this.calculateGrandTotals(machines);
                      this.setState({ machines: machines });
                    }}
                    value={machine.ChargesPerHrs}
                  />
                </td>
                <td>
                  <span
                    className="form-control"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      textAlign: "right",
                    }}
                  >
                    {machine.MachineRental}
                  </span>{" "}
                </td>
                <td>
                  <span
                    className="form-control"
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    {machine.TractorRequired ? "Yes" : "No"}
                  </span>{" "}
                </td>
                <td>
                  {machine.TractorRequired && (
                    <input
                      type="number"
                      className="form-control"
                      min="1"
                      style={{ textAlign: "right" }}
                      onChange={(evt) => {
                        let machines = this.state.machines;
                        machines[index].TractorRental = Number(
                          Number(evt.target.value).toFixed(2)
                        );
                        machines[index].TotalRental = Number(
                          Number(
                            Number(machine.MachineRental) +
                              Number(machine.TractorRental)
                          ).toFixed(2)
                        );
                        this.calculateGrandTotals(machines);
                      }}
                      value={machine.TractorRental}
                    />
                  )}
                </td>
                <td style={{ textAlign: "right" }}>
                  <span
                    className="form-control"
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    {" "}
                    {machine.TotalRental}{" "}
                  </span>
                </td>
                <td>
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-default btn-md"
                      onClick={() => this.onRemoveMachine(index)}
                    >
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {this.state.machines.length > 0 && (
              <tr>
                <td>
                  {" "}
                  <span
                    className="form-control"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    Grand Total
                  </span>{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  <span
                    className="form-control"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {this.state.grandTotal.TotalMachineHrs}{" "}
                  </span>{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  <span
                    className="form-control"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {this.state.grandTotal.TotalChargesPerHrs}{" "}
                  </span>{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  <span
                    className="form-control"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.grandTotal.TotalMachineRental}{" "}
                  </span>{" "}
                </td>
                <td>
                  {" "}
                  <span
                    className="form-control"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </span>{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  <span
                    className="form-control"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.grandTotal.TotalTractorRental}
                  </span>{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  <span
                    className="form-control"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.grandTotal.TotalTotalRental}
                  </span>{" "}
                </td>
                <td></td>
              </tr>
            )}
            {this.state.machines.length === 0 && (
              <tr>
                <td colSpan="8">
                  <i>No machines added</i>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  renderMobile() {
    return (
      <React.Fragment>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-warning btn-lg"
            onClick={() => {
              this.setState({ showAddEditMachine: true, selectedIndex: -1 });
            }}
          >
            <i className="fa fa-add"></i> Add Machine
          </button>
        </div>

        {this.state.machines.map((machine, index) => (
          <div key={"machine_" + index} className="machine">
            <div>{machine.Machine}</div>
            <div>
              {machine.MachineHrs} (hrs) X ₹ {machine.ChargesPerHrs} = ₹{" "}
              {machine.MachineRental}
            </div>
            {machine.TractorRequired && (
              <div>Tractor rental: ₹ {machine.TractorRental}</div>
            )}
            <div>Total rental: ₹ {machine.TotalRental}</div>
            <div className="machine-footer">
              <button
                className="btn btn-default btn-lg"
                onClick={() => this.onMachineEdit(index)}
              >
                Edit
              </button>
              &nbsp;
              <button
                className="btn btn-default  btn-lg"
                onClick={() => this.onRemoveMachine(index)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}

        {this.state.machines.length > 0 && (
          <div className="machine-grand-total">
            <div>
              <b>Grand Total</b>{" "}
            </div>
            <div>
              {" "}
              Total Machine Hrs: {this.state.grandTotal.TotalMachineHrs}{" "}
            </div>
            <div>
              {" "}
              Total Charges Per Hr: {
                this.state.grandTotal.TotalChargesPerHrs
              }{" "}
            </div>
            <div>
              {" "}
              Total Rental Charges: {
                this.state.grandTotal.TotalMachineRental
              }{" "}
            </div>
            <div>
              {" "}
              Total Tractor Rental:{this.state.grandTotal.TotalTractorRental}
            </div>
            <div> Total Rental: {this.state.grandTotal.TotalTotalRental}</div>
          </div>
        )}

        {this.state.showAddEditMachine && (
          <div className="window">
            <div className="window-header">
              <div
                className="window-close-btn"
                onClick={() => {
                  this.setState({ showAddEditMachine: false });
                }}
              >
                <svg viewBox="0 0 496.096 496.096">
                  <g>
                    <g>
                      <path
                        d="M259.41,247.998L493.754,13.654c3.123-3.124,3.123-8.188,0-11.312c-3.124-3.123-8.188-3.123-11.312,0L248.098,236.686
                            L13.754,2.342C10.576-0.727,5.512-0.639,2.442,2.539c-2.994,3.1-2.994,8.015,0,11.115l234.344,234.344L2.442,482.342
                            c-3.178,3.07-3.266,8.134-0.196,11.312s8.134,3.266,11.312,0.196c0.067-0.064,0.132-0.13,0.196-0.196L248.098,259.31
                            l234.344,234.344c3.178,3.07,8.242,2.982,11.312-0.196c2.995-3.1,2.995-8.016,0-11.116L259.41,247.998z"
                      />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              Add Machine
            </div>
            <div className="window-content">
              <div
                className={
                  this.state.machineTypeError === ""
                    ? "mb-3 "
                    : "mb-3  has-error"
                }
              >
                <label>ਮਸ਼ੀਨਾਂ (Machine(s))</label>
                {this.state.selectedIndex === -1 && (
                  <Typeahead
                    id="selectMachine"
                    autoFocus={false}
                    allowNew={false}
                    multiple={false}
                    options={this.state.machineTypes}
                    labelKey="name"
                    placeholder="Select Machine"
                    filterBy={["name"]}
                    dropup={false}
                    highlightOnlyResult={false}
                    selected={this.state.selectedMachine}
                    onChange={(selected) => {
                      // console.log(selected);
                      if (selected && selected.length > 0)
                        this.setState({
                          machineCode: selected[0].code,
                          machine: selected[0].name,
                          selectedMachine: selected,
                          machineTypeError: "",
                        });
                      else {
                        this.setState({
                          selectedMachine: selected,
                          machineTypeError: "",
                        });
                      }
                    }}
                  />
                )}
                {this.state.selectedIndex !== -1 && (
                  <input
                    type="text"
                    className="form-control"
                    disabled={true}
                    value={this.state.machine}
                  />
                )}
                {this.state.machineTypeError !== "" && (
                  <span className="help-block">
                    {this.state.machineTypeError}
                  </span>
                )}
              </div>
              <div
                className={
                  this.state.hrsError === "" ? "mb-3 " : "mb-3  has-error"
                }
              >
                <label>ਘੰਟੇ) ਕਿਰਾਇਆ (Hour(s) Rented)</label>
                <input
                  type="number"
                  className="form-control"
                  min="1"
                  style={{ textAlign: "right" }}
                  onChange={(evt) => {
                    if (Number(evt.target.value) < 100) {
                      let machineRental = Number(
                        Number(
                          Number(evt.target.value) *
                            Number(this.state.chargesPerHrs)
                        ).toFixed(2)
                      );
                      let totalRental = Number(
                        Number(
                          Number(machineRental) +
                            Number(this.state.tractorRental)
                        ).toFixed(2)
                      );
                      this.setState({
                        machineHrs: Number(evt.target.value),
                        machineRental: machineRental,
                        hrsError: "",
                        totalRental: totalRental,
                      });
                    }
                  }}
                  onBlur={() => {
                    if (this.state.machineHrs > 99.99) {
                      this.setState({
                        hrsError: "Please enter proper machine hrs.",
                      });
                    }
                  }}
                  value={this.state.machineHrs}
                />
                {this.state.hrsError !== "" && (
                  <span className="help-block">{this.state.hrsError}</span>
                )}
              </div>
              <div
                className={
                  this.state.chargesPerHrsError === ""
                    ? "mb-3 "
                    : "mb-3 has-error"
                }
              >
                <lable>ਘੰਟੇ ਪ੍ਰਤੀ ਕਿਰਾਇਆ ਕਿਰਾਏ (Rental Charges Per Hour)</lable>
                <input
                  type="number"
                  className="form-control"
                  min="0"
                  style={{ textAlign: "right" }}
                  onChange={(evt) => {
                    let machineRental = Number(
                      Number(
                        Number(evt.target.value) * Number(this.state.machineHrs)
                      ).toFixed(2)
                    );
                    let totalRental = Number(
                      Number(
                        Number(machineRental) + Number(this.state.tractorRental)
                      ).toFixed(2)
                    );
                    this.setState({
                      chargesPerHrs: Number(evt.target.value),
                      machineRental: machineRental,
                      totalRental: totalRental,
                      chargesPerHrsError: "",
                    });
                  }}
                  value={this.state.chargesPerHrs}
                />
                {this.state.chargesPerHrsError !== "" && (
                  <span className="help-block">
                    {this.state.chargesPerHrsError}
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label>ਕਿਰਾਏ ਦਾ ਖਰਚਾ (Rental Charges)</label>
                <input
                  type="number"
                  className="form-control"
                  disabled={true}
                  min="0"
                  style={{ textAlign: "right" }}
                  value={this.state.machineRental}
                />
              </div>
              <div className="mb-3 ">
                <lable>ਟਰੈਕਟਰ ਲੋੜੀਂਦਾ (Tractor Required)</lable>
                <input
                  type="checkbox"
                  checked={this.state.tractorRequired}
                  value={this.state.tractorRequired}
                  onChange={() => {
                    let totalRental = Number(
                      Number(this.state.machineRental).toFixed(2)
                    );
                    this.setState({
                      tractorRequired: !this.state.tractorRequired,
                      tractorRentalError: "",
                      tractorRental: "",
                      totalRental: totalRental,
                    });
                  }}
                />
              </div>
              {this.state.tractorRequired && (
                <div
                  className={
                    this.state.tractorRentalError === ""
                      ? "mb-3 "
                      : "mb-3 has-error"
                  }
                >
                  <label>ਟਰੈਕਟਰ ਕਿਰਾਇਆ (Tractor Rental)</label>
                  <input
                    type="number"
                    className="form-control"
                    style={{ textAlign: "right" }}
                    onChange={(evt) => {
                      let totalRental = Number(
                        Number(
                          Number(this.state.machineRental) +
                            Number(evt.target.value)
                        ).toFixed(2)
                      );
                      this.setState({
                        tractorRental: Number(evt.target.value),
                        tractorRentalError: "",
                        totalRental: totalRental,
                      });
                    }}
                    value={this.state.tractorRental}
                  />
                  {this.state.tractorRentalError !== "" && (
                    <span className="help-block">
                      {this.state.tractorRentalError}
                    </span>
                  )}
                </div>
              )}
              <div className="mb-3">
                <label>ਕੁਲ ਕਿਰਾਇਆ (Total Rental)</label>
                <input
                  type="number"
                  className="form-control"
                  disabled={true}
                  min="0"
                  style={{ textAlign: "right" }}
                  value={this.state.totalRental}
                />
                {/* <span className="form-control" style={{ border: "none", backgroundColor: "transparent", textAlign: "right" }}>{this.state.totalRental}</span>  */}
              </div>
            </div>
            <div className="window-footer">
              <button
                className="btn btn-default btn-lg"
                onClick={this.closeAddEditWindow}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary  btn-lg"
                onClick={this.onAddMachine}
              >
                Add
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderMobileMachineList() {
    return <React.Fragment></React.Fragment>;
  }

  renderMobileAddEditMachine() {}

  renderFooter() {
    return (
      <React.Fragment>
        {!isMobile() && (
          <div className="col-md-12">
            <button className="btn btn-default" onClick={this.onCancel}>
              Cancel
            </button>
            <button
              className="btn btn-primary pull-right"
              onClick={this.onSave}
              disabled={this.state.saving}
            >
              Save
            </button>
          </div>
        )}
        {isMobile() && (
          <div className="footer">
            <button className="btn btn-default btn-lg" onClick={this.onCancel}>
              Cancel
            </button>
            <button
              className="btn btn-primary  btn-lg"
              onClick={this.onSave}
              disabled={this.state.saving}
            >
              Save
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }

  getMaxDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1.
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    console.log(formattedDate);
    return formattedDate;
  }

  render() {
    return (
      <div>
        <Header menuSelected="transactionadd" {...this.props} />
        <main className="container">
          {/* <br /> */}
          <div className="row">
            <div className="col-md-12">
              <div
                className="my-3 p-3 bg-white rounded shadow-sm"
                style={{ minHeight: "180px", fontSize: "14px" }}
              >
                <h3 className="border-bottom border-gray pb-2 mb-0">
                  {" "}
                  <span className="fa-stack fa-md">
                    <i className="fa fa-circle-thin fa-stack-2x"></i>
                    <i className="fa fa-plus fa-stack-1x"></i>
                  </span>{" "}
                  Add Transaction
                </h3>
                <br />
                <div className="mb-3 col-md-6">
                  <label>Transaction Date</label>
                  <input
                    type="date"
                    className="form-control"
                    min="2020-10-01"
                    max={this.getMaxDate()}
                    onChange={(evt) =>
                      this.setState({ transactionDate: evt.target.value })
                    }
                    value={this.state.transactionDate}
                  />
                </div>

                <div
                  className={
                    this.state.farmerMobileNoError === ""
                      ? "mb-3 col-md-6"
                      : "mb-3 col-md-6  has-error"
                  }
                >
                  <label>ਕਿਸਾਨ ਦਾ ਮੋਬਾਈਲ ਨੰਬਰ (Farmer Mobile No.)</label>
                  <div className="input-group-btn">
                    <input
                      type="number"
                      className="input-form-control"
                      value={this.state.farmerMobileNo}
                      onChange={(evt) =>
                        this.setState({
                          farmerMobileNo: evt.target.value,
                          farmerMobileNoError: "",
                        })
                      }
                    />
                    <button
                      type="button"
                      disabled={this.state.farmerMobileNo.trim() === ""}
                      className="btn btn-warning btn-md"
                      style={{ verticalAlign: "top" }}
                      onClick={this.searchFarmer}
                    >
                      <i className="fa fa-search"></i> Search
                    </button>
                  </div>
                  {this.state.farmerMobileNoError !== "" && (
                    <span class="help-block">
                      {this.state.farmerMobileNoError}
                    </span>
                  )}
                </div>
                <div
                  className={
                    this.state.farmerNameError === ""
                      ? "mb-3 col-md-6"
                      : "mb-3 col-md-6  has-error"
                  }
                >
                  <label>ਕਿਸਾਨ ਦਾ ਨਾਮ (Farmer Name)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.farmerName}
                    onChange={(evt) =>
                      this.setState({
                        farmerName: evt.target.value,
                        farmerNameError: "",
                      })
                    }
                  />
                  {this.state.farmerNameError !== "" && (
                    <span class="help-block">{this.state.farmerNameError}</span>
                  )}
                </div>
                <div
                  className={
                    this.state.farmerFatherNameError === ""
                      ? "mb-3 col-md-6"
                      : "mb-3 col-md-6  has-error"
                  }
                >
                  <label>ਪਿਤਾ ਦਾ ਨਾਮ (Father Name)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.farmerFatherName}
                    onChange={(evt) =>
                      this.setState({
                        farmerFatherName: evt.target.value,
                        farmerFatherNameError: "",
                      })
                    }
                  />
                  {this.state.farmerNameError !== "" && (
                    <span class="help-block">
                      {this.state.farmerFatherNameError}
                    </span>
                  )}
                </div>
                <div
                  className={
                    this.state.farmerProfileError === ""
                      ? "mb-3 col-md-6"
                      : "mb-3 col-md-6  has-error"
                  }
                >
                  <label>ਕਿਸਾਨ ਪ੍ਰੋਫਾਈਲ (Farmer Profile)</label>
                  <select
                    className="form-control"
                    value={this.state.farmerProfile}
                    onChange={(evt) =>
                      this.setState({
                        farmerProfile: evt.target.value,
                        farmerProfileError: "",
                      })
                    }
                  >
                    <option value="">Select Profile</option>
                    {this.state.farmerProfiles.map((profile) => (
                      <option value={profile.code}>{profile.name}</option>
                    ))}
                  </select>
                  {this.state.farmerProfileError !== "" && (
                    <span class="help-block">
                      {this.state.farmerProfileError}
                    </span>
                  )}
                </div>
                <div
                  className={
                    this.state.villageError === ""
                      ? "mb-3 col-md-6"
                      : "mb-3 col-md-6  has-error"
                  }
                >
                  <label>ਕਿਸਾਨ ਦਾ ਪਿੰਡ (Farmer Village)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.village}
                    onChange={(evt) =>
                      this.setState({
                        village: evt.target.value,
                        villageError: "",
                      })
                    }
                  />
                  {this.state.villageError !== "" && (
                    <span class="help-block">{this.state.villageError}</span>
                  )}
                </div>
                {!isMobile() && this.renderDesktop()}
                {isMobile() && this.renderMobile()}
                {isMobile() && (
                  <React.Fragment>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </React.Fragment>
                )}
                {this.renderFooter()}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default TransactionAdd;
