import React, { Component } from 'react'
import Header from './Header'
import '../css/App.css';

class SocietyEdit extends Component {

    constructor(props) {
        super(props)
        this.state = {
            division: "",
            district: "",
            drname: "",
            drmobile: "",
            ar: "",
            arname: "",
            armobile: "",
            society: "",
            village: "",
            secretary: "",
            secretarymobile: "",
            inspector: "",
            inspectormobile: "",
            happyseeder: 0,
            superseeder: 0,
            paddystrawchopper: 0,
            mulcher: 0,
            rmbplough: 0,
            zerotilldrill: 0,
            supersms: 0,
            rotaryslasher: 0,
            shrubmaster: 0,
            cuttercumspreader: 0,
            rotavator: 0,
            tractor: 0,
            tractortrolley: 0,
            laserleveller: 0,
            directseederrice: 0,
            maizeplantermechanical: 0,
            maizeplantervaccum: 0,
            spraypump: 0,
            pressuredisks: 0,
            disklock: 0,
            biofertilizerspreader: 0,
            cultivator: 0,
            subsoiler: 0,
            powercleaner: 0,
            discrazor: 0,
            harrowtripple: 0,
            cottonseeddrill: 0,
            watertank: 0,
            suhaga: 0,
            wheatseedfan: 0,
            seedgrindingmachine: 0,
            jindra: 0,
            combine: 0,
            seelarcotton: 0,
            generator: 0,
            wheatseeddrill: 0,
            reaper: 0,
            plough: 0,
            groundleveller: 0,
            strawreaper: 0,
            potatoplanter: 0,
            tawian: 0,
            simpleleveller: 0,
            loader: 0,
            potatodrill: 0,
            multidrillmachine: 0,
            puddler: 0,
            chopper: 0,
            corndrill: 0,
            peasmachine: 0,
            seeddrill: 0,
            chizler: 0,
            paddyplanter: 0,
            dieseltank: 0,
            bhauni: 0,
            tota: 0,
            tiller: 0,
            karah: 0,
            tarfali: 0,
            razor: 0,
            // taulynew: 0,
            baler:0,
            thresher: 0,
            karchamachine: 0,
            rator: 0,
            sugarcanerager: 0,
            discharrow: 0,
            chainkuppi: 0,
            sugarcaneseeder: 0,
            grinder: 0,
            changer: 0,
            trippleplough: 0,
            // filagemaking: 0,
            raker:0,
            cornharvester: 0,
            cutter: 0,
            motormachine: 0,
            discplough: 0,
            gainiplough: 0,
            dugplough: 0,
            offsetharrow: 0,
            jaal: 0,
            tarakadi: 0,
            carrotmachine: 0,
            discbedmaker: 0,
            loaderbucket: 0,
            seedgrader: 0,
            achathall: 0,
            tawawatta: 0,
            jack: 0,
            surfaceseeder:0,
            smartseeder:0

        }
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount() {
        if (this.props.common.society) {
            this.setState({
                division: this.props.common.society.DIVISION,
                district: this.props.common.society.DISTRICT,
                drname: this.props.common.society.DRNAME,
                drmobile: this.props.common.society.DRMOBILE,
                ar: this.props.common.society.AR,
                arname: this.props.common.society.ARNAME,
                armobile: this.props.common.society.ARMOBILE,
                society: this.props.common.society.SOCIETY,
                village: this.props.common.society.VILLAGE,
                secretary: this.props.common.society.SECRETARY,
                secretarymobile: this.props.common.society.SECRETARYMOBILE,
                inspector: this.props.common.society.INSPECTOR,
                inspectormobile: this.props.common.society.INSPECTORMOBILE,

                happyseeder: this.props.common.society.HAPPYSEEDER ? this.props.common.society.HAPPYSEEDER : 0,
                superseeder: this.props.common.society.SUPERSEEDER ? this.props.common.society.SUPERSEEDER : 0,
                paddystrawchopper: this.props.common.society.PADDYSTRAWCHOPPER ? this.props.common.society.PADDYSTRAWCHOPPER : 0,
                mulcher: this.props.common.society.MULCHER ? this.props.common.society.MULCHER : 0,
                rmbplough: this.props.common.society.RMBPLOUGH ? this.props.common.society.RMBPLOUGH  : 0,
                zerotilldrill: this.props.common.society.ZEROTILLDRILL ? this.props.common.society.ZEROTILLDRILL : 0,
                supersms: this.props.common.society.SUPERSMS ? this.props.common.society.SUPERSMS : 0,
                rotaryslasher: this.props.common.society.ROTARYSLASHER ? this.props.common.society.ROTARYSLASHER : 0,
                shrubmaster: this.props.common.society.SHRUBMASTER ? this.props.common.society.SHRUBMASTER : 0,
                cuttercumspreader: this.props.common.society.CUTTERCUMSPREADER ? this.props.common.society.CUTTERCUMSPREADER : 0,
                rotavator: this.props.common.society.ROTAVATOR ? this.props.common.society.ROTAVATOR : 0,
                tractor: this.props.common.society.TRACTOR ? this.props.common.society.TRACTOR : 0,
                tractortrolley: this.props.common.society.TRACTORTROLLEY ? this.props.common.society.TRACTORTROLLEY : 0,
                laserleveller: this.props.common.society.LASERLEVELLER ? this.props.common.society.LASERLEVELLER : 0,
                directseederrice: this.props.common.society.DIRECTSEEDERRICE ? this.props.common.society.DIRECTSEEDERRICE : 0,
                maizeplantermechanical: this.props.common.society.MAIZEPLANTERMECHANICAL ? this.props.common.society.MAIZEPLANTERMECHANICAL : 0,
                maizeplantervaccum: this.props.common.society.MAIZEPLANTERVACCUM ? this.props.common.society.MAIZEPLANTERVACCUM : 0,
                spraypump: this.props.common.society.SPRAYPUMP ? this.props.common.society.SPRAYPUMP : 0,
                pressuredisks: this.props.common.society.PRESSUREDISKS ? this.props.common.society.PRESSUREDISKS : 0,
                disklock: this.props.common.society.DISKLOCK ? this.props.common.society.DISKLOCK : 0,
                biofertilizerspreader: this.props.common.society.BIOFERTILIZERSPREADER ? this.props.common.society.BIOFERTILIZERSPREADER : 0,
                cultivator: this.props.common.society.CULTIVATOR ? this.props.common.society.CULTIVATOR : 0,
                subsoiler: this.props.common.society.SUBSOILER ? this.props.common.society.SUBSOILER : 0,
                powercleaner: this.props.common.society.POWERCLEANER ? this.props.common.society.POWERCLEANER :0,
                discrazor: this.props.common.society.DISCRAZOR ? this.props.common.society.DISCRAZOR : 0,
                harrowtripple: this.props.common.society.HARROWTRIPPLE ? this.props.common.society.HARROWTRIPPLE : 0,
                cottonseeddrill: this.props.common.society.COTTONSEEDDRILL ? this.props.common.society.COTTONSEEDDRILL : 0,
                watertank: this.props.common.society.WATERTANK ? this.props.common.society.WATERTANK : 0,
                suhaga: this.props.common.society.SUHAGA ? this.props.common.society.SUHAGA : 0,
                wheatseedfan: this.props.common.society.WHEATSEEDFAN ? this.props.common.society.WHEATSEEDFAN : 0,
                seedgrindingmachine: this.props.common.society.SEEDGRINDINGMACHINE ? this.props.common.society.SEEDGRINDINGMACHINE : 0,
                jindra: this.props.common.society.JINDRA ? this.props.common.society.JINDRA : 0,
                combine: this.props.common.society.COMBINE ? this.props.common.society.COMBINE : 0,
                seelarcotton: this.props.common.society.SEELARCOTTON ? this.props.common.society.SEELARCOTTON : 0,
                generator: this.props.common.society.GENERATOR ? this.props.common.society.GENERATOR : 0,
                wheatseeddrill: this.props.common.society.WHEATSEEDDRILL ? this.props.common.society.WHEATSEEDDRILL : 0,
                reaper: this.props.common.society.REAPER ? this.props.common.society.REAPER : 0,
                plough: this.props.common.society.PLOUGH ? this.props.common.society.PLOUGH : 0,
                groundleveller: this.props.common.society.GROUNDLEVELLER ? this.props.common.society.GROUNDLEVELLER : 0,
                strawreaper: this.props.common.society.STRAWREAPER ? this.props.common.society.STRAWREAPER : 0,
                potatoplanter: this.props.common.society.POTATOPLANTER ? this.props.common.society.POTATOPLANTER : 0,
                tawian: this.props.common.society.TAWIAN ? this.props.common.society.TAWIAN : 0,
                simpleleveller: this.props.common.society.SIMPLELEVELLER ? this.props.common.society.SIMPLELEVELLER : 0,
                loader: this.props.common.society.LOADER ? this.props.common.society.LOADER : 0,
                potatodrill: this.props.common.society.POTATODRILL ? this.props.common.society.POTATODRILL : 0,

                multidrillmachine: this.props.common.society.MULTIDRILLMACHINE ? this.props.common.society.MULTIDRILLMACHINE : 0,
                puddler: this.props.common.society.PUDDLER ? this.props.common.society.PUDDLER : 0,
                chopper: this.props.common.society.CHOPPER ? this.props.common.society.CHOPPER : 0,
                corndrill: this.props.common.society.CORNDRILL ? this.props.common.society.CORNDRILL : 0,
                peasmachine: this.props.common.society.PEASMACHINE ? this.props.common.society.PEASMACHINE : 0,
                seeddrill: this.props.common.society.SEEDDRILL ? this.props.common.society.SEEDDRILL : 0,
                chizler: this.props.common.society.CHIZLER ? this.props.common.society.CHIZLER : 0,
                paddyplanter: this.props.common.society.PADDYPLANTER ? this.props.common.society.PADDYPLANTER : 0,
                dieseltank: this.props.common.society.DIESELTANK ? this.props.common.society.DIESELTANK : 0,
                bhauni: this.props.common.society.BHAUNI ? this.props.common.society.BHAUNI : 0,
                tota: this.props.common.society.TOTA ? this.props.common.society.TOTA : 0,
                tiller: this.props.common.society.TILLER ? this.props.common.society.TILLER : 0,
                karah: this.props.common.society.KARAH ? this.props.common.society.KARAH : 0,
                tarfali: this.props.common.society.TARFALI ? this.props.common.society.TARFALI : 0,
                razor: this.props.common.society.RAZOR ? this.props.common.society.RAZOR : 0,
                // taulynew: this.props.common.society.TAULYNEW ? this.props.common.society.TAULYNEW : 0,
                baler: this.props.common.society.BALER ? this.props.common.society.BALER : 0,

                thresher: this.props.common.society.THRESHER ? this.props.common.society.THRESHER : 0,
                karchamachine: this.props.common.society.KARCHAMACHINE ? this.props.common.society.KARCHAMACHINE : 0,
                rator: this.props.common.society.RATOR ? this.props.common.society.RATOR: 0,
                sugarcanerager: this.props.common.society.SUGARCANERAGER ? this.props.common.society.SUGARCANERAGER : 0,
                discharrow: this.props.common.society.DISCHARROW ? this.props.common.society.DISCHARROW : 0,
                chainkuppi: this.props.common.society.CHAINKUPPI ? this.props.common.society.CHAINKUPPI : 0,
                sugarcaneseeder: this.props.common.society.SUGARCANESEEDER ? this.props.common.society.SUGARCANESEEDER : 0,
                grinder: this.props.common.society.GRINDER ? this.props.common.society.GRINDER : 0,
                changer: this.props.common.society.CHANGER ? this.props.common.society.CHANGER : 0,
                trippleplough: this.props.common.society.TRIPPLEPLOUGH ? this.props.common.society.TRIPPLEPLOUGH : 0,
                // filagemaking: this.props.common.society.FILAGEMAKING ? this.props.common.society.FILAGEMAKING : 0,
                raker: this.props.common.society.RAKER ? this.props.common.society.RAKER : 0,
                cornharvester: this.props.common.society.CORNHARVESTER ? this.props.common.society.CORNHARVESTER : 0,
                cutter: this.props.common.society.CUTTER ? this.props.common.society.CUTTER : 0,
                motormachine: this.props.common.society.MOTORMACHINE ? this.props.common.society.MOTORMACHINE : 0,
                discplough: this.props.common.society.DISCPLOUGH ? this.props.common.society.DISCPLOUGH : 0,
                gainiplough: this.props.common.society.GAINIPLOUGH ? this.props.common.society.GAINIPLOUGH : 0,
                dugplough: this.props.common.society.DUGPLOUGH ? this.props.common.society.DUGPLOUGH : 0,
                offsetharrow: this.props.common.society.OFFSETHARROW ? this.props.common.society.OFFSETHARROW : 0,
                jaal: this.props.common.society.JAAL ? this.props.common.society.JAAL: 0,
                tarakadi: this.props.common.society.TARAKADI ? this.props.common.society.TARAKADI : 0,
                carrotmachine: this.props.common.society.CARROTMACHINE ? this.props.common.society.CARROTMACHINE : 0,
                discbedmaker: this.props.common.society.DISCBEDMAKER ? this.props.common.society.DISCBEDMAKER : 0,
                loaderbucket: this.props.common.society.LOADERBUCKET ? this.props.common.society.LOADERBUCKET : 0,
                seedgrader: this.props.common.society.SEEDGRADER ? this.props.common.society.SEEDGRADER : 0,
                achathall: this.props.common.society.ACHATHALL ? this.props.common.society.ACHATHALL : 0,
                tawawatta: this.props.common.society.TAWAWATTA ? this.props.common.society.TAWAWATTA : 0,
                jack: this.props.common.society.JACK ? this.props.common.society.JACK : 0,
                surfaceseeder: this.props.common.society.SURFACESEEDER ? this.props.common.society.SURFACESEEDER : 0,
                smartseeder: this.props.common.society.SMARTSEEDER ? this.props.common.society.SMARTSEEDER : 0
            });
        };
    };

    onSave() {

        let totalCRMMachines = 0, totalNonCRMMachines = 0, totalMachines = 0;

        totalCRMMachines = Number(Number(this.state.happyseeder) + Number(this.state.superseeder) + Number(this.state.supersms) +
            Number(this.state.rmbplough) + Number(this.state.rotavator) + Number(this.state.zerotilldrill) + Number(this.state.chopper) + Number(this.state.mulcher));

        totalNonCRMMachines = Number(Number(this.state.paddystrawchopper) + Number(this.state.rotaryslasher) + Number(this.state.shrubmaster) + Number(this.state.cuttercumspreader) +
            Number(this.state.tractor) + Number(this.state.tractortrolley) + Number(this.state.laserleveller) + Number(this.state.directseederrice) +
            Number(this.state.maizeplantermechanical) + Number(this.state.maizeplantervaccum) + Number(this.state.spraypump) + Number(this.state.pressuredisks) +
            Number(this.state.disklock) + Number(this.state.biofertilizerspreader) + Number(this.state.cultivator) + Number(this.state.subsoiler) + Number(this.state.powercleaner) +
            Number(this.state.discrazor) + Number(this.state.harrowtripple) + Number(this.state.cottonseeddrill) + Number(this.state.watertank) + Number(this.state.suhaga) +
            Number(this.state.wheatseedfan) + Number(this.state.seedgrindingmachine) + Number(this.state.jindra) + Number(this.state.combine) + Number(this.state.seelarcotton) +
            Number(this.state.generator) + Number(this.state.wheatseeddrill) + Number(this.state.reaper) + Number(this.state.plough) + Number(this.state.groundleveller) +
            Number(this.state.strawreaper) + Number(this.state.potatoplanter) + Number(this.state.tawian) + Number(this.state.simpleleveller) + Number(this.state.loader) +
            Number(this.state.potatodrill) + Number(this.state.multidrillmachine) + Number(this.state.puddler) + Number(this.state.corndrill) +
            Number(this.state.peasmachine) + Number(this.state.seeddrill) + Number(this.state.chizler) + Number(this.state.paddyplanter) + Number(this.state.dieseltank) +
            Number(this.state.bhauni) + Number(this.state.tota) + Number(this.state.tiller) + Number(this.state.karah) + Number(this.state.tarfali) +
            Number(this.state.razor) + Number(this.state.baler) + Number(this.state.thresher) + Number(this.state.karchamachine) + Number(this.state.rator) +
            Number(this.state.sugarcanerager) + Number(this.state.discharrow) + Number(this.state.chainkuppi) + Number(this.state.sugarcaneseeder) +
            Number(this.state.grinder) + Number(this.state.changer) + Number(this.state.trippleplough) + Number(this.state.raker) +
            Number(this.state.cornharvester) + Number(this.state.cutter) + Number(this.state.motormachine) + Number(this.state.discplough) +
            Number(this.state.gainiplough) + Number(this.state.dugplough) + Number(this.state.offsetharrow) + Number(this.state.jaal) +
            Number(this.state.tarakadi) + Number(this.state.carrotmachine) + Number(this.state.discbedmaker) + Number(this.state.loaderbucket) +
            Number(this.state.seedgrader) + Number(this.state.achathall) + Number(this.state.tawawatta) + Number(this.state.jack)) +
            Number(this.state.surfaceseeder) + Number(this.state.smartseeder)

        totalMachines = Number(totalNonCRMMachines) + Number(totalCRMMachines);

        let society = {
            key: this.props.common.society.key,
          
            // DIVISION: this.state.division,
            // DISTRICT: this.state.district,
            DRNAME: this.state.drname,
            DRMOBILE: this.state.drmobile,
            // AR: this.state.ar,
            ARNAME: this.state.arname,
            ARMOBILE: this.state.armobile,
            SOCIETY: this.state.society,
            VILLAGE: this.state.village ? this.state.village : "",
            SECRETARY: this.state.secretary,
            SECRETARYMOBILE: this.state.secretarymobile,
            INSPECTOR: this.state.inspector,
            INSPECTORMOBILE: this.state.inspectormobile,

            HAPPYSEEDER: this.state.happyseeder,
            SUPERSEEDER: this.state.superseeder,
            PADDYSTRAWCHOPPER: this.state.paddystrawchopper,
            MULCHER: this.state.mulcher,
            RMBPLOUGH: this.state.rmbplough,
            ZEROTILLDRILL: this.state.zerotilldrill,
            SUPERSMS: this.state.supersms,
            ROTARYSLASHER: this.state.rotaryslasher,
            SHRUBMASTER: this.state.shrubmaster,
            CUTTERCUMSPREADER: this.state.cuttercumspreader,
            ROTAVATOR: this.state.rotavator,
            TRACTOR: this.state.tractor,
            TRACTORTROLLEY: this.state.tractortrolley,
            LASERLEVELLER: this.state.laserleveller,
            DIRECTSEEDERRICE: this.state.directseederrice,
            MAIZEPLANTERMECHANICAL: this.state.maizeplantermechanical,
            MAIZEPLANTERVACCUM: this.state.maizeplantervaccum,
            SPRAYPUMP: this.state.spraypump,
            PRESSUREDISKS: this.state.pressuredisks,
            DISKLOCK: this.state.disklock,
            BIOFERTILIZERSPREADER: this.state.biofertilizerspreader,
            CULTIVATOR: this.state.cultivator,
            SUBSOILER: this.state.subsoiler,
            POWERCLEANER: this.state.powercleaner,
            DISCRAZOR: this.state.discrazor,
            HARROWTRIPPLE: this.state.harrowtripple,
            COTTONSEEDDRILL: this.state.cottonseeddrill,
            WATERTANK: this.state.watertank,
            SUHAGA: this.state.suhaga,
            WHEATSEEDFAN: this.state.wheatseedfan,
            SEEDGRINDINGMACHINE: this.state.seedgrindingmachine,
            JINDRA: this.state.jindra,
            COMBINE: this.state.combine,
            SEELARCOTTON: this.state.seelarcotton,
            GENERATOR: this.state.generator,
            WHEATSEEDDRILL: this.state.wheatseeddrill,
            REAPER: this.state.reaper,
            PLOUGH: this.state.plough,
            GROUNDLEVELLER: this.state.groundleveller,
            STRAWREAPER: this.state.strawreaper,
            POTATOPLANTER: this.state.potatoplanter,
            TAWIAN: this.state.tawian,
            SIMPLELEVELLER: this.state.simpleleveller,
            LOADER: this.state.loader,
            POTATODRILL: this.state.potatodrill,

            MULTIDRILLMACHINE: this.state.multidrillmachine,
            PUDDLER: this.state.puddler,
            CHOPPER: this.state.chopper,
            CORNDRILL: this.state.corndrill,
            PEASMACHINE: this.state.peasmachine,
            SEEDDRILL: this.state.seeddrill,
            CHIZLER: this.state.chizler,
            PADDYPLANTER: this.state.paddyplanter,
            DIESELTANK: this.state.dieseltank,
            BHAUNI: this.state.bhauni,
            TOTA: this.state.tota,
            TILLER: this.state.tiller,
            KARAH: this.state.karah,
            TARFALI: this.state.tarfali,
            RAZOR: this.state.razor,
            // TAULYNEW: this.state.taulynew,
            BALER: this.state.baler,
            THRESHER: this.state.thresher,
            KARCHAMACHINE: this.state.karchamachine,
            RATOR: this.state.rator,
            SUGARCANERAGER: this.state.sugarcanerager,
            DISCHARROW: this.state.discharrow,
            CHAINKUPPI: this.state.chainkuppi,
            SUGARCANESEEDER: this.state.sugarcaneseeder,
            GRINDER: this.state.grinder,
            CHANGER: this.state.changer,
            TRIPPLEPLOUGH: this.state.trippleplough,
            // FILAGEMAKING: this.state.filagemaking,
            RAKER: this.state.raker,
            CORNHARVESTER: this.state.cornharvester,
            CUTTER: this.state.cutter,
            MOTORMACHINE: this.state.motormachine,
            DISCPLOUGH: this.state.discplough,
            GAINIPLOUGH: this.state.gainiplough,
            DUGPLOUGH: this.state.dugplough,
            OFFSETHARROW: this.state.offsetharrow,
            JAAL: this.state.jaal,
            TARAKADI: this.state.tarakadi,
            CARROTMACHINE: this.state.carrotmachine,
            DISCBEDMAKER: this.state.discbedmaker,
            LOADERBUCKET: this.state.loaderbucket,
            SEEDGRADER: this.state.seedgrader,
            ACHATHALL: this.state.achathall,
            TAWAWATTA: this.state.tawawatta,
            JACK: this.state.jack,       
            SURFACESEEDER: this.state.surfaceseeder,
            SMARTSEEDER: this.state.smartseeder,
            SUPERSEEDER: this.state.superseeder,
            TOTALCRMMACHINES: Number(totalCRMMachines),
            TOTALNONCRMMACHINES: Number(totalNonCRMMachines),
            TOTALMACHINES: Number(totalMachines)
        };
        // console.log(society);
        this.props.updateSociety(society, this.props.common.society.key).then(async() => {
            await this.props.setSociety(society);
            alert('Data saved successfully');
            this.props.onHistory.push('/home');
        });
    };

    render() {
        return <div>
            <Header menuSelected="home" {...this.props} />
            <main className="container">
                {/* <br /> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ minHeight: "180px", fontSize: "14px" }}>
                            <h3 className="border-bottom border-gray pb-2 mb-0"> <span className="fa-stack fa-md">
                                <i className="fa fa-circle-thin fa-stack-2x"></i>
                                <i className="fa fa-edit fa-stack-1x"></i>
                            </span> View / Edit Machine Types
                            </h3>
                            <br />
                            {/* <div className="mb-3 col-md-6">
                                <label>Division</label>
                                <input type="text" className="form-control"
                                    value={this.state.division}
                                    disabled
                                    onChange={(evt) => this.setState({ division: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>District</label>
                                <input type="text" className="form-control"
                                    value={this.state.district}
                                    disabled
                                    onChange={(evt) => this.setState({ district: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>DR Name</label>
                                <input type="text" className="form-control"
                                    value={this.state.drname}
                                    disabled
                                    onChange={(evt) => this.setState({ drname: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>DR MOBILE</label>
                                <input type="text" className="form-control"
                                    value={this.state.drmobile}
                                    disabled
                                    onChange={(evt) => this.setState({ drmobile: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>AR</label>
                                <input type="text" className="form-control"
                                    value={this.state.ar}
                                    disabled
                                    onChange={(evt) => this.setState({ ar: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>AR Name</label>
                                <input type="text" className="form-control"
                                    value={this.state.arname}
                                    disabled
                                    onChange={(evt) => this.setState({ arname: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>AR MOBILE</label>
                                <input type="text" className="form-control"
                                    value={this.state.armobile}
                                    disabled
                                    onChange={(evt) => this.setState({ armobile: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Society</label>
                                <input type="text" className="form-control"
                                    value={this.state.society}
                                    disabled
                                    onChange={(evt) => this.setState({ society: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Village</label>
                                <input type="text" className="form-control"
                                    value={this.state.village}
                                    disabled
                                    onChange={(evt) => this.setState({ village: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Secretary</label>
                                <input type="text" className="form-control"
                                    value={this.state.secretary}
                                    disabled
                                    onChange={(evt) => this.setState({ secretary: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Secretary Mobile</label>
                                <input type="text" className="form-control"
                                    value={this.state.secretarymobile}
                                    disabled
                                    onChange={(evt) => this.setState({ secretarymobile: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Inspector</label>
                                <input type="text" className="form-control"
                                    value={this.state.inspector}
                                    disabled
                                    onChange={(evt) => this.setState({ inspector: evt.target.value })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Inspector Mobile</label>
                                <input type="text" className="form-control"
                                    value={this.state.inspectormobile}
                                    disabled
                                    onChange={(evt) => this.setState({ inspectormobile: evt.target.value })} />
                            </div> */}
                            <div className="mb-3 col-md-6">
                                <label>Happy Seeder</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.happyseeder}
                                    onChange={(evt) => this.setState({ happyseeder: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Super Seeder</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.superseeder}
                                    onChange={(evt) => this.setState({ superseeder: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Paddy Straw Chopper</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.paddystrawchopper}
                                    onChange={(evt) => this.setState({ paddystrawchopper: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Mulcher</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.mulcher}
                                    onChange={(evt) => this.setState({ mulcher: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>RMB Plough</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.rmbplough}
                                    onChange={(evt) => this.setState({ rmbplough: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Zero Till Drill</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.zerotilldrill}
                                    onChange={(evt) => this.setState({ zerotilldrill: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Super SMS</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.supersms}
                                    onChange={(evt) => this.setState({ supersms: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Rotary Slasher</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.rotaryslasher}
                                    onChange={(evt) => this.setState({ rotaryslasher: Number(evt.target.value) })} />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label>Shrub Master</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.shrubmaster}
                                    onChange={(evt) => this.setState({ shrubmaster: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Cutter Cum Spreader</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.cuttercumspreader}
                                    onChange={(evt) => this.setState({ cuttercumspreader: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Rotavator</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.rotavator}
                                    onChange={(evt) => this.setState({ rotavator: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tractor</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.tractor}
                                    onChange={(evt) => this.setState({ tractor: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tractor Trolley</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.tractortrolley}
                                    onChange={(evt) => this.setState({ tractortrolley: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Laser Leveller</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.laserleveller}
                                    onChange={(evt) => this.setState({ laserleveller: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Direct Seeder Rice (DSR)</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.directseederrice}
                                    onChange={(evt) => this.setState({ directseederrice: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Maize Planter Mechanical</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.maizeplantermechanical}
                                    onChange={(evt) => this.setState({ maizeplantermechanical: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Maize Planter Vaccum</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.maizeplantervaccum}
                                    onChange={(evt) => this.setState({ maizeplantervaccum: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Spray Pump</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.spraypump}
                                    onChange={(evt) => this.setState({ spraypump: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Pressure Disks</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.pressuredisks}
                                    onChange={(evt) => this.setState({ pressuredisks: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Disk Lock</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.disklock}
                                    onChange={(evt) => this.setState({ disklock: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>(Bio) Fertilizer Spreader</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.biofertilizerspreader}
                                    onChange={(evt) => this.setState({ biofertilizerspreader: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Cultivator</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.cultivator}
                                    onChange={(evt) => this.setState({ cultivator: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Sub Soiler</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.subsoiler}
                                    onChange={(evt) => this.setState({ subsoiler: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Power Cleaner</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.powercleaner}
                                    onChange={(evt) => this.setState({ powercleaner: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Disc Razor</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.discrazor}
                                    onChange={(evt) => this.setState({ discrazor: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Harrow Tripple</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.harrowtripple}
                                    onChange={(evt) => this.setState({ harrowtripple: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Cotton Seed Drill</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.cottonseeddrill}
                                    onChange={(evt) => this.setState({ cottonseeddrill: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Water Tank</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.watertank}
                                    onChange={(evt) => this.setState({ watertank: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Suhaga</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.suhaga}
                                    onChange={(evt) => this.setState({ suhaga: Number(evt.target.value) })} />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label>Wheat Seed Fan</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.wheatseedfan}
                                    onChange={(evt) => this.setState({ wheatseedfan: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Seed Grinding Machine</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.seedgrindingmachine}
                                    onChange={(evt) => this.setState({ seedgrindingmachine: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Jindra</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.jindra}
                                    onChange={(evt) => this.setState({ jindra: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Combine</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.combine}
                                    onChange={(evt) => this.setState({ combine: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Seelar Cotton</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.seelarcotton}
                                    onChange={(evt) => this.setState({ seelarcotton: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Generator</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.generator}
                                    onChange={(evt) => this.setState({ generator: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Wheat Seed Drill</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.wheatseeddrill}
                                    onChange={(evt) => this.setState({ wheatseeddrill: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Reaper</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.reaper}
                                    onChange={(evt) => this.setState({ reaper: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Plough</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.plough}
                                    onChange={(evt) => this.setState({ plough: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Ground Leveller</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.groundleveller}
                                    onChange={(evt) => this.setState({ groundleveller: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Straw Reaper</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.strawreaper}
                                    onChange={(evt) => this.setState({ strawreaper: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Potato Planter</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.potatoplanter}
                                    onChange={(evt) => this.setState({ potatoplanter: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tawian</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.tawian}
                                    onChange={(evt) => this.setState({ tawian: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Simple Leveller</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.simpleleveller}
                                    onChange={(evt) => this.setState({ simpleleveller: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Loader</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.loader}
                                    onChange={(evt) => this.setState({ loader: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Potato Drill</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.potatodrill}
                                    onChange={(evt) => this.setState({ potatodrill: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Multi Drill Machine</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.multidrillmachine}
                                    onChange={(evt) => this.setState({ multidrillmachine: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Puddler (Kaddo)</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.puddler}
                                    onChange={(evt) => this.setState({ puddler: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Chopper</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.chopper}
                                    onChange={(evt) => this.setState({ chopper: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Corn Drill</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.corndrill}
                                    onChange={(evt) => this.setState({ corndrill: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Peas Machine</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.peasmachine}
                                    onChange={(evt) => this.setState({ peasmachine: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Seed Drill</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.seeddrill}
                                    onChange={(evt) => this.setState({ seeddrill: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Chizler</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.chizler}
                                    onChange={(evt) => this.setState({ chizler: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Paddy Planter</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.paddyplanter}
                                    onChange={(evt) => this.setState({ paddyplanter: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Diesel Tank</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.dieseltank}
                                    onChange={(evt) => this.setState({ dieseltank: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Bhauni</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.bhauni}
                                    onChange={(evt) => this.setState({ bhauni: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tota</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.tota}
                                    onChange={(evt) => this.setState({ tota: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tiller</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.tiller}
                                    onChange={(evt) => this.setState({ tiller: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Karah</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.karah}
                                    onChange={(evt) => this.setState({ karah: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tarfali</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.tarfali}
                                    onChange={(evt) => this.setState({ tarfali: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Razor</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.razor}
                                    onChange={(evt) => this.setState({ razor: Number(evt.target.value) })} />
                            </div>
                            {/* <div className="mb-3 col-md-6">
                                <label>Tauly New</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.taulynew}
                                    onChange={(evt) => this.setState({ taulynew: Number(evt.target.value) })} />
                            </div> */}
                            <div className="mb-3 col-md-6">
                                <label>BALER</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.baler}
                                    onChange={(evt) => this.setState({ baler: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Thresher</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.thresher}
                                    onChange={(evt) => this.setState({ thresher: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Karcha Machine</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.karchamachine}
                                    onChange={(evt) => this.setState({ karchamachine: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Multi Drill Machine</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.multidrillmachine}
                                    onChange={(evt) => this.setState({ multidrillmachine: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Rator</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.rator}
                                    onChange={(evt) => this.setState({ rator: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Sugarcane Rager</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.sugarcanerager}
                                    onChange={(evt) => this.setState({ sugarcanerager: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Disc Harrow</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.discharrow}
                                    onChange={(evt) => this.setState({ discharrow: Number(evt.target.value) })} />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label>Chain Kuppi</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.chainkuppi}
                                    onChange={(evt) => this.setState({ chainkuppi: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Sugarcane Seeder</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.sugarcaneseeder}
                                    onChange={(evt) => this.setState({ sugarcaneseeder: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Grinder</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.grinder}
                                    onChange={(evt) => this.setState({ grinder: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Changer</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.changer}
                                    onChange={(evt) => this.setState({ changer: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tripple Plough</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.trippleplough}
                                    onChange={(evt) => this.setState({ trippleplough: Number(evt.target.value) })} />
                            </div>
                            {/* <div className="mb-3 col-md-6">
                                <label>Filage Making</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.filagemaking}
                                    onChange={(evt) => this.setState({ filagemaking: Number(evt.target.value) })} />
                            </div> */}
                            <div className="mb-3 col-md-6">
                                <label>RAKER</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.raker}
                                    onChange={(evt) => this.setState({ raker: Number(evt.target.value) })} />
                            </div>
                            
                            <div className="mb-3 col-md-6">
                                <label>Corn Harvester</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.cornharvester}
                                    onChange={(evt) => this.setState({ cornharvester: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Cutter</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.cutter}
                                    onChange={(evt) => this.setState({ cutter: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Motor Machine / Motor Extraction Machine</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.motormachine}
                                    onChange={(evt) => this.setState({ motormachine: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Disc Plough</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.discplough}
                                    onChange={(evt) => this.setState({ discplough: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Gaini Plough</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.gainiplough}
                                    onChange={(evt) => this.setState({ gainiplough: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Dug Plough</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.dugplough}
                                    onChange={(evt) => this.setState({ dugplough: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Offset Harrow</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.offsetharrow}
                                    onChange={(evt) => this.setState({ offsetharrow: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Jaal</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.jaal}
                                    onChange={(evt) => this.setState({ jaal: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tarakadi</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.tarakadi}
                                    onChange={(evt) => this.setState({ tarakadi: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Carrot Machine</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.carrotmachine}
                                    onChange={(evt) => this.setState({ carrotmachine: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Disc Bed Maker</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.discbedmaker}
                                    onChange={(evt) => this.setState({ discbedmaker: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Loader Bucket</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.loaderbucket}
                                    onChange={(evt) => this.setState({ loaderbucket: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Seed Grader</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.seedgrader}
                                    onChange={(evt) => this.setState({ seedgrader: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Achat Hall</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.achathall}
                                    onChange={(evt) => this.setState({ achathall: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Tawa Watta</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.tawawatta}
                                    onChange={(evt) => this.setState({ tawawatta: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Jack</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.jack}
                                    onChange={(evt) => this.setState({ jack: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Surface Seeder</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.surfaceseeder}
                                    onChange={(evt) => this.setState({ surfaceseeder: Number(evt.target.value) })} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label>Smart Seeder</label>
                                <input type="number" className="form-control" min="0"
                                    value={this.state.smartseeder}
                                    onChange={(evt) => this.setState({ smartseeder: Number(evt.target.value) })} />
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-default" onClick={() => this.props.onHistory.push('/home')}>Cancel</button>
                                <button className="btn btn-primary pull-right" onClick={this.onSave} disabled={this.state.saving} >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}

export default SocietyEdit